// // ✅ This is a component that checks for the latest frontend version and prompts the user to refresh if there is a mismatch.   
// ////////////// VersionChecker.js

import React, { useEffect, useState } from "react";
import { notification } from "antd";
import api from "../functions/api";

const VersionChecker = () => {
  const [currentVersion, setCurrentVersion] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);

  useEffect(() => {
    // console.log("🚀 VersionChecker running...");

    // Fetch frontend version dynamically from version.json
    const fetchCurrentVersion = async () => {
      try {
        const response = await fetch(`/version.json?timestamp=${Date.now()}`);
        const data = await response.json();
        // console.log("✅ Current Frontend Version:", data.version);
        setCurrentVersion(data.version);
      } catch (error) {
        // console.error("❌ Error fetching frontend version:", error);
      }
    };
    

    // Fetch latest backend version
    const fetchLatestVersion = async () => {
      try {
        const response = await api.get("/version_frontend");
        // console.log("✅ Latest Backend Version:", response.data.version);
        setLatestVersion(response.data.version);
      } catch (error) {
        // console.error("❌ Error fetching latest version:", error);
      }
    };

    fetchCurrentVersion();
    fetchLatestVersion();

    const interval = setInterval(() => {
      fetchCurrentVersion();
      fetchLatestVersion();
    }, 60000); // Check every 60 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (latestVersion && currentVersion && latestVersion !== currentVersion) {
      console.log("⚠️ Version Mismatch: Prompting user to refresh...");
      notification.info({
        message: "New Version Available",
        description: `Version ${latestVersion} is available. Please refresh the page.`,
        duration: 0,
        btn: <button onClick={() => window.location.reload()}>Refresh Now</button>,
      });
    } else {
    //   console.log("✅ No version mismatch. No refresh needed.");
    }
  }, [latestVersion, currentVersion]);

  return null;
};

export default VersionChecker;

