//PositionDetails.jsx

import React, { useState, useEffect } from 'react';

import { Table, Collapse } from 'antd';
import { useSelector } from 'react-redux';
import api from '../functions/api';
import '../css/PositionDetails.css'; // Import the CSS file
import getUserId from '../functions/getUserId';

const { Panel } = Collapse;

const PositionDetails = ({ tickerPortfolioData, maybeBlur }) => {

// console.log("tickerPortfolioData", tickerPortfolioData)

const [blockData, setBlockData] = useState([]);
// console.log("Blockdata ", blockData)

useEffect(() => {
  // console.log("Triggered ")
  const userid = getUserId(); // Get the user ID
  if (tickerPortfolioData.Ticker && userid) {
    // Fetch data from the API based on ticker and userid
    api.get('/portfolio_relative_performance', {
      params: { ticker: tickerPortfolioData.Ticker, userid },
    })
      .then((response) => {
        setBlockData(response.data.data); // Update blockData state with response data

        // console.log("Blockdata ", blockData)

      })
      .catch((error) => {
        console.error('Error fetching portfolio relative performance data:', error);
      });
  }
}, [tickerPortfolioData.Ticker]);

  // Find the record with the highest Block_Age for "In portfolio since" date
  const oldestRecord = blockData.reduce((oldest, current) => {
    return current.Block_Age > oldest.Block_Age ? current : oldest;
  }, blockData[0]);

  // Fetch exposures data from Redux store
  const exposures = useSelector(state => state.portfolio?.data?.exposures);
  // console.log("exposures ", exposures)

  // Ensure tickerValue is valid before processing
  const tickerExposureData = tickerPortfolioData?.Ticker && exposures ? exposures.find(exposure => exposure.ticker && exposure.ticker.toLowerCase() === tickerPortfolioData.Ticker.toLowerCase()) : null;

  // Prepare the data source for the Position table
  const positionDetailsDataSource = tickerPortfolioData ? [{
    key: 'positionDetails',
    field: 'Position',
    value: tickerPortfolioData.Position,
  }, {
    key: 'valueEur',
    field: 'Value (EUR)',
    value: tickerPortfolioData.Value_eur,
  }, {
    key: 'latestTxDate',
    field: 'Latest Transaction Date',
    value: tickerPortfolioData.latest_tx_date,
  }, {
    key: 'inPortfolioSince',
    field: 'In portfolio since',
    value: oldestRecord ? oldestRecord.Block_Date : 'n/a',
  }, {
    key: 'stockExposure',
    field: 'Stock in Portfolio',
    value: tickerExposureData?.stock_exposure || 'N/A',
  }, {
    key: 'trackerExposureAmount',
    field: 'via ETF in Portfolio',
    value: tickerExposureData?.tracker_exposure_amount || 'N/A',
  }, {
    key: 'totalAmountExposure',
    field: 'Total Exposure',
    value: tickerExposureData?.total_amount_exposure || 'N/A',
  },
  {
    key: 'totalAmountExposure',
    field: 'Exposure %',
    value: tickerExposureData?.total_amount_exposure_perc 
      ? `${tickerExposureData.total_amount_exposure_perc}%`
      : 'N/A',

  },
  {
    key: 'Category',
    field: 'Category',
    value: tickerPortfolioData.Category,
  },
  
  ] : [];

  // Define the columns for the Position table
  const positionDetailsColumns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      align: 'left',
      render: text => <span className="bold-text">{text}</span>,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      align: 'left',
      render: (text, record) => {
        // Add '%' for specific fields if necessary
        const fieldsWithPercentage = ['Unrealized P/L (%)', 'Performance Since Last Tx', 'Performance YTD', 'Overall Relative Performance'];
        return fieldsWithPercentage.includes(record.field) ? maybeBlur(`${text}%`) : maybeBlur(text);
      },
    },
  ];

  const performanceData = tickerPortfolioData ? [{
    key: 'unrealizedProfLossEurPerc',
    field: 'Unrealized P/L (%)',
    value: tickerPortfolioData.unrealized_prof_loss_Eur_perc,
  }, {
    key: 'performanceSinceLastTx',
    field: 'Performance Since Last Tx',
    value: tickerPortfolioData.performance_since_last_tx,
  }, {
    key: 'performanceYtd',
    field: 'Performance YTD',
    value: tickerPortfolioData.performance_ytd
    ,
  }, {
    key: 'overallRelativePerformance',
    field: 'Overall Relative Performance',
    value: tickerPortfolioData.Overall_Relative_Performance,
  }, {
    key: 'Overall_Relative_Sharpe_Ratio_Performance',
    field: 'Risk-Adjusted Performance Score',
    value: tickerPortfolioData.Overall_Relative_Sharpe_Ratio_Performance,
  }

] : [];

  const performanceColumns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      align: 'left',
      render: text => <span className="bold-text">{text}</span>,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      align: 'left',
      render: text => (text && text.toString().endsWith('%') ? text : `${text}%`), // Ensure text is checked before calling endsWith
    },
  ];

  const blockColumns = [
    {
      title: 'Block Date',
      dataIndex: 'Block_Date',
      key: 'Block_Date',
      align: 'center',
    },
    {
      title: 'Block Quantity',
      dataIndex: 'Block_Quantity',
      key: 'Block_Quantity',
      align: 'center',
    },
    {
      title: 'Annualized Performance',
      dataIndex: 'Annualized_Performance',
      key: 'Annualized_Performance',
      align: 'center',
      render: text => `${text}%`, // Add '%' for Annualized Performance
    },
    {
      title: 'Sharpe Ratio',
      dataIndex: 'block_sharpe_ratio',
      key: 'block_sharpe_ratio',
      align: 'center',
      render: text => `${text}%`, // Add '%' for Annualized Performance
    },
  ];

  const blockDataSource = blockData.map((data, index) => ({
    key: index,
    Block_Date: data.Block_Date,
    Block_Quantity: data.Block_Quantity,
    Annualized_Performance: data.Annualized_Performance,
    block_sharpe_ratio: data.block_sharpe_ratio,
  }));

  return (
    <Collapse defaultActiveKey={['1']} className="position-details-collapse">
  <Panel header="Position Details" key="1">
    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '10px' }}>
      
      <div className="table-wrapper">
        <h3>Position</h3>
        <Table
          dataSource={positionDetailsDataSource}
          columns={positionDetailsColumns}
          pagination={false}
          size="small"
          className="small-table"
          showHeader={false}
        />
      </div>

      <div className="table-wrapper">
        <h3>Performance</h3>
        <Table
          dataSource={performanceData}
          columns={performanceColumns}
          pagination={false}
          size="small"
          className="small-table"
          showHeader={false}
        />
      </div>

      <div className="table-wrapper">
        <h3>Performance/Block</h3>
        <Table
          dataSource={blockDataSource}
          columns={blockColumns}
          pagination={false}
          size="small"
          className="small-table"
        />
      </div>
      
    </div>
  </Panel>
</Collapse>

  );
};

export default PositionDetails;

