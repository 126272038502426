import React, { useEffect, useState } from 'react';
import { Table, message, Checkbox, Tooltip, Tag, Button } from 'antd';
import api from '../functions/api';
import getUserId from '../functions/getUserId';
import ThreeTablesModal from "./SearchResultModal";
import MarketAlerts from "./MarketAlerts"; // Import MarketAlerts component
import { useSelector, useDispatch } from 'react-redux';
import { renderAlertEffectiveness } from '../functions/alertClauseService'; // Import renderAlertEffectiveness

const Opportunities = ({ updateUnseen }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [isMarketAlertsVisible, setIsMarketAlertsVisible] = useState(false);
  const [selectedAlertSubtypeIdentifier, setSelectedAlertSubtypeIdentifier] = useState(null);
  const dispatch = useDispatch();

  const alertEffectiveness = useSelector(state => state?.portfolio?.data?.alert_effectiveness_data);

  const formatAlertText = (text) => {
    if (!text) return null;

    const becauseIndex = text.indexOf('because:');
    const formattedText = becauseIndex !== -1
      ? `${text.substring(0, becauseIndex + 8)}\n${text
          .substring(becauseIndex + 8)
          .replace(/ - /g, '\n- ')
          .replace(/\n+/g, '\n')
          .trim()}`
      : text;

    return formattedText.split('\n').map((line, index) => {
      if (line.startsWith('- (initiated') || line.startsWith('- (updated')) {
        return (
          <div
            key={index}
            style={{
              color: 'gray',
              fontStyle: 'italic',
              fontSize: '10px',
            }}
          >
            {line.replace(/^- /, '')}
          </div>
        );
      } else if (line.startsWith('-')) {
        return (
          <div key={index} className="bullet-container">
            {line.replace(/^- /, '')}
          </div>
        );
      }
      return <div key={index}>{line}</div>;
    });
  };

  const handleRowClick = (record) => {
    setSelectedTicker(record.ticker); // Assuming `ticker` is the unique identifier
    setIsModalOpen(true);
  };

  const fetchOpportunities = async () => {
    const userId = getUserId();
    setLoading(true);
    try {
      const response = await api.post('/get_alerts_unknown_scope', { userId });
      const alerts = response.data.alerts || [];

      // Sort by seen status first, then by alert_subtype and ticker in alphabetical order
      const sortedAlerts = Array.isArray(alerts)
        ? [...alerts].sort((a, b) => {
            const seenA = a.seen === null ? 0 : Number(a.seen);
            const seenB = b.seen === null ? 0 : Number(b.seen);

            if (seenA !== seenB) return seenA - seenB;

            if (seenA === 0 && seenB === 0) {
              if (a.alert_subtype === 'combination' && b.alert_subtype !== 'combination') return -1;
              if (a.alert_subtype !== 'combination' && b.alert_subtype === 'combination') return 1;
            }

            return a.ticker.localeCompare(b.ticker);
          })
        : [];

      setData(sortedAlerts);

      // Check if there are unseen alerts and update parent component
      const hasUnseen = sortedAlerts.some(alert => alert.seen === 0);
      updateUnseen(hasUnseen); // Pass the unseen status to `Alerts.jsx`

    } catch (error) {
      console.error('Error fetching opportunities alerts:', error);
      message.error('Failed to fetch opportunities alerts.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOpportunities();
  }, []);

  const handleCheckboxChange = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus === 1 ? 0 : 1;
      await api.post('/pf_alert_check_update', { id, seen: newStatus });
      dispatch(updateSeenStatus(id, newStatus));

      // Update the local `data` state to reflect the changes
      setData((prevData) => {
        const updatedData = prevData
          .map((item) => (item.ID === id ? { ...item, seen: newStatus } : item))
          .sort((a, b) => {
            const seenA = a.seen === null ? 0 : Number(a.seen);
            const seenB = b.seen === null ? 0 : Number(b.seen);

            if (seenA !== seenB) return seenA - seenB;

            if (seenA === 0 && seenB === 0) {
              if (a.alert_subtype === 'combination' && b.alert_subtype !== 'combination') return -1;
              if (a.alert_subtype !== 'combination' && b.alert_subtype === 'combination') return 1;
            }

            return a.ticker.localeCompare(b.ticker);
          });

        // Check if there are unseen alerts and update parent component
        const hasUnseen = updatedData.some(alert => alert.seen === 0);
        updateUnseen(hasUnseen);

        return updatedData;
      });

    } catch (error) {
      console.error('Failed to update seen status', error);
      message.error('Failed to update checkbox status.');
    }
  };

  // Redux action to update the 'seen' status
  const updateSeenStatus = (id, seen) => {
    return {
      type: 'UPDATE_SEEN_STATUS',
      payload: { id, seen },
    };
  };

  const handleOpenMarketAlerts = (alertSubtypeIdentifier) => {
    setSelectedAlertSubtypeIdentifier(alertSubtypeIdentifier);
    setIsMarketAlertsVisible(true);
  };

  const handleCloseMarketAlerts = () => {
    setIsMarketAlertsVisible(false);
    setSelectedAlertSubtypeIdentifier(null);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
    },
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
      render: (text, record) => (
        <span onClick={() => handleRowClick(record)} style={{ cursor: 'pointer' }}>
          <Tag color="blue">{record?.ticker || 'N/A'}</Tag>
          {record.age_warning && (
            <Tooltip title={record.age_warning}>
              <span
                style={{
                  color: 'red',
                  fontSize: '16px',
                  marginLeft: '8px',
                  cursor: 'help',
                }}
              >
                ⚠️
              </span>
            </Tooltip>
          )}
        </span>
      ),
    },
    {
      title: 'Alert Text',
      dataIndex: 'pf_alert_text',
      key: 'pf_alert_text',
      render: (text) => <div>{formatAlertText(text)}</div>,
    },
    {
      title: 'Alert Type',
      dataIndex: 'alert_type',
      key: 'alert_type',
    },
    {
      title: 'Alert Subtype',
      dataIndex: 'alert_subtype',
      key: 'alert_subtype',
      render: (text, record) => (
        <Button type="link" style={{ padding: 0 }} onClick={() => handleOpenMarketAlerts(record.alert_subtype_identifier)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Checked?',
      dataIndex: 'seen',
      key: 'seen',
      width: 10,
      render: (text, record) => (
        <Checkbox 
          checked={record.seen === 1} 
          onChange={() => handleCheckboxChange(record.ID, record.seen)}
        />
      ),
    },
    {
      title: 'Alert Effectiveness',
      key: 'alert_effectiveness',
      width: 185,
      render: (text, record) => renderAlertEffectiveness(alertEffectiveness, record.alert_subtype_identifier),
    },
  ];

  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        rowKey="ID"
        loading={loading}
        pagination={{ pageSize: 10 }}
      />
      <ThreeTablesModal
        tickerValue={selectedTicker}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        userId={getUserId()}
      />
      {isMarketAlertsVisible && (
        <MarketAlerts
          isVisible={isMarketAlertsVisible}
          onClose={handleCloseMarketAlerts}
          alertSubtypeIdentifier={selectedAlertSubtypeIdentifier}
        />
      )}
    </>
  );
};

export default Opportunities;
