import React, { useState, useEffect } from 'react';
import { Button, message, Table, Tag } from 'antd';
import { useSelector } from 'react-redux';
import api from '../functions/api';
import getUserId from '../functions/getUserId';

const RebalanceScenarios = () => {
    const [scenarios, setScenarios] = useState([]);
    const [scenarioResults, setScenarioResults] = useState([]);
    const [selectedScenario, setSelectedScenario] = useState(null);

    const parametersData = useSelector(state => state.portfolio.parametersData.DP_dataset_json);

    useEffect(() => {
        const fetchScenarios = async () => {
            try {
                const response = await api.get('/rebalance_scenarios');
                if (response.status === 200) {
                    const scenarios = response.data;
                    console.log('Scenarios:', scenarios);
                    setScenarios(scenarios);
                } else {
                    message.error('Failed to retrieve scenarios');
                }
            } catch (error) {
                message.error('Failed to retrieve scenarios');
            }
        };

        fetchScenarios();
    }, []);

    const handleScenarioSelect = async (scenario) => {
        console.log('Selected Scenario:', scenario);
        setSelectedScenario(scenario);

        const userId = getUserId();
        try {
            const response = await api.get('/rebalance_scenarios', {
                params: { userid: userId, scenario }
            });
            if (response.status === 200) {
                console.log('Scenario Results:', response.data);
                setScenarioResults(response.data);
                message.success('Scenario results successfully retrieved');
            } else {
                message.error('Failed to retrieve scenario results');
            }
        } catch (error) {
            message.error('Failed to retrieve scenario results');
        }
    };

    const formatValue = (metric) => {
        const parameter = parametersData.find(param => param.DataField === metric.metric);
        const showInfront = parameter?.show_infront || '';
        const showBehind = parameter?.show_behind || '';
        return `${showInfront}${metric.value}${showBehind}`;
    };

    const columns = [
        {
            title: 'Ticker',
            dataIndex: 'ticker',
            key: 'ticker',
            render: (text, record) => (
                <div>
                    <Tag color="blue">{record?.ticker || 'N/A'}</Tag>
                    <div>{record.company}</div>
                    <div>Total Score: {parseInt(record.total_score)}</div>
                    <div>Total Amount Exposure: €{record.total_amount_exposure}</div>
                </div>
            ),
        },
        {
            title: 'Metric',
            dataIndex: 'metrics',
            key: 'metric',
            render: (metrics) => (
                <div>
                    {metrics.map(metric => (
                        <div key={metric.metric}>{metric.metric}</div>
                    ))}
                </div>
            ),
        },
        {
            title: 'Value',
            dataIndex: 'metrics',
            key: 'value',
            render: (metrics) => (
                <div>
                    {metrics.map(metric => (
                        <div key={metric.metric}>{formatValue(metric)}</div>
                    ))}
                </div>
            ),
        },
        {
            title: 'Sector Avg',
            dataIndex: 'metrics',
            key: 'sector_avg',
            render: (metrics) => (
                <div>
                    {metrics.map(metric => (
                        <div key={metric.metric}>{metric.sector_avg}</div>
                    ))}
                </div>
            ),
        },
        {
            title: 'Weight',
            dataIndex: 'metrics',
            key: 'weight',
            render: (metrics) => (
                <div>
                    {metrics.map(metric => (
                        <div key={metric.metric}>{parseInt(metric.weight)}%</div>
                    ))}
                </div>
            ),
        },
        {
            title: 'Score',
            dataIndex: 'metrics',
            key: 'score',
            render: (metrics) => (
                <div>
                    {metrics.map(metric => (
                        <div key={metric.metric}>{parseInt(metric.score)}</div>
                    ))}
                </div>
            ),
        },
    ];

    const dataSource = scenarioResults.map(result => ({
        ticker: result.ticker,
        company: result.company,
        total_score: result.total_score,
        total_amount_exposure: result.total_amount_exposure,
        metrics: result.metrics,
    }));

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '20px' }}>
                {scenarios.map(scenario => (
                    <Button 
                        key={scenario}
                        type={selectedScenario === scenario ? 'primary' : 'default'}
                        style={{ marginBottom: '10px', backgroundColor: selectedScenario === scenario ? 'red' : undefined }}
                        onClick={() => handleScenarioSelect(scenario)}
                    >
                        {scenario}
                    </Button>
                ))}
            </div>
            <div style={{ flex: 1 }}>
                {selectedScenario && <h3>Selected Scenario: {selectedScenario}</h3>}
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    rowKey="ticker"
                    pagination={false}
                />
            </div>
        </div>
    );
};

export default RebalanceScenarios;
