// REACT widgetReducer.js
const initialState = {
  widgetPreferences: [],  // Stores widget preferences
  widgetPreload: []       // Stores widget preload data
};

const widgetReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'SET_WIDGET_PREFERENCES':
          return {
              ...state,
              widgetPreferences: action.payload.widgetPreferences,
              widgetPreload: action.payload.widgetPreload // Store preload data as well
          };

      case 'UPDATE_WIDGET_CONTENT':
          const { userId, widgetNumber, newContentId } = action.payload;
          return {
              ...state,
              widgetPreferences: state.widgetPreferences.map(preference =>
                  preference.userid === userId && preference.widget_number === widgetNumber
                      ? { ...preference, widgetcontentid: newContentId }
                      : preference
              )
          };

      default:
          return state;
  }
};

export default widgetReducer;


// const initialState = {
//     widgetPreferences: [] // Assuming the initial state structure for widget preferences
//   };
  
//   const widgetReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case 'UPDATE_WIDGET_CONTENT':
//         const { userId, widgetNumber, newContentId } = action.payload;
//         return {
//           ...state,
//           widgetPreferences: state.widgetPreferences.map(preference =>
//             preference.userid === userId && preference.widget_number === widgetNumber
//               ? { ...preference, widgetcontentid: newContentId }
//               : preference
//           )
//         };
  
//       case 'SET_WIDGET_PREFERENCES':
//         return {
//           ...state,
//           widgetPreferences: action.payload
//         };
  
//       default:
//         return state;
//     }
//   };
  
//   export default widgetReducer;
  