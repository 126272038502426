import React from 'react';
import { Tabs } from 'antd';
import RebalanceTargetSetting from './Rebalance_Targetsetting';
import RebalanceScenarios from './Rebalance_Scenarios';

const { TabPane } = Tabs;

const Rebalance = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h2>Portfolio Rebalancing</h2>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Rebalance Targets" key="1">
                    <RebalanceTargetSetting />
                </TabPane>
                <TabPane tab="Rebalance Scenarios" key="2">
                    <RebalanceScenarios />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default Rebalance;
