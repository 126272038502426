import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Tag, Collapse, message, Modal, Table, Button, Tooltip, Select, Space, Spin, Tabs, Badge, Input, AutoComplete } from 'antd';
import api from '../functions/api';
import '../PortfolioModal.css';
import getUserId from '../functions/getUserId';
import { CloseOutlined } from '@ant-design/icons';
import ThreeTablesModal from "./SearchResultModal";
import { groupBy } from 'lodash';
import ToplineTable from './ToplineTable';
import TickerSearch from './TickerSearch';
import PreferencesModal from './PreferencesModal.jsx';
import usePortfolioData from '../functions/fetchPortfolioData';
import ESG_MainTable from './ESG_MainTable';
import useLocalStorage from '../functions/useLocalStorage';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { getPortfolioData } from '../redux/reducers/portfolioReducer';
import PF_Piecharts_Analysis from './PF_Piecharts_Analysis.jsx';
import PF_Analysis from './PF_Analysis.jsx';
import PF_Exposure from './PF_Exposure.jsx';
import Benchmark_YTD from './Benchmark_YTD'; 
import PF_Alerts from './PF_Alerts';  
import Rebalance from './Rebalance';
import Portfolio_Performance from './Portfolio_Performance.jsx';
import PF_Risk from "./PF_Risk"; // Import the new PF_Risk component

const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;

const PortfolioModal = ({ portvisible, onClose }) => {
  const userId = getUserId();
  const { 
    portfolioData = [], 
    tableColumns = [], 
    categories: categoryOptions = [], 
    fetchPortfolioData,
    portfolioTopline = [],
    portfolioTickers = []
  } = usePortfolioData(userId);

  const hasUnseenpfAlerts = useSelector(state =>
    state.portfolio.data.pf_alert?.some(alert => alert.seen === 0)
  );

  const hasUnseenmarketAlerts = useSelector(state =>
    state.portfolio.data.market_alerts?.some(alert => {
      const seenbyUsers = alert.seenby 
        ? alert.seenby.split(',').map(id => id.trim()).filter(id => id) 
        : [];
      return userId && !seenbyUsers.includes(userId.toString());
    })
  );

  const dispatch = useDispatch();
  const doesCategoryHaveAlert = (records) => {
    return records.some(record => tickerAlerts?.includes(record.Ticker));
  };

  const [isTableReady, setIsTableReady] = useState(false);
  const fieldMetadata = useSelector(state => state?.portfolio?.data?.field_metadata);
  const updatedCategoryRecords = useSelector(state => state?.portfolio?.data?.updated_category_records);
  const [esgData, setEsgData] = useState(null);
  const dataparameters = useSelector(state => state.portfolio.parametersData);
  const harvestAlerts = useSelector(state => state?.portfolio?.data?.pf_alert);
  const tickerAlerts = harvestAlerts?.map(alert => alert.ticker);
  const pfDataAlerts = useSelector(state => state?.portfolio?.data?.pf_data_alert);

  const [token, setToken] = useLocalStorage('token', null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isDataFetched, setDataFetched] = useState(false);
  const [completionCounter, setCompletionCounter] = useState(0);

  const handleRefreshClick = async () => {
    setIsRefreshing(true);
    setDataFetched(true);
    const token = localStorage.getItem('token');

    try {
      await fetchAndUpdatePortfolioData();
      // console.log("Before dispatch:", stored_portfolioData);
      await dispatch(getPortfolioData(localStorage.getItem('token'))).unwrap();
      // console.log("After dispatch:", stored_portfolioData);

    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fetchAndUpdatePortfolioData = useCallback(async () => {
    await fetchPortfolioData(stored_portfolioData, fieldMetadata);
    setCompletionCounter(0);
    setIsTableReady(true);
  }, [fetchPortfolioData, portfolioTickers]);

  useEffect(() => {
    if (portfolioData) {
      setCompletionCounter(prevCount => prevCount + 1);
      setDataFetched(false);
    }
  }, [portfolioData]);

  useEffect(() => {
    if (completionCounter === 1) {
      setIsRefreshing(false);
      setCompletionCounter(0);
    }
  }, [completionCounter]);

  useEffect(() => {
    if (isTableReady && !isRefreshing) {
      setIsRefreshing(false);
    }
  }, [isTableReady, isRefreshing]);

  const [aggregatedData, setAggregatedData] = useState([]);
  const stored_portfolioData = useSelector((state) => state.portfolio.data);
  const fetchedEsgData = useSelector((state) => state.portfolio.esgData);

  if (!isEqual(fetchedEsgData, esgData)) {
    setEsgData(fetchedEsgData);
  }

  const handleTickerSelect = (ticker, record) => {
    const data = {
      UserID: userId,
      Ticker: ticker,
      Company: record.Company,
      Position: record.Position,
      Close: record.Close,
      Value_eur: record.Value_eur,
    };
    api.post("/portfolio_view_update", data)
      .then((response) => {
        message.success(`Ticker ${ticker} assigned to ${record.Company}`);
      })
      .catch((error) => {
        console.error("There was an error updating the portfolio view:", error);
      });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [isPreferencesModalVisible, setIsPreferencesModalVisible] = useState(false);

  const onRowClick = async (record) => {
    try {
      setSelectedTicker(record.Ticker);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };

  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [editingCategoryValue, setEditingCategoryValue] = useState(null);
  const [editingRecordId, setEditingRecordId] = useState(null);
  const [newCategoryValue, setNewCategoryValue] = useState(null);

  const epochToDate = (epoch) => {
    const date = new Date(epoch * 1000);
    return date.toLocaleString();
  };

  const getLatestDate = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) return null;
    const latestEpoch = Math.max(...data.map(item => item.Epoch));
    return epochToDate(latestEpoch);
  };

  const updateCategory = async (recordId, newCategoryValue) => {
    const userId = getUserId();
    const record = portfolioData.find(item => item.id === recordId);
    if (!record) {
      console.error('Failed to find the record by recordId');
      return;
    }
    try {
      await api.put('/category_update', { userId, ticker: record.Ticker, category: newCategoryValue, company: record.Company });
      message.success('Category updated successfully');
    } catch (error) {
      message.error('Failed to update category');
      console.error(error);
    }
  };

  useEffect(() => {
    if (!portfolioData) return;
    const dataByCategories = groupBy(portfolioData, (item) => item.Category || 'Undefined');
    const data = Object.entries(dataByCategories).map(([category, records]) => ({
      category,
      records,
      totalValue: records.reduce((sum, record) => sum + (parseFloat(record.Value_eur) || 0), 0)
    }));

    if (!isEqual(data, aggregatedData)) {
      setAggregatedData(data);
    }

    Object.entries(dataByCategories).forEach(([category, records]) => {
      const totalValue = records.reduce((sum, record) => sum + (parseFloat(record.Value_eur) || 0), 0);
    });

    setAggregatedData(data);
  }, [portfolioData]);

  useEffect(() => {
    if (editingCategoryId === null) return;
    setAggregatedData(prevAggregatedData => 
      prevAggregatedData.map(({ category, records }) => ({
        category,
        records: records.map(record =>
          record.id === editingCategoryId
            ? { ...record, Category: editingCategoryValue }
            : record
        ),
      }))
    );
    setEditingCategoryId(null);
    setEditingCategoryValue(null);
  }, [editingCategoryId, editingCategoryValue]);

  const [searchValue, setSearchValue] = useState('');
  const [filteredPortfolioData, setFilteredPortfolioData] = useState([]);
  const [activeKey, setActiveKey] = useState([]);

  const handleSearch = (value) => {
    setSearchValue(value);
    const filteredRecords = portfolioData.filter(record =>
      (record.Ticker || '').toLowerCase().includes(value.toLowerCase())
    );
    setFilteredPortfolioData(filteredRecords);

    if (filteredRecords.length > 0) {
      const category = filteredRecords[0].Category;
      setActiveKey([category]);
    }
  };

  const handleSelect = (value) => {
    const filteredRecords = portfolioData.filter(record =>
      (record.Ticker || '').toLowerCase() === value.toLowerCase()
    );

    if (filteredRecords.length > 0) {
      const category = filteredRecords[0].Category;
      setActiveKey([category]);
      setFilteredPortfolioData(filteredRecords);
    }
  };

  return (
    <>
      <Modal
        open={portvisible}
        title={
          <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
            Your Portfolio Status
            <Space>
              {isRefreshing ? (
                <Spin />
              ) : (
                <Button key="refresh" type="secondary" onClick={handleRefreshClick}>
                  Refresh
                </Button>
              )}
              <CloseOutlined onClick={onClose} style={{ cursor: 'pointer' }} />
            </Space>
          </Space>
        }
        onCancel={onClose}
        width={'80%'}
        closeIcon={null}
        footer={[
          <Button key="submit" type="primary" onClick={onClose}>
            OK
          </Button>,
          isRefreshing ? <Spin /> : <Button key="refresh" type="secondary" onClick={handleRefreshClick}>Refresh</Button>,
        ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Generic Performance" key="1">
            <h2>{getLatestDate(portfolioData)}</h2>
            <ToplineTable 
              tableLayout="fixed"
              portfolioData={portfolioData} 
              updatedCategoryRecords={updatedCategoryRecords} 
              portfolioTopline={Array.isArray(portfolioTopline) ? portfolioTopline : []} 
            />
            <Benchmark_YTD />
            <Portfolio_Performance />
          </TabPane>

          <TabPane tab="Category Performance" key="2">
            <h2>Performance per Category</h2>
            <AutoComplete
              style={{ width: 200, marginBottom: '20px' }}
              options={portfolioData
                .filter(record => (record.Ticker || '').toLowerCase().includes(searchValue.toLowerCase()))
                .map(record => ({ value: record.Ticker }))}
              onSearch={handleSearch}
              onSelect={handleSelect}
              placeholder="Search Ticker"
            />
            <Collapse activeKey={activeKey} onChange={setActiveKey} defaultActiveKey={[]}>
              {aggregatedData.map(({ category, records, totalValue = 0 }, index) => {
                const categoryRecord = updatedCategoryRecords?.find(record => record.category.toLowerCase() === category.toLowerCase());       
                const headerContent = categoryRecord 
                  ? (
                      <>
                        {doesCategoryHaveAlert(records) && <span style={{color: 'red', fontSize: '24px'}}>• </span>}
                        <b>{category}</b>  {categoryRecord.number_of_stocks} items, {categoryRecord.part_of_portfolio} % of portfolio, 
                        Value: €{totalValue.toFixed(2)}, Performance: {categoryRecord.performance}%, 
                        Benchmark:{categoryRecord.benchmark1}
                      </>
                    )
                  : <b>{category}</b>;
                const filteredRecords = searchValue ? records.filter(record => (record.Ticker || '').toLowerCase() === searchValue.toLowerCase()) : records;
                return (
                  <Panel header={headerContent} key={category}>
                    <div style={{ position: 'relative', textAlign: 'left',overflowX: 'auto', maxWidth: '100%' }}>
                      <Table 
                        dataSource={filteredRecords} 
                        columns={tableColumns.map(column => {
                          if (fieldMetadata && (column.dataIndex in fieldMetadata)) {
                            return {
                              ...column,
                              sorter: (a, b) => {
                                if (typeof a[column.dataIndex] === 'number' && typeof b[column.dataIndex] === 'number') {
                                  return a[column.dataIndex] - b[column.dataIndex];
                                }
                                if (typeof a[column.dataIndex] === 'string' && typeof b[column.dataIndex] === 'string') {
                                  return a[column.dataIndex].localeCompare(b[column.dataIndex]);
                                }
                                return 0;
                              },
                              render: (value, record) => (
                                <Tooltip 
                                  title={fieldMetadata[column.dataIndex].Tooltip} 
                                  mouseEnterDelay={1.5}
                                >
                                  {value} 
                                </Tooltip>
                              ),
                            }
                          } else if (column.dataIndex === 'Ticker') {
                            return { 
                              ...column,
                              align: 'left',
                              width: '10px',
                              sorter: (a, b) => a.Ticker.localeCompare(b.Ticker),
                              render: (value, record) => (
                                <Tooltip title={record.Company} mouseEnterDelay={2.5} color='#1677ff' placement="lt">
                                  <div style={{ position: 'relative', textAlign: 'left' }}>
                                    <div style={{ zIndex: 0, position: 'relative', textAlign: 'left' }}>
                                      {tickerAlerts?.includes(value) && <span style={{color: 'red', fontSize: '24px', textAlign: 'left'}}>• </span>}
                                      {pfDataAlerts?.some(alert => alert[2] === value) && <span style={{color: 'blue', fontSize: '24px', textAlign: 'left'}}>• </span>}
                                      <Tag color="blue">{value}</Tag>
                                      <p style={{ fontSize: 8, marginTop: '15px' }} onClick={e => e.stopPropagation()}>
                                        {editingRecordId === record.id
                                          ? (
                                            <Select
                                              defaultValue={record.Category}
                                              onChange={(selectedValue) => {
                                                setNewCategoryValue(selectedValue);
                                                setEditingCategoryId(record.id);
                                                setEditingCategoryValue(selectedValue);
                                                updateCategory(record.id, selectedValue); 
                                                setEditingRecordId(null);
                                                document.activeElement.blur(); 
                                              }}
                                              style={{ width: 120 }} 
                                              size="small"
                                            >
                                              {categoryOptions.map((option, index) => (
                                                <Option value={option[0]} key={index}>{option[0]}</Option>
                                              ))}
                                            </Select>
                                          )
                                          : !record.Ticker
                                            ? (
                                              <>
                                                <TickerSearch 
                                                  dropdownStyle={{ minWidth: '200px' }}
                                                  onTickerSelect={(ticker) => handleTickerSelect(ticker, record)}
                                                />
                                                <span style={{ display: 'block', marginTop: '5px', cursor: 'pointer' }} onClick={() => setEditingRecordId(record.id)}>
                                                  {record.Category}
                                                </span>
                                              </>
                                            )
                                            : (
                                              <span style={{ cursor: 'pointer' }} onClick={() => setEditingRecordId(record.id)}>
                                                {record.Category}
                                              </span>
                                            )
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </Tooltip>
                              ),
                            }
                          } else if (column.dataIndex === 'Company') {
                            return { 
                              ...column,
                              width: '75px', 
                              sorter: (a, b) => a.Company.localeCompare(b.Company),
                              render: (value, record) => (
                                <Tooltip title={value} mouseEnterDelay={1.5} color='#1677ff' placement="lt">
                                  <div style={{ position: 'relative', textAlign: 'left' }}>
                                    <div style={{ fontSize: 10, zIndex: 0, position: 'relative', textAlign: 'left' }}>
                                      {value && value.length > 12 ? value.substring(0, 30) + '..' : value}
                                      <p style={{ fontSize: 8, marginTop: '15px' }} onClick={e => e.stopPropagation()}>
                                        {record.account}
                                      </p>
                                    </div>
                                  </div>
                                </Tooltip>
                              ),
                            }
                          } else {
                            return column;
                          }
                        })}
                        rowKey="id" 
                        pagination={false}
                        rowClassName="tableRow"
                        expandable={{
                          expandedRowRender: (record) => {
                            if (harvestAlerts.some(alert => alert.ticker === record.Ticker)) {
                              return (
                                <p style={{ color: 'red' }}>
                                  {harvestAlerts.find(alert => alert.ticker === record.Ticker).pf_alert_text}
                                </p>
                              );
                            }
                            return null;
                          },
                          rowExpandable: (record) => harvestAlerts?.some(alert => alert.ticker === record.Ticker)
                        }}
                        onRow={(record) => ({ onClick: () => onRowClick(record) })}
                        onHeaderRow={column => {
                          return {
                            onClick: () => { 
                              setIsPreferencesModalVisible(true);
                            },
                          };
                        }}         
                      />
                      {isModalVisible && (
                        <ThreeTablesModal 
                          tickerValue={selectedTicker} 
                          open={isModalVisible} 
                          onClose={() => setIsModalVisible(false)} 
                          userId={userId} 
                        />
                      )}
                    </div>
                  </Panel>
                )
              })}
            </Collapse>
          </TabPane>

          <TabPane 
            tab={
              <Badge dot={hasUnseenpfAlerts} color="red">
                <span>Portfolio Alerts</span>
              </Badge>
            } 
            key="3"
          >
            <PF_Alerts /> 
          </TabPane>

          <TabPane 
            tab={
              <span>Rebalance</span>
            } 
            key="4"
          >
            <Rebalance /> 
          </TabPane>

          <TabPane tab="ESG" key="5">
            {esgData && 
              <ESG_MainTable
                dataSet={esgData.data || []}
                metadata={esgData.metadata || []}
                dataparametersSet={dataparameters || []}
              />
            }
          </TabPane>

          <TabPane tab="Pie Charts" key="6">
            <PF_Piecharts_Analysis />
          </TabPane>

          <TabPane tab="Analysis" key="7">
            <PF_Analysis />
          </TabPane>

          <TabPane tab="Exposure" key="8">
            <PF_Exposure />
          </TabPane>

          <TabPane tab="Risk" key="risk">
            <PF_Risk /> {/* Render the PF_Risk component */}
          </TabPane>

          
        </Tabs>
      </Modal>
      {isPreferencesModalVisible && (
        <PreferencesModal
          userId={userId}
          onClose={() => setIsPreferencesModalVisible(false)}
        />
      )}
    </>
  );
};

export default PortfolioModal;