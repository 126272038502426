//PF_Exposure.jsx
import React, { useEffect, useState } from "react";
import api from '../functions/api';
import { useSelector } from 'react-redux';
import { Table, Collapse, Tag } from 'antd'; // Ensure Tag is imported
import ThreeTablesModal from "./SearchResultModal";
import getUserId from '../functions/getUserId';
import Piecharts_MUI from "./Piecharts_MUI";

const { Panel } = Collapse;


const PF_Exposure = () => {
    const [isTableExpanded, setIsTableExpanded] = useState(true); // State to track table collapse
    const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
    const [selectedTicker, setSelectedTicker] = useState(null); // State to store selected ticker
    
    const portfolioData = useSelector(state => state.portfolio.data);
    // console.log("portfolioData", portfolioData);
    const userId = getUserId();
    const demoMode = useSelector(state => state.demoMode.isDemoMode);
    const maybeBlur = (text) => demoMode ? <span className="blur-text">{text}</span> : text;
    const [exposureData, setExposureData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

   
    useEffect(() => {
      if (!userId) {
        console.warn("userId not ready, skipping exposure fetch");
        return;
      }
      console.log("useEffect triggered in PF_Exposure");
      console.log("userId in PF_Exposure", userId);

      const fetchData = async () => {
        try {
          const res = await api.get(`/pf_other_exposures`, {
            params: { userid: userId },
          });
      
          console.log("Raw API response for pf_other_exposures", res);
          console.log("Response .data", res.data);
          console.log("Response .data.portfolio_various_exposures", res.data.portfolio_various_exposures);
      
          setExposureData(res.data.portfolio_various_exposures);
        } catch (err) {
          console.error("Error fetching pf_other_exposures", err);
          setError("Failed to fetch exposure data.");
        } finally {
          setLoading(false);
        }
      };
      
    
        fetchData();
      }, []);
  
    if (!portfolioData || !portfolioData.exposures || portfolioData.exposures.length === 0) {
      return <div>No data available</div>;
    }

    const safeParseFloat = (str) => {
        const value = parseFloat(str);
        return isNaN(value) ? 0 : value; // Treat non-numeric/invalid values as 0
      };

    const columns = [
      {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
        render: (text, record) => (
          <div>
            <a onClick={() => {
              setSelectedTicker(record.ticker);
              setIsModalVisible(true);
            }}>
              <Tag color="blue">{text}</Tag>
            </a>
            <div style={{ fontSize: '10px', color: '#888' }}>
              {record.company || ''}
            </div>
            {record.Assettype && (
              <div style={{ fontSize: '10px', fontStyle: 'italic', color: '#888' }}>
                {record.Assettype}
              </div>
            )}
          </div>
        )
        
      },
      
      {
        title: 'Part of Total Portfolio',
        dataIndex: 'total_amount_exposure_perc',
        key: 'total_amount_exposure_perc',
        sorter: (a, b) => parseFloat(a.total_amount_exposure_perc) - parseFloat(b.total_amount_exposure_perc),
        render: (text, record) => (
          <div>
            {`${parseFloat(text).toFixed(2)}%`}
            {record.part_underlying_assets_unknown !== null && (
              <div style={{ fontSize: '10px', color: '#888' }}>
                Unknown holdings part
              </div>
            )}
          </div>
        )
      },
      
      { 
        title: 'Total Amount Exposure', 
        dataIndex: 'total_amount_exposure', 
        key: 'total_amount_exposure',
        sorter: (a, b) => parseFloat(a.total_amount_exposure) - parseFloat(b.total_amount_exposure),
        render: (text, record) => (
          <div>
            {maybeBlur(text)}
            {record.part_underlying_assets_unknown !== null && (
              <div style={{ fontSize: '10px', color: '#888' }}>
                Unknown holdings part
              </div>
            )}
          </div>
        )
      },
      
      {
        title: 'Tracker Exposure Amount',
        dataIndex: 'tracker_exposure_amount',
        key: 'tracker_exposure_amount',
        sorter: (a, b) => parseFloat(a.tracker_exposure_amount) - parseFloat(b.tracker_exposure_amount),
        render: (text, record) => {
          const details = record.tracker_exposure ? record.tracker_exposure.split(', ').map((detail, index) => (
            <div key={index}>{maybeBlur(detail)}</div>  // Apply blur to each detail
          )) : null;
      
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>{maybeBlur(record.tracker_exposure_amount)}</div>  
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right', fontSize: 10 }}>
                {details}
              </div>
            </div>
          );
        },
      },
      
      { 
        title: 'Stock Exposure', 
        dataIndex: 'stock_exposure', 
        key: 'stock_exposure',
        sorter: (a, b) => safeParseFloat(a.stock_exposure) - safeParseFloat(b.stock_exposure),
        render: (text) => maybeBlur(text)
      },
    ];

    const dataSource = portfolioData.exposures.map((exposure, index) => ({
      key: index,
      ticker: exposure.ticker,
      company: exposure.company,
      Assettype: exposure.Assettype, // <-- add this line
      total_amount_exposure: exposure.total_amount_exposure,
      tracker_exposure_amount: exposure.tracker_exposure_amount,
      tracker_exposure: exposure.tracker_exposure,
      stock_exposure: exposure.stock_exposure,
      total_amount_exposure_perc: exposure.total_amount_exposure_perc,
      part_underlying_assets_unknown: exposure.part_underlying_assets_unknown
    }));
    


    return (
      <>
        <Collapse defaultActiveKey={[]}>
          <Panel header="Exposure Overview" key="overview">
          {exposureData && (
  <>
    <Piecharts_MUI
      data={{ 0: Object.fromEntries(exposureData.base_currency.map((item, idx) => [idx, { percent: item.exposure_perc }])) }}
      title="Currency Exposure"
      labelPrefix="Currency"
      legendInformation={Object.fromEntries(exposureData.base_currency.map((item, idx) => [idx, item.name]))}
      legendFontSize={12}
    />

    <Piecharts_MUI
      data={{ 0: Object.fromEntries(exposureData.country.map((item, idx) => [idx, { percent: item.exposure_perc }])) }}
      title="Country Exposure"
      labelPrefix="Country"
      legendInformation={Object.fromEntries(exposureData.country.map((item, idx) => [idx, item.name]))}
      legendFontSize={12}
    />

    <Piecharts_MUI
      data={{ 0: Object.fromEntries(exposureData.industry.map((item, idx) => [idx, { percent: item.exposure_perc }])) }}
      title="Industry Exposure"
      labelPrefix="Industry"
      legendInformation={Object.fromEntries(exposureData.industry.map((item, idx) => [idx, item.name]))}
      legendFontSize={12}
    />

    <Piecharts_MUI
      data={{ 0: Object.fromEntries(exposureData.sector.map((item, idx) => [idx, { percent: item.exposure_perc }])) }}
      title="Sector Exposure"
      labelPrefix="Sector"
      legendInformation={Object.fromEntries(exposureData.sector.map((item, idx) => [idx, item.name]))}
      legendFontSize={12}
    />
  </>
)}

          </Panel>
    
          <Panel header="Exposure Details" key="details">
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{ pageSize: 10 }}
            />
          </Panel>
        </Collapse>
    
        {isModalVisible && (
          <ThreeTablesModal 
            tickerValue={selectedTicker}
            open={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            userId={userId}
          />
        )}
      </>
    );
    
};

export default PF_Exposure;
