import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tag, Tooltip, Checkbox, Button } from 'antd';
import api from '../functions/api';
import getUserId from '../functions/getUserId';
import ThreeTablesModal from "./SearchResultModal";
import MarketAlerts from "./MarketAlerts"; // Import MarketAlerts component
import { renderAlertEffectiveness } from '../functions/alertClauseService'; // Import renderAlertEffectiveness

// Redux action to update the 'seen' status
const updateSeenStatus = (id, seen) => {
  return {
    type: 'UPDATE_SEEN_STATUS',
    payload: { id, seen },
  };
};

const FavoritesAlerts = () => {
  const dispatch = useDispatch();
  // Fetch the alerts data from the Redux store
  const rawAlerts = useSelector(state => state.favorites?.data?.favorites_alert_data) || [];
  const companyData = useSelector(state => state.favorites?.data?.data) || []; // Get company info

  // Create a dictionary for fast lookup: { Ticker: Company Name }
  const companyMap = companyData.reduce((acc, item) => {
    if (item.Ticker) {
      acc[item.Ticker] = item.Company || 'N/A';
    }
    return acc;
  }, {});

  // Merge company names into alerts
  const alerts = rawAlerts.map(alert => ({
    ...alert,
    company: companyMap[alert.ticker] || 'N/A', // Assign company name if available
  }));

  const sortedAlerts = Array.isArray(alerts)
    ? [...alerts].sort((a, b) => {
        const seenA = a.seen === null ? 0 : Number(a.seen);
        const seenB = b.seen === null ? 0 : Number(b.seen);

        if (seenA !== seenB) return seenA - seenB;

        if (seenA === 0 && seenB === 0) {
          if (a.alert_subtype === 'combination' && b.alert_subtype !== 'combination') return -1;
          if (a.alert_subtype !== 'combination' && b.alert_subtype === 'combination') return 1;
        }

        return a.ticker.localeCompare(b.ticker);
      })
    : [];

  const alertEffectiveness = useSelector(state => state?.portfolio?.data?.alert_effectiveness_data);

  const userId = getUserId();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [isMarketAlertsVisible, setIsMarketAlertsVisible] = useState(false);
  const [selectedAlertSubtypeIdentifier, setSelectedAlertSubtypeIdentifier] = useState(null);

  const handleRowClick = (ticker) => {
    setSelectedTicker(ticker);
    setIsModalVisible(true);
  };

  const handleCheckboxChange = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus === 1 ? 0 : 1;
      await api.post('/pf_alert_check_update', { id, seen: newStatus });

      dispatch({
        type: 'UPDATE_SEEN_STATUS',
        payload: { id, seen: newStatus },
      });

      // Force re-fetch alerts from Redux store to trigger re-render
      dispatch({ type: 'REFRESH_ALERTS' });

    } catch (error) {
      console.error('Failed to update seen status', error);
    }
  };

  const handleOpenMarketAlerts = (alertSubtypeIdentifier) => {
    setSelectedAlertSubtypeIdentifier(alertSubtypeIdentifier);
    setIsMarketAlertsVisible(true);
  };

  const handleCloseMarketAlerts = () => {
    setIsMarketAlertsVisible(false);
    setSelectedAlertSubtypeIdentifier(null);
  };

  const formatAlertText = (text) => {
    if (!text) return null;

    const becauseIndex = text.indexOf('because:');
    const formattedText = becauseIndex !== -1
      ? `${text.substring(0, becauseIndex + 8)}\n${text
          .substring(becauseIndex + 8)
          .replace(/ - /g, '\n- ')
          .replace(/\n+/g, '\n')
          .trim()}`
      : text;

    return formattedText.split('\n').map((line, index) => {
      if (line.startsWith('- (initiated') || line.startsWith('- (updated')) {
        return (
          <div
            key={index}
            style={{
              color: 'gray',
              fontStyle: 'italic',
              fontSize: '10px',
            }}
          >
            {line.replace(/^- /, '')}
          </div>
        );
      } else if (line.startsWith('-')) {
        return (
          <div key={index} className="bullet-container">
            {line.replace(/^- /, '')}
          </div>
        );
      }
      return <div key={index}>{line}</div>;
    });
  };

  const columns = [
    {
      title: 'Company',
      key: 'company',
      width: 180,
      render: (text, record) => {
        const companyName = record?.company ?? 'N/A';
        const category = record?.Category ?? 'N/A';

        return (
          <Tooltip title={companyName}>
            <div>
              <div
                style={{ cursor: 'pointer', color: 'blue', fontWeight: 'bold' }}
                onClick={() => handleRowClick(record?.ticker)}
              >
                {companyName.length > 20 ? `${companyName.substring(0, 20)}...` : companyName}
              </div>
              <div style={{ marginTop: '2px' }}>
                <Tag color="blue">{record?.ticker || 'N/A'}</Tag>
              </div>
              <div style={{ fontSize: '12px', color: 'gray' }}>Share price:</div>
              <div style={{ fontSize: '12px', color: 'gray' }}>
                at alert initiation: {record?.price_at_initiation ? parseFloat(record.price_at_initiation).toFixed(2) : 'N/A'}
              </div>
              <div style={{ fontSize: '12px', color: 'gray' }}>
                current: {record?.price_current ? parseFloat(record.price_current).toFixed(2) : 'N/A'}
              </div>
              <div style={{ fontSize: '12px', color: 'black', fontWeight: 'bold' }}>
                Category: {category}
              </div>
              {record.age_warning && (
                <Tooltip title={record.age_warning}>
                  <span
                    style={{
                      color: 'red',
                      fontSize: '16px',
                      marginLeft: '8px',
                      cursor: 'help',
                    }}
                  >
                    ⚠️
                  </span>
                </Tooltip>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Alert Type',
      dataIndex: 'alert_type',
      key: 'alert_type',
      width: 30,
    },
    {
      title: 'Alert Subtype',
      dataIndex: 'alert_subtype',
      key: 'alert_subtype',
      width: 40,
      render: (text, record) => (
        <div>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => handleOpenMarketAlerts(record.alert_subtype_identifier)}
          >
            {text}
          </Button>
          <div style={{ fontSize: '12px', color: 'gray' }}>
            ({record.alert_subtype_identifier})
          </div>
        </div>
      ),
    },
    {
      title: 'Alert Text',
      dataIndex: 'pf_alert_text',
      key: 'pf_alert_text',
      render: (text) => (
        <Tooltip title={text}>
          <div>{formatAlertText(text)}</div>
        </Tooltip>
      ),
    },
    {
      title: 'Checked?',
      dataIndex: 'seen',
      key: 'seen',
      width: 20,
      render: (text, record) => (
        <Checkbox 
          checked={record.seen === 1} 
          onChange={() => handleCheckboxChange(record.ID, record.seen)}
        />
      ),
    },
    {
      title: 'Alert Effectiveness',
      key: 'alert_effectiveness',
      width: 185,
      render: (text, record) => renderAlertEffectiveness(alertEffectiveness, record.alert_subtype_identifier),
    }
  ];

  return (
    <> 
      <div style={{ padding: '20px' }}>
        <h2>Favorites Alerts</h2>
        <Table
          dataSource={sortedAlerts}
          columns={columns}
          rowKey="ID"
          pagination={{ pageSize: 10 }}
        />
      </div>

      {isModalVisible && (
        <ThreeTablesModal 
          tickerValue={selectedTicker} 
          open={isModalVisible} 
          onClose={() => setIsModalVisible(false)} 
          userId={userId} 
        />
      )}
      {isMarketAlertsVisible && (
        <MarketAlerts
          isVisible={isMarketAlertsVisible}
          onClose={handleCloseMarketAlerts}
          alertSubtypeIdentifier={selectedAlertSubtypeIdentifier}
        />
      )}
    </>
  );
};

export default FavoritesAlerts;