////PF_Alerts

import React, { useState } from 'react';
import { Table, Tooltip, Checkbox, Button, Modal, Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import ThreeTablesModal from "./SearchResultModal";
import getUserId from '../functions/getUserId';
import api from '../functions/api';
import epochToDate from '../functions/epochdate';
import OpportunityCheck from "./OpportunityCheck";
// import AlertTable from "./AlertTable";
import MarketAlerts from "./MarketAlerts"; // Import MarketAlerts component
import { renderAlertEffectiveness } from '../functions/alertClauseService'; // Import renderAlertEffectiveness


// Redux action to update the 'seen' status
const updateSeenStatus = (id, seen) => {
  return {
    type: 'UPDATE_SEEN_STATUS',
    payload: { id, seen },
  };
};

const PF_Alerts = () => {
  const dispatch = useDispatch();
  const [isMarketAlertsVisible, setIsMarketAlertsVisible] = useState(false);
  const [selectedAlertSubtypeIdentifier, setSelectedAlertSubtypeIdentifier] = useState(null);

  
  const [isOpportunityCheckVisible, setIsOpportunityCheckVisible] = useState(false);
  const handleReinvestmentCheck = (ticker) => {
    setSelectedTicker(ticker); // Set the selected ticker
    setIsOpportunityCheckVisible(true); // Show the OpportunityCheck component
  };

  
  const harvestAlerts = useSelector(state => state?.portfolio?.data?.pf_alert);
  // console.log("alerts in PF_Alerts", harvestAlerts)
  const alertEffectiveness = useSelector(state => state?.portfolio?.data?.alert_effectiveness_data);
  // console.log("alert Effectiveness data in PF_Alerts", alertEffectiveness)

  // Sort by seen status first, then by alert_subtype and ticker in alphabetical order

  
  // console.log("Initial harvestAlerts:", harvestAlerts);

const sortedHarvestAlerts = Array.isArray(harvestAlerts)
  ? [...harvestAlerts].sort((a, b) => {
      const seenA = a.seen === null ? 0 : Number(a.seen);
      const seenB = b.seen === null ? 0 : Number(b.seen);

      // ✅ Log all unchecked alerts (including null values treated as 0)
      if (seenA === 0 || seenB === 0) {
        // console.log(`🔍 Comparing unchecked: ${a.ticker} (${a.alert_subtype}, seen: ${seenA}) vs ${b.ticker} (${b.alert_subtype}, seen: ${seenB})`);
      }

      // 1️⃣ Sort by `seen` first (unchecked on top)
      if (seenA !== seenB) {
        // console.log(`🔄 Sorting by seen: ${seenA} vs ${seenB}`);
        return seenA - seenB;
      }

      // 2️⃣ Prioritize 'combination' among only `seen === 0` alerts
      const isCombinationA = a.alert_subtype === 'combination' ? -1 : 0;
      const isCombinationB = b.alert_subtype === 'combination' ? -1 : 0;

      if (seenA === 0 && isCombinationA !== isCombinationB) {
        // console.log(`🚀 Moving 'combination' UP: ${a.ticker} (${a.alert_subtype}) vs ${b.ticker} (${b.alert_subtype})`);
        return isCombinationA - isCombinationB;
      }

      // 3️⃣ Final fallback: Alphabetical sorting
      const tickerComparison = a.ticker.localeCompare(b.ticker);
      if (tickerComparison !== 0) {
        // console.log(`🔠 Sorting by ticker: ${a.ticker} vs ${b.ticker}, result: ${tickerComparison}`);
      }
      return tickerComparison;
    })
  : [];



  const formatAlertText = (text) => {
    if (!text) return null;
  
    const becauseIndex = text.indexOf('because:');
    const formattedText = becauseIndex !== -1
      ? `${text.substring(0, becauseIndex + 8)}\n${text
          .substring(becauseIndex + 8)
          .replace(/ - /g, '\n- ')
          .replace(/\n+/g, '\n')
          .trim()}`
      : text;
  
    return formattedText.split('\n').map((line, index) => {
      if (line.startsWith('- (initiated') || line.startsWith('- (updated')) {
        // Metadata line
        return (
          <div
            key={index}
            style={{
              color: 'gray',
              fontStyle: 'italic',
              fontSize: '10px',
            }}
          >
            {line.replace(/^- /, '')}
          </div>
        );
      } else if (line.startsWith('-')) {
        // Bullet point
        return (
          <div key={index} className="bullet-container">
            {line.replace(/^- /, '')}
          </div>
        );
      }
      // Default line
      return <div key={index}>{line}</div>;
    });
  };
  

  const handleCheckboxChange = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus === 1 ? 0 : 1;
      await api.post('/pf_alert_check_update', { id, seen: newStatus });
      dispatch(updateSeenStatus(id, newStatus));
    } catch (error) {
      console.error('Failed to update seen status', error);
    }
  };

  const userId = getUserId();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);

  const handleRowClick = (ticker) => {
    setSelectedTicker(ticker);
    setIsModalVisible(true);
  };

  const handleOpenMarketAlerts = (alertSubtypeIdentifier) => {
    setSelectedAlertSubtypeIdentifier(alertSubtypeIdentifier);
    setIsMarketAlertsVisible(true);
  };

  const handleCloseMarketAlerts = () => {
    setIsMarketAlertsVisible(false);
    setSelectedAlertSubtypeIdentifier(null);
  };
  

  const columns = [
    {
      title: 'Company',
      key: 'company',
      width: 180,
      render: (text, record) => (
        <Tooltip title={record.company}>
          <div>
            {/* Company Name (Clickable) */}
            <div
              style={{ cursor: 'pointer', color: 'blue', fontWeight: 'bold' }}
              onClick={() => handleRowClick(record.ticker)}
            >
              {record.company.length > 20 ? `${record.company.substring(0, 20)}...` : record.company}
            </div>
    
            {/* Ticker inside a Tag */}
            <div style={{ marginTop: '4px' }}>
              <Tag color="blue">{record.ticker}</Tag>
            </div>
    
            {/* Additional Info */}
            <div style={{ fontSize: '12px', color: 'gray' }}>Share price:</div>
            <div style={{ fontSize: '12px', color: 'gray' }}>
              at alert initiation: {parseFloat(record.price_at_initiation).toFixed(2)}
            </div>
            <div style={{ fontSize: '12px', color: 'gray' }}>
              current: {parseFloat(record.price_current).toFixed(2)}
            </div>
    
            {/* Category Value */}
            <div style={{ fontSize: '12px', color: 'black', fontWeight: 'bold' }}>
              Category: {record.Category || 'N/A'}
            </div>
    
            {/* Warning Symbol if age_warning exists */}
            {record.age_warning && (
              <Tooltip title={record.age_warning}>
                <span
                  style={{
                    color: 'red',
                    fontSize: '16px',
                    marginLeft: '8px',
                    cursor: 'help',
                  }}
                >
                  ⚠️
                </span>
              </Tooltip>
            )}
          </div>
        </Tooltip>
      ),
    },
    
    
    
 
    {
      title: 'Alert Type',
      dataIndex: 'alert_type',
      key: 'alert_type',
      width: 40,
      render: (text, record) => (
        <div>
          <span>{text}</span>
          {text === 'Harvest' && (
            <Button
              size="small"
              type="primary"
              style={{ marginLeft: '10px' }}
              onClick={() => handleReinvestmentCheck(record.ticker)}
            >
              Re-investment check
            </Button>
          )}
        </div>
      ),
    },
    {
      title: 'Alert Subtype',
      dataIndex: 'alert_subtype',
      key: 'alert_subtype',
      width: 40,
      render: (text, record) => (
        <div>
          {userId === 2 ? (
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => handleOpenMarketAlerts(record.alert_subtype_identifier)}
            >
              {text}
            </Button>
          ) : (
            <span>{text}</span>
          )}
          <div style={{ fontSize: '12px', color: 'gray' }}>
            ({record.alert_subtype_identifier})
          </div>
        </div>
      ),
    },
    
    {
      title: 'Checked?',
      dataIndex: 'seen',
      key: 'seen',
      width: 40,
      render: (text, record) => (
        <Checkbox 
          checked={record.seen === 1} 
          onChange={() => handleCheckboxChange(record.ID, record.seen)}
        />
      ),
    },

    {
      title: 'Alert Text',
      dataIndex: 'pf_alert_text',
      key: 'pf_alert_text',
      width: 550,
      render: (text) => <div>{formatAlertText(text)}</div>,
    },
    
    
    
    {
      title: "Alert Effectiveness",
      key: "alert_effectiveness",
      width: 185,
      render: (text, record) => renderAlertEffectiveness(alertEffectiveness, record.alert_subtype_identifier),
    }
    
    
  ];

  return (
    <>
      <Table
  dataSource={sortedHarvestAlerts}
  columns={columns}
  rowKey="ID"
  pagination={{ pageSize: 5 }}
  scroll={{ x: '100%' }} // Enable horizontal scrolling if needed
  // tableLayout="fixed" // Ensure column widths are respected
/>

      {isModalVisible && (
        <ThreeTablesModal 
          tickerValue={selectedTicker} 
          open={isModalVisible} 
          onClose={() => setIsModalVisible(false)} 
          userId={userId} 
        />
      )}
      {isOpportunityCheckVisible && (
          <OpportunityCheck 
            ticker={selectedTicker} 
            onClose={() => setIsOpportunityCheckVisible(false)} 
          />
        )}
      {isMarketAlertsVisible && (
        <MarketAlerts
          isVisible={isMarketAlertsVisible}
          onClose={handleCloseMarketAlerts}
          alertSubtypeIdentifier={selectedAlertSubtypeIdentifier} // Pass the selected identifier(s)
        />
      )}
    </>
  );
};

export default PF_Alerts;

