// This component is a template for creating a personal widget that displays data from a user's personal database.
// It includes a button that opens a modal with a table displaying the user's personal data.
// The table is sortable and clickable, and each row can be clicked to open the URL in a new tab.

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import getUserId from '../functions/getUserId';
import api from '../functions/api';
import { message, Modal, Table as AntTable, Typography as AntTypography, Tag, Tooltip } from 'antd';

import '../css/WidgetAreaController.css';
import ThreeTablesModal from "./SearchResultModal";
import SimpleExternalLinkNewWindow from '../functions/LinkFlex';
import WidgetWidgetView from './WidgetWidgetView';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const { Title } = Typography;

const WidgetPersonal = ({ subtype, title }) => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [isThreeTablesModalVisible, setIsThreeTablesModalVisible] = useState(false);

  const widgetPreload = useSelector(state => state.widget?.widgetPreload) || [];
  
  // ✅ Extract alert tickers from Redux state
  const favoritesAlertData = useSelector(state => state.favorites?.data?.favorites_alert_data || []);
  const opportunitiesAlerts = useSelector(state => state.portfolio?.data?.opportunities_alerts || []);
  const pfDataAlerts = useSelector(state => state.portfolio?.data?.pf_alert || []);
  
    // ✅ Map tickers to alert details
  const alertDetailsMap = new Map([
      ...favoritesAlertData.map(alert => [alert.ticker?.toUpperCase(), `${alert.alert_type}:${alert.alert_subtype}`]),
      ...opportunitiesAlerts.map(alert => [alert.ticker?.toUpperCase(), `${alert.alert_type}:${alert.alert_subtype}`]),
      ...pfDataAlerts.map(alert => [
        (Array.isArray(alert) && alert.length > 2 && typeof alert[2] === 'string' ? alert[2].toUpperCase() : null),
        `${alert[4]}:${alert[5]}` // Adjusted for pf_alert format
      ])
    ]);


// Filter all records matching the current `subtype` and `userid`
const widgetData = widgetPreload.filter(wp => wp.subtype === subtype && wp.userid == getUserId());

const parsedData = widgetData.map(item => {
  try {
    return JSON.parse(item.data); // Convert each `data` field from string to object
  } catch (error) {
    console.error("Error parsing widget data:", error);
    return {}; // Return empty object if parsing fails
  }
});

// Get headers from the first record (assuming all records have the same structure)
const headersArray = widgetData.length > 0 ? Object.keys(JSON.parse(widgetData[0].headers_sequence)) : [];


  // useEffect(() => {
  //   loadData(); // Automatically fetch data when the component mounts
  // }, []); // Empty dependency array ensures it runs only once
  

  const loadData = () => {
    const userid = getUserId();
    setLoading(true);
    api.get('/widget_personal', {
      params: {
        subtype: subtype,
        userid: userid,
      }
    })
    .then((response) => {
      const standardizedData = response.data.data.map(item => {
        const newItem = {};
        Object.keys(item).forEach(key => {
          newItem[key.toLowerCase()] = item[key];
        });
        return newItem;
      });
      // console.log("DATA BEING LOADED FOR THIS WIDGET", response)
      setData(standardizedData);
      setHeaders(response.data.headers_sequence.filter(header => header.toLowerCase() !== 'url'));
      // console.log("headers", headers)
      setLoading(false);
    })
    .catch((error) => {
      message.error('Failed to fetch personal widget data');
      setLoading(false);
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
    loadData();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onRowClick = (record) => {
    const urlColumnKey = Object.keys(record).find(key => key.toLowerCase().includes('url'));
    if (urlColumnKey && record[urlColumnKey]) {
      window.open(record[urlColumnKey], '_blank');
    }
  };

  const generateAndSortColumns = (headers) => {
    const onTickerClick = (ticker) => {
      setSelectedTicker(ticker);
      setIsThreeTablesModalVisible(true);
    };

    const isUrlColumn = (header) => header.toLowerCase() === 'url';
    const dataIndexForHeader = (header) => header.toLowerCase();

    const renderColumnContent = (header, text, record) => {
      const dataIndex = dataIndexForHeader(header);
      const tickerValue = record[dataIndex];
      const alertInfo = typeof tickerValue === 'string' ? alertDetailsMap.get(tickerValue.toUpperCase()) : null;
    
      if (isUrlColumn(header)) {
        return <SimpleExternalLinkNewWindow clickedValue={text} url={record[dataIndex]} />;
      } else if (dataIndex === 'ticker') {
        return (
          <a onClick={() => onTickerClick(record[dataIndex])} style={{ cursor: 'pointer', color: '#1890ff', display: 'flex', alignItems: 'center' }}>
            {alertInfo && (
              <Tooltip title={alertInfo} arrow>
                <span style={{ width: 6, height: 6, backgroundColor: 'red', borderRadius: '50%', marginRight: 4, display: 'inline-block' }}></span>
              </Tooltip>
            )}
            <Tag color="blue">{text}</Tag>
          </a>
        );
      } else {
        return text;
      }
    };
    

    return headers.map(header => ({
      title: header,
      dataIndex: dataIndexForHeader(header),
      key: header,
      sorter: (a, b) => {
        if (typeof a[dataIndexForHeader(header)] === 'number' && typeof b[dataIndexForHeader(header)] === 'number') {
          return a[dataIndexForHeader(header)] - b[dataIndexForHeader(header)];
        }
        if (typeof a[dataIndexForHeader(header)] === 'string' && typeof b[dataIndexForHeader(header)] === 'string') {
          return a[dataIndexForHeader(header)].localeCompare(b[dataIndexForHeader(header)]);
        }
        return 0;
      },
      render: (text, record) => renderColumnContent(header, text, record),
    }));
  };

  const columns = generateAndSortColumns(headers);

  return (
    <div>
      <WidgetWidgetView 
  title={title}
  headers={headersArray}
  parsedData={parsedData}
  onClick={showModal}
  setSelectedTicker={setSelectedTicker}
/>




<Modal
  title={<AntTypography.Title level={3}>{title}</AntTypography.Title>}
  visible={isModalVisible}
  onCancel={handleCancel}
  footer={null}
  width={1200}
  destroyOnClose
>
  <AntTable // <-- Use Ant Design's table here
    dataSource={data}
    columns={columns}
    pagination={{ pageSize: 10 }}
    loading={loading}
    rowKey="id"
    bordered
    size="middle"
    onRow={(record) => ({
      onClick: () => onRowClick(record),
    })}
  />
</Modal>



      {isThreeTablesModalVisible && (
        <ThreeTablesModal
          userId={getUserId()}
          onClose={() => setIsThreeTablesModalVisible(false)}
          open={isThreeTablesModalVisible}
          tickerValue={selectedTicker}
        />
      )}
    </div>
  );
};

export default WidgetPersonal;
