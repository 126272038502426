/// FavoritesModal.jsx
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Tag, Modal, Table, Button, Checkbox, message, Tooltip, Tabs, Dropdown, Menu } from 'antd';
import api from '../functions/api';
import ThreeTablesModal from "./SearchResultModal";
import getUserId from '../functions/getUserId';
import ESG_MainTable from './ESG_MainTable';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { updateFavoritesAction } from '../redux/actions';
import{updateFavoritesAndBenchmarkAction} from '../redux/actions';
import { getFavoritesData } from '../redux/reducers/portfolioReducer'
import FavoritesAlerts from './FavoritesAlerts';
import { fetchFavoritesESGData } from '../functions/fetchFavorites_ESG_data';


  // Mapping state to props
  const mapStateToProps = state => ({
    favorites: state.favorites,
  });
    
const FavoritesModal = ({ favorites, favvisible, onClose, fixedColumns, favoritesData, tableColumns, userId, pdata}) => {
  // console.log("favoritesData: ", favoritesData);
  const bpdata = useSelector(state => state.favorites?.data?.benchmark_performance);
  const b_dataset_json = useSelector(state => state.portfolio?.parametersData?.b_dataset_json);
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const fieldMetadata = useSelector(state => state?.favorites?.data?.field_metadata);
  const [esgData, setEsgData] = useState(null);
  const dataparameters = useSelector(state => state.favorites.parametersData);
  const fetchedEsgData = useSelector((state) => state.favorites.esgData);
  // const allState = useSelector(state => state);
  // console.log("All State: ", allState);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isSaveDisabled, setSaveDisabled] = useState(true); // Initiate with Save button disabled
  const [alerts, setAlerts] = useState([]);
  
 
  useEffect(() => {
    if (favvisible) {
      // console.log("Dispatch in favorites");
      dispatch(getFavoritesData());
    }
  }, [dispatch, favvisible]);
  
  if (!isEqual(fetchedEsgData, esgData)) {
    setEsgData(fetchedEsgData);
      }

  useEffect(() => {
        if (favvisible && favoritesData?.length) {
          const tickers = favoritesData.map(item => item.Ticker).filter(Boolean); // Ensure we only pass valid tickers
      
          if (tickers.length > 0) {
            fetchFavoritesESGData(tickers).then((data) => {
              if (data) {
                setEsgData(data);
              }
            });
          }
        }
      }, [favvisible, favoritesData]);

  
  useEffect(() => {
    if (favoritesData) {
      const initialCheckedItems = favoritesData.map(() => true);
      setCheckedItems(initialCheckedItems);
    }
  }, [favoritesData]);

  const isTickerInAlerts = (ticker) => {
    return alerts.some((alert) => alert.ticker === ticker);
  };
  
  const updatedTableColumns = tableColumns.map(column => {
    if (['Ticker', 'Company'].includes(column.title)) {
      return {
        ...column,
        render: (text, record) => <span onClick={() => onRowClick(record)}>{text}</span>
      };
    }
    return column;
  });
  
  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      render: (_, record, index) => {
        if (record.Ticker && record.Company) {
          return (
            <Tooltip title={fieldMetadata && fieldMetadata['Favorite'] ? fieldMetadata['Favorite'].Tooltip : ''}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={checkedItems[index]}
                  onChange={(e) => handleCheckboxChange(e, index)}
                />
                {isTickerInAlerts(record.Ticker) && <span style={{ color: 'red', marginLeft: '5px' }}>●</span>}
              </div>
            </Tooltip>
          );
        }
        return null;
      },
    },
    ...updatedTableColumns,
  ];
  
  ////////////////////////////////
  //for the performance tab
  const getBenchmarkDataForStock = (ticker) => {
    if(!bpdata) {
      // console.error("bpdata is undefined");
      return null;
    }
    // console.log(`Searching for data for ${ticker} in`, bpdata);
    const result = bpdata.find((data) => data.favorite_ticker === ticker);
    // console.log("Result is:", result);
    return result;
  };
  
  const handleMenuClick = async (e, record) => {
    const selectedBenchmark = e.item.props.value;
    const userId = getUserId();
    const ticker = record.ticker;
    try {
      const response = await api.post(`/fav_benchmark_save/${userId}`, {
        Ticker: ticker,
        benchmark: selectedBenchmark.benchmark,
        b_ticker: selectedBenchmark.ticker
      });
      if (response.data.status === 'success') {
        // console.log('Successfully updated the benchmark.');
        updateBenchmarkForTicker(selectedBenchmark, ticker);
        updateBpData(selectedBenchmark, ticker); // New function to update bpdata
        // updateBenchmarkForTicker(selectedBenchmark.benchmark, ticker);
        setIsRefreshDisabled(true);
      } else {
        console.error('Failed to update the benchmark.');
      }
  
    } catch (error) {
      console.error('An error occurred while updating the benchmark:', error);
    }
  };

  const updateBenchmarkForTicker = (newBenchmark, ticker) => {
    const updatedFavorites = favorites.data.data.map((fav) => {
      if (fav.Ticker === ticker) {
        return { ...fav, benchmark: newBenchmark.benchmark };
      }
      return fav;
    });
    dispatch(getFavoritesData());
    setIsRefreshDisabled(true);
    // dispatch(updateFavoritesAndBenchmarkAction(updatedFavorites));
    // dispatch(updateFavoritesAction(updatedFavorites));
  };

  const updateBpData = (newBenchmark, ticker) => {
    const updatedBpData = bpdata.map((data) => {
      if (data.favorite_ticker === ticker) {
        return { ...data, benchmark: newBenchmark.benchmark };
      }
      return data;
    });
  };
  
  const performanceColumns = [
    {
      title: '',
      dataIndex: 'checkbox',
      render: (_, record, index) => {
          return (
            <Tooltip title={fieldMetadata && fieldMetadata['Favorite'] ? fieldMetadata['Favorite'].Tooltip : ''}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={checkedItems[index]}
                onChange={(e) => handleCheckboxChange(e, index)}
              />
              {isTickerInAlerts(record.Ticker) && <span style={{ color: 'red', marginLeft: '5px' }}>●</span>}
            </div>
          </Tooltip>
          );
        return null;
      },
    },
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
      render: (text, record) => {
        const benchmark = favorites.data.data.find(fav => fav.Ticker === record.ticker)?.benchmark;
        const menu = (
          <Menu onClick={(e) => handleMenuClick(e, record)}>
            {b_dataset_json.map((item, index) => (
              <Menu.Item key={index} value={{ benchmark: item.benchmark, ticker: item.ticker }}>
                {`${item.benchmark} (${item.ticker})`}
              </Menu.Item>
            ))}
          </Menu>
        );
        return (
          <>
          <Tag color="blue" onClick={() => onRowClick(record)} style={{ cursor: 'pointer' }}>
          {text}
        </Tag>
            {/* <span onClick={() => onRowClick(record)}>{text}</span> */}
            <br />
            <span style={{ fontSize: '8px' }}>Benchmark: </span>
            <Dropdown overlay={menu}>
              <span style={{ fontSize: '8px', cursor: 'pointer' }}>
                {benchmark !== null ? benchmark : 'Select'} {/* Change N/A to Select here */}
              </span>
            </Dropdown>
          </>
        );
      },
    }
    ,
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (text, record) => <span onClick={() => onRowClick(record)}>{text}</span>,
    },
    {
      title: 'YTD',
      dataIndex: 'YTD',
      key: 'YTD',
      render: (text, record) => {
        const benchmarkData = getBenchmarkDataForStock(record.ticker);
    
        // Check if benchmark data is available and valid
        if (benchmarkData && benchmarkData.YTD !== undefined) {
          const stockPerformance = parseFloat(text);
          const benchmarkPerformance = parseFloat(benchmarkData.YTD);
          const performanceDifference = stockPerformance - benchmarkPerformance;
    
          return (
            <>
              <div>{`${text}%`}</div>
              <div style={{ fontSize: '10px' }}>
                {`B: ${benchmarkData.YTD}%`}
              </div>
              <div style={{ fontSize: '10px', color: performanceDifference >= 0 ? 'green' : 'red' }}>
                {`Diff: ${performanceDifference.toFixed(2)}%`}
              </div>
            </>
          );
        } else {
          return <div>{`${text}%`}</div>;
        }
      },
    },
    
    {
      title: 'One Day',
      dataIndex: 'one_day_perf',
      key: 'one_day_perf',
      render: (text, record) => {
        const benchmarkData = getBenchmarkDataForStock(record.ticker);
    
        if (benchmarkData && benchmarkData.one_day_perf !== undefined) {
          const stockPerformance = parseFloat(text);
          const benchmarkPerformance = parseFloat(benchmarkData.one_day_perf);
          const performanceDifference = stockPerformance - benchmarkPerformance;
    
          return (
            <>
              <div>{`${text}%`}</div>
              <div style={{ fontSize: '8px' }}>
                {`B: ${benchmarkData.one_day_perf}%`}
              </div>
              <div style={{ fontSize: '8px', color: performanceDifference >= 0 ? 'green' : 'red' }}>
                {`Diff: ${performanceDifference.toFixed(2)}%`}
              </div>
            </>
          );
        } else {
          return <div>{`${text}%`}</div>;
        }
      },
    },
    
    {
      title: 'One Week',
      dataIndex: 'one_week_performance',
      key: 'one_week_performance',
      render: (text, record) => {
        const benchmarkData = getBenchmarkDataForStock(record.ticker);
    
        if (benchmarkData && benchmarkData.one_week_performance !== undefined) {
          const stockPerformance = parseFloat(text);
          const benchmarkPerformance = parseFloat(benchmarkData.one_week_performance);
          const performanceDifference = stockPerformance - benchmarkPerformance;
    
          return (
            <>
              <div>{`${text}%`}</div>
              <div style={{ fontSize: '8px' }}>
                {`B: ${benchmarkData.one_week_performance}%`}
              </div>
              <div style={{ fontSize: '8px', color: performanceDifference >= 0 ? 'green' : 'red' }}>
                {`Diff: ${performanceDifference.toFixed(2)}%`}
              </div>
            </>
          );
        } else {
          return <div>{`${text}%`}</div>;
        }
      },
    },
    
    {
      title: 'One Month',
      dataIndex: 'one_month_performance',
      key: 'one_month_performance',
      render: (text, record) => {
        const benchmarkData = getBenchmarkDataForStock(record.ticker);
    
        if (benchmarkData && benchmarkData.one_month_performance !== undefined) {
          const stockPerformance = parseFloat(text);
          const benchmarkPerformance = parseFloat(benchmarkData.one_month_performance);
          const performanceDifference = stockPerformance - benchmarkPerformance;
    
          return (
            <>
              <div>{`${text}%`}</div>
              <div style={{ fontSize: '8px' }}>
                {`B: ${benchmarkData.one_month_performance}%`}
              </div>
              <div style={{ fontSize: '8px', color: performanceDifference >= 0 ? 'green' : 'red' }}>
                {`Diff: ${performanceDifference.toFixed(2)}%`}
              </div>
            </>
          );
        } else {
          return <div>{`${text}%`}</div>;
        }
      },
    },
    
    {
      title: 'Six Months',
      dataIndex: 'six_month_performance',
      key: 'six_month_performance',
      render: (text, record) => {
        const benchmarkData = getBenchmarkDataForStock(record.ticker);
    
        if (benchmarkData && benchmarkData.six_month_performance !== undefined) {
          const stockPerformance = parseFloat(text);
          const benchmarkPerformance = parseFloat(benchmarkData.six_month_performance);
          const performanceDifference = stockPerformance - benchmarkPerformance;
    
          return (
            <>
              <div>{`${text}%`}</div>
              <div style={{ fontSize: '8px' }}>
                {`B: ${benchmarkData.six_month_performance}%`}
              </div>
              <div style={{ fontSize: '8px', color: performanceDifference >= 0 ? 'green' : 'red' }}>
                {`Diff: ${performanceDifference.toFixed(2)}%`}
              </div>
            </>
          );
        } else {
          return <div>{`${text}%`}</div>;
        }
      },
    },
    
    {
      title: 'data timestamp',
      dataIndex: 'epoch',
      key: 'epoch',
      render: (epoch) => new Date(epoch * 1000).toLocaleString()
    },
  ];

  //for threeTablesModal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const onRowClick = async (record) => {
    try {
      const tickerValue = record.Ticker || record.ticker;
      setSelectedTicker(tickerValue); // save the ticker of the clicked row
      setIsModalVisible(true);
    } catch (error) {
      console.error("Failed to fetch data", error);
    }}

    /////////////////////////////////////////
  const handleCheckboxChange = (e, index) => {
    e.stopPropagation();  // Stop event propagation here
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = e.target.checked;
    setCheckedItems(newCheckedItems);
    const isInitialCheckedState = favoritesData.map(() => true);
    setSaveDisabled(isEqual(newCheckedItems, isInitialCheckedState));
  };
  
  const handleSaveClick = async () => {
    try {
      const favoritesToUpdate = favoritesData.map((item, index) => ({
        ticker: item.Ticker,
        isFavorite: checkedItems[index],
      }));
      await api.post(`/change_favorites/${userId}`, favoritesToUpdate);
      message.success("Favorites saved");
    } catch (error) {
      message.error("Failed to save favorites");
    }
  };

  const token = localStorage.getItem('token');
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(true);  // Initialize to true if you want it disabled by default
  
  const handleRefresh = async () => {
    console.log("handleRefresh called");  // Before try block
    try {
      console.log("Before API call");  // Before the API call
      const newFavoritesData = await api.get(`/favorites_view/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      dispatch(updateFavoritesAction(newFavoritesData.data));
      dispatch(updateFavoritesAction(newFavoritesData.benchmark_performance));
      setIsRefreshDisabled(true);
    } catch (error) {
      console.error('An error occurred while refreshing:', error);
    }
  };
  
  return (
    <Modal
    open={favvisible}
    title={
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Favorites</span>
        <div>
          <Button key="refresh" disabled={isRefreshDisabled} onClick={handleRefresh}>Refresh</Button>,
          <Button type="primary" disabled={isSaveDisabled} onClick={handleSaveClick}>
            Save
          </Button>
        </div>
      </div>
    }
    onCancel={onClose}
    width="100%"
    footer={[
       <Button key="refresh" disabled={isRefreshDisabled} onClick={handleRefresh}>Refresh</Button>, 
       <Button key="back" onClick={onClose}>OK</Button>,
        <Button key="submit" type="primary" disabled={isSaveDisabled} onClick={handleSaveClick}>Save</Button>
        ]}
        >
          <Tabs>
            <TabPane tab="Custom" key="1">
            <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
            <Table 
                dataSource={favoritesData}
                columns={columns}
                rowKey="id"
              />
              </div>
            </TabPane>
            <TabPane tab="Performance" key="2">
            <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
              {pdata ? (
                <Table dataSource={pdata} columns={performanceColumns} rowKey="ticker" />
              ) : (
                "No performance data available."
              )}
              </div>
              </TabPane>
            <TabPane tab="ESG" key="3">
            {esgData && 
              <ESG_MainTable
                dataSet={esgData.data}
                metadata={esgData.metadata}
                dataparametersSet={dataparameters}
              />} 
            </TabPane>
            <TabPane tab="Alerts" key="4">
    <FavoritesAlerts />
  </TabPane>
          </Tabs>

      {isModalVisible && (
        <ThreeTablesModal 
          tickerValue={selectedTicker}
          open={isModalVisible} 
          onClose={() => setIsModalVisible(false)}
          userId={userId}
        />
      )}
    </Modal>
  );
};

export default connect(mapStateToProps)(FavoritesModal);

