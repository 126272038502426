import api from '../functions/api';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getDataParameters } from '../redux/reducers/portfolioReducer';
import ESG_MainTable from './ESG_MainTable';
import ESG_Input from './ESG_Input';
import DownloadPDF from './DownloadPDF';
import CurrentDateTime from '../functions/Timestamp';
import ESG_Details from './ESG_Details';
import ESG_ETFDetails from './ESG_ETFDetails'; // Import the new component
import { Shield as Icon } from "lucide-react";
import NavButton from "./ui/NavButton";
import Box from "@mui/material/Box";

function ESGCheck() {
  const dispatch = useDispatch();
  const dataparametersSet = useSelector(state => state.portfolio.parametersData);

  useEffect(() => {
    if (!dataparametersSet || dataparametersSet.length === 0) {
      dispatch(getDataParameters());
    }
  }, [dispatch, dataparametersSet]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false); // Separate state for details modal
  const [isETFDetailsVisible, setIsETFDetailsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSet, setDataSet] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [notFoundDataset, setNotFoundDataset] = useState([]);
  const [holdingsData, setHoldingsData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [inputType, setInputType] = useState(null); // Track the input type
  const [selectedETFData, setSelectedETFData] = useState({}); // State for selected ETF data
  const [etfEsgHighlights, setEtfEsgHighlights] = useState({}); // New state for ETF highlights
  
const [ETFsFoundInNotFoundDataset, setETFsFoundInNotFoundDataset] = useState([]); // Corrected to an array
const [UnknownThingsFoundInNotFoundDataset, setUnknownThingsFoundInNotFoundDataset] = useState([]); // Corrected to an array


const [canShowETFDetails, setCanShowETFDetails] = useState(false);


  const [showInputOptions, setShowInputOptions] = useState(true); // New state to track input visibility

   const showModal = () => {
    setIsModalVisible(true);
    setShowInputOptions(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setDataSet(null);
    setShowInputOptions(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDataSet(null);
    setShowInputOptions(false)
  };

  const handleDetailsClose = () => {
    setIsDetailsVisible(false);
    setShowInputOptions(true);
    setShowInputOptions(true);
  };

  const handleETFDetailsClose = () => {
    setIsETFDetailsVisible(false);
    setShowInputOptions(true);
    setShowInputOptions(true);
  };

  const handleFileDetailsClose = () => {
    setShowInputOptions(true);
  };

  const [urlData, setUrlData] = useState({});
  const latestUrl = useRef({});

  useEffect(() => {

    // console.log("Input type ", inputType)
    // console.log("Data set:", dataSet);

    if (inputType === 'stock' && dataSet && dataSet.length > 0) {
      setIsDetailsVisible(true); // Open details modal for single stock search
      setShowInputOptions(false);
    }
    // console.log("Input type:", inputType);
  // console.log("Data set:", dataSet);
  // console.log("ETFsFoundInNotFoundDataset:", ETFsFoundInNotFoundDataset);
  // console.log("UnknownThingsFoundInNotFoundDataset:", UnknownThingsFoundInNotFoundDataset);

    if (
      inputType === 'etf' &&
      ((dataSet && dataSet.length > 0) ||
       (ETFsFoundInNotFoundDataset && ETFsFoundInNotFoundDataset.length > 0) ||
       (UnknownThingsFoundInNotFoundDataset && UnknownThingsFoundInNotFoundDataset.length > 0))
    )
      {
      console.log("SETTING ETF DATA")
      setCanShowETFDetails(true);
      setIsETFDetailsVisible(true); // Open details modal for ETF search
      setShowInputOptions(false);
      setSelectedETFData({
        holdings: dataSet, // Assuming dataSet is the correct structure for holdings
        etfName: metadata,
        ticker: responseData.ticker || 'N/A',
        ETFs_found_in_not_found_dataset: responseData.ETFs_found_in_not_found_dataset, 
        UnknownThingsFound:responseData.Unknown_things_found_in_not_found_dataset
        // UnknownThingsFound:responseData.UnknownThingsFound
        

      });
    }

    else {
      setCanShowETFDetails(false); // Reset the state if conditions are not met
    }

    if (inputType === 'file' && dataSet && dataSet.length > 0) {
      setShowInputOptions(false); // Hide input options
    }

  }, [inputType, dataSet, metadata, responseData]);

  const fetchUrlAndSetState = async (ticker, datafield, callback) => {
    try {
      const response = await api.get('/external_link', { params: { ticker, field_name: datafield } });
      setUrlData(prevState => ({ ...prevState, [`${ticker}_${datafield}`]: response.data.url }));
      latestUrl.current[datafield] = response.data.url;
      if (callback) callback(response.data.url);
    } catch (error) {
      console.error('Error getting column URL:', error);
    }
  };

  const handleCellClick = (record, datafield) => {
    fetchUrlAndSetState(record.ticker, datafield, (url) => {
      window.open(url, '_blank');
    });
  };


  return (
    <>

      <NavButton
            icon={<Icon size={14} />}
            label={
              <Box lineHeight={0.9} textAlign="center">
            <div>ESG</div>
            <div>Check</div>
          </Box>
        }
            onClick={showModal}
            // disabled={disabled}
            tooltip="Retrieve ESG data by searching for a particular stock or uploading a file with a number of stocks, for instance your portfolio"
          />

      {/* <Tooltip
        title="Retrieve ESG data by searching for a particular stock or uploading a file with a number of stocks, for instance your portfolio."
        mouseEnterDelay={1.5}
        placement="bottom"
      >
        <Button type="primary" onClick={showModal}>
          ESG-Check
        </Button>
        
      </Tooltip> */}

     

      <Modal
        title={
          <div>
            ESG Check <CurrentDateTime />
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="90%"
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
            OK
          </Button>,
          inputType === 'file' && dataSet && dataSet.length > 0 && ( // Ensures the button is only shown when ESG_MainTable is rendered
            <DownloadPDF
              key="download"
              contentId="ESG_Maintable"
              filename="ESG data on stocks and ETF holdings found in uploaded file"
              // currentDateTime={CurrentDateTime}
            />
          ),
        ]}
        
      >
        <div id="modal-content">
          {showInputOptions ? (
            <ESG_Input
              isLoading={isLoading}
              setDataSet={setDataSet}
              setMetadata={setMetadata}
              setIsLoading={setIsLoading}
              setNotFoundDataset={setNotFoundDataset}
              setHoldingsData={setHoldingsData}
              setResponseData={setResponseData}
              setInputType={setInputType}
              setEtfEsgHighlights={setEtfEsgHighlights}
              setETFsFoundInNotFoundDataset={setETFsFoundInNotFoundDataset} // Pass the setter
              setUnknownThingsFoundInNotFoundDataset={setUnknownThingsFoundInNotFoundDataset} // Pass the setter
            />

          ) 
          
          : inputType === 'etf' && canShowETFDetails ? ( // Only check for inputType and canShowETFDetails
            <ESG_ETFDetails
              visible={isETFDetailsVisible}
              onClose={handleETFDetailsClose}
              etfData={selectedETFData || {}}
              etfEsgHighlights={etfEsgHighlights}
            />
          ) : inputType === 'stock' && dataSet && dataSet.length > 0 ? ( // Check for dataSet for stock
            <ESG_Details
              visible={isDetailsVisible}
              onClose={handleDetailsClose}
              ticker={dataSet[0]?.ticker || ''}
              details={dataSet[0] || {}}
            />
          ) : inputType === 'file' && dataSet && dataSet.length > 0 ? ( // Check for dataSet for file
            <ESG_MainTable
              dataSet={dataSet}
              metadata={metadata || []}
              handleCellClick={handleCellClick}
              latestUrl={latestUrl || []}
              urlData={urlData || []}
              dataparametersSet={dataparametersSet || []}
              notFoundDataset={notFoundDataset || []}
              holdingsData={holdingsData || []}
              responseData={responseData || []}
              etfEsgHighlights={etfEsgHighlights || []}
            />
          ) : null}
        </div>
      </Modal>
    </>
  );
}

export default ESGCheck;

