///Users.jsx
// function of this component is to handle user registration and login.

import React from 'react';
import { Modal, Form, Input, Button, Tabs, message } from 'antd';
import api from '../functions/api';
import { useDispatch } from 'react-redux';  
import { logIn, logOut } from '../redux/actions';  


const User = ({ setUserLoggedIn, defaultTab = "2", visible, setVisible, refetchUserDetails, setToken }) => {
  const dispatch = useDispatch(); 

  const token = localStorage.getItem('token');
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = React.useState(defaultTab); 
  const [loading, setLoading] = React.useState(false); // Add loading state for spinner

  const [formLogin] = Form.useForm();
  const [formRegister] = Form.useForm();

  React.useEffect(() => {
  if (visible) {
    formLogin.resetFields();
    formRegister.resetFields();
    setActiveTab(defaultTab);
  }
  }, [visible]);

  const handleCancel = () => {
    setVisible(false); // Notify the parent component to hide the modal
  };

  const handleRegisterSubmit = async (values) => {
    try {
      await api.post('/register', values); 
      setVisible(false);
    } catch (error) {
      if (error.response) {
          console.error('Error registering user:', error.response.data);
          alert(error.response.data.error); // Display the error message to the user
      } else if (error.request) {
          console.error('No response received:', error.request);
      } else {
          console.error('Error', error.message);
      }
    }
  };
  
  const handleLoginSubmit = async (values) => {
    setLoading(true);
  
    try {

      const response = await api.post('/login', values, {
        headers: {
          'Cache-Control': 'no-cache',
          'Authorization': '', // Ensure no stale token is sent
        },
        timeout: 10000, // Optional: fail fast if backend is slow
        auth: true, // Prevent interceptor from adding token
      });
      

      // const response = await api.post('/login', values);
  
      if (!response?.data?.access_token) {
        throw new Error("Invalid response from server");
      }
  
      const newToken = response.data.access_token;
      localStorage.setItem('token', newToken);
      setToken(newToken);
  
      try {
        const userDetails = await api.get('/user_check');
        const { ID, first_name, last_name } = userDetails.data;
        const formattedName = `${first_name} ${last_name}`;
        dispatch(logIn({ ID, userName: formattedName }));
      } catch (e) {
        console.error("Failed to fetch user details after login:", e);
      }
      
      setLoading(false);
      setVisible(false);
      
      // const userData = { ID: response.data.user_id };
      // dispatch(logIn(userData));
  
      // setTimeout(() => {
      //   setLoading(false);
      //   setVisible(false);
      // }, 500);
    } catch (error) {
      setLoading(false);
  
      if (error.code === 'ERR_NETWORK') {
        message.error("Backend not responding. Please wait and try again.", 1.5);
        return;
      }
      
  
      console.error('Login error:', error.response?.data || error.message);
  
      if (error.response?.status === 401) {
        message.error("Incorrect credentials. Please try again.", 1.5);
      } else if (error.response?.status === 500) {
        message.error("Server error. If you try again it will probably work.", 1.5);
      } else {
        message.error("An unexpected error occurred. Please refresh and try again.", 1.5);
      }
    }
  };
  
  
  React.useEffect(() => {
    setActiveTab(defaultTab);  // Update activeTab when defaultTab changes
  }, [token, visible, defaultTab]);

  return (
    <div>
      <Modal
        title="Register/Login"
        open={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <TabPane tab="Register" key="1">
           <Form form={formRegister} onFinish={handleRegisterSubmit}> 
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { type: 'email', message: 'The input is not valid E-mail!' },
                  { required: true, message: 'Please input your E-mail!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="userid"
                label="User ID"
                rules={[
                  { required: true, message: 'Please input your User ID!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                  { required: true, message: 'Please input your First Name!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                  { required: true, message: 'Please input your Last Name!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Register
                </Button>
              </Form.Item>
            </Form>
          </TabPane>

          <TabPane tab="Login" key="2">
           <Form form={formLogin} onFinish={handleLoginSubmit}>
              <Form.Item
                name="userid"
                label="User ID"
                rules={[
                  { required: true, message: 'Please input your User ID!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? '' : 'Login'}
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default User;
