//// actions.js in react
import jwt_decode from 'jwt-decode';
import { message } from 'antd';
import api from '../functions/api';

export const toggleDemoMode = (isDemo) => ({
  type: 'TOGGLE_DEMO_MODE',
  payload: isDemo
});

export const toggleDebugMode = (isDebug) => {
  // Store the state in local storage
  localStorage.setItem('isDebugMode', JSON.stringify(isDebug));
  return {
    type: 'TOGGLE_DEBUG_MODE',
    payload: isDebug
  };
};



export function logIn(payload) {
  // console.log('logIn action called with payload:', payload);
  return {
    type: 'LOGIN',
    payload,
  };
}

export function logOut() {
  // console.log('logOut action called with payload:');
  return {
    type: 'LOGOUT',
  };
}

export function startTokenExpiryCheck(dispatch, setShowExpiredMessage) {
  return setInterval(() => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      dispatch(logOut());
      return;
    }
    
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000; // Divide by 1000 to get time in seconds

    if (decoded.exp < currentTime) {
      dispatch(logOut());
      message.error('Your token has expired. Please log in to continue.');
      setShowExpiredMessage(true);
    } else if (decoded.exp - currentTime < 60) { // Check if token is about to expire within the next minute
      setShowExpiredMessage(false);
    }
  }, 60000); // Check every minute
}


export const clearUserData = () => {
  return {
    type: 'CLEAR_USER_DATA',
  };
};

export const clearPortfolioData = () => {
  return {
    type: 'CLEAR_PORTFOLIO_DATA',
  };
};

export const clearFavoritesData = () => {
  return {
    type: 'CLEAR_FAVORITES_DATA',
  };
};

export const updateFavoritesAction = (updatedFavorites) => ({
  type: 'UPDATE_FAVORITES',
  payload: updatedFavorites,
});

export const updateFavoritesAndBenchmarkAction = (updatedFavorites, updatedBenchmarkPerformance) => ({
  type: 'UPDATE_FAVORITES_AND_BENCHMARK',
  payload: { updatedFavorites, updatedBenchmarkPerformance },
});


export const updateWidgetContent = (userId, widgetNumber, newContentId) => {
  return {
      type: 'UPDATE_WIDGET_CONTENT',
      payload: { userId, widgetNumber, newContentId }
  };
};

export const setWidgetPreferences = (widgetPrefs, widgetPreload) => {
  return {
    type: 'SET_WIDGET_PREFERENCES',
    payload: { 
      widgetPreferences: widgetPrefs, 
      widgetPreload: widgetPreload 
    },
  };
};


// export const setWidgetPreferences = (widgetPrefs) => {
//   return {
//     type: 'SET_WIDGET_PREFERENCES',
//     payload: widgetPrefs,
//   };
// };