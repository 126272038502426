///ClauseEditor.jsx

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form, Input, Select, Button, Alert, message, Spin } from 'antd';
import { fetchTargetFieldValues, updateClause, testClause, addClause } from '../functions/alertClauseService';
import TextCrafter from './TextCrafter';
import AlertTestingResultsTable from './AlertTestingResultsTable';

const { Option } = Select;

const ClauseEditor = ({ visible, onClose, clause, dashboardStructure, refreshClauses }) => {
  const [editingClause, setEditingClause] = useState(clause);
  const [targetFieldValues, setTargetFieldValues] = useState([]);
  const [thresholdTargetFieldValues, setThresholdTargetFieldValues] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);  // Tracks if there are changes
  const operatorOptions = ['>', '<', '=', '>=', '<=', '!='];
  const [showWarning, setShowWarning] = useState(false);
  const [isTextCrafterVisible, setTextCrafterVisible] = useState(false); 
  const parametersData = useSelector((state) => state.portfolio?.parametersData?.DP_dataset_json || []);
  const [testResultMessage, setTestResultMessage] = useState(null);
  const [testResults, setTestResults] = useState([]); // Store test results for modal display
  const [isTestResultsModalVisible, setIsTestResultsModalVisible] = useState(false); // Control test results modal visibility
  const [isTesting, setIsTesting] = useState(false); // Add state for spinner


  console.log("dashboard structure in clause editor", dashboardStructure)
  const getThresholdOptions = () => {
    if (!parametersData || !Array.isArray(parametersData)) {
      console.log("parametersData is not available or is not an array.");
      return null;
    }
    
    if (editingClause.clause_field && editingClause.clause_field_sourcetable) {
      const matchedParam = parametersData.find(
        (param) =>
          param.SourceTable === editingClause.clause_field_sourcetable &&
          param.DataField === editingClause.clause_field
      );
  
      // console.log("Matched Param:", matchedParam); // Log to confirm match
      if (matchedParam && matchedParam.Values) {
        // Return options from matchedParam.Values, splitting by commas
        return matchedParam.Values.split(',').map((value) => value.trim());
      }
    }
    
    return null; // Return null if no matching parameter is found
  };
  
 


  const handleTestClause = async () => {
    setIsTesting(true); // Show spinner
    const isMandatoryFilled = editingClause?.clause_field && editingClause?.clause_operator && editingClause?.clause_txt;
    const isScenario1 = editingClause?.threshold;
    const isScenario2 = editingClause?.threshold_field && editingClause?.threshold_sourcetable;
  
    if (!isMandatoryFilled || (!isScenario1 && !isScenario2)) {
      message.warning("Form is not complete for testing. Please fill out required fields.");
      setIsTesting(false); // Hide spinner
      return;
    }
  
    try {
      const result = await testClause(editingClause);
      setTestResults(result); // Store the full test results for the modal
      setTestResultMessage(`${result.length} True`); // Set test result message to display number of true results
      message.info(`${result.length} True`); // Show message with number of true results
    } catch (error) {
      console.error('Error testing clause:', error);
      setTestResultMessage('Error testing clause.');
    } finally {
      setIsTesting(false); // Hide spinner
    }
  };
  

  // console.log("clause in ClauseEditor", clause)

  useEffect(() => {
    setEditingClause(clause);
    setHasChanges(false); // Reset changes when a new clause is loaded
  }, [clause]);

  useEffect(() => {
    if (editingClause?.clause_field_target && editingClause?.clause_field_sourcetable) {
      const loadTargetFieldValues = async () => {
        try {
          const values = await fetchTargetFieldValues(editingClause.clause_field_sourcetable, editingClause.clause_field_target);
          setTargetFieldValues(values);
        } catch (error) {
          console.error('Error fetching target field values:', error);
        }
      };
      loadTargetFieldValues();
    }
  }, [editingClause?.clause_field_target, editingClause?.clause_field_sourcetable]);

  useEffect(() => {
    if (editingClause?.threshold_field_target && editingClause?.threshold_sourcetable) {
      const loadThresholdTargetFieldValues = async () => {
        try {
          const values = await fetchTargetFieldValues(editingClause.threshold_sourcetable, editingClause.threshold_field_target);
          setThresholdTargetFieldValues(values);
        } catch (error) {
          console.error('Error fetching threshold target field values:', error);
        }
      };
      loadThresholdTargetFieldValues();
    }
  }, [editingClause?.threshold_field_target, editingClause?.threshold_sourcetable]);

  // Update handleFieldChange to trigger the warning condition
  const handleFieldChange = (field, value, option = {}) => {
    setEditingClause((prev) => ({ ...prev, [field]: value }));
    setHasChanges(true); // Mark as changed
  
    if (field === "clause_field_sourcetable") {
      const selectedTableFields = dashboardStructure[value];
  
      if (value === "Portfolio_View" || value === "Fundamental_data_trends_per_ticker" || value === "Portfolio_Exposure" || value === "ticker") {
      // if (value === "Portfolio_View") {
        // Autofill with 'ticker' for 'Portfolio_View'
        setEditingClause((prev) => ({
          ...prev,
          clause_field_target: "ticker",
          clause_field_target_value: "ticker",
        }));
      } else {
        const hasTickerField = selectedTableFields?.some((f) => f.name === "ticker");
        const endsWithData = value.toLowerCase().endsWith("_data");
  
        if (hasTickerField && endsWithData) {
          setEditingClause((prev) => ({
            ...prev,
            clause_field_target: "ticker",
            clause_field_target_value: "ticker",
          }));
        } else {
          setEditingClause((prev) => ({
            ...prev,
            clause_field_target: null,
            clause_field_target_value: null,
          }));
        }
      }
    }
  
    if (field === "threshold_field") {
      // Use the provided `option.table` to set the correct sourcetable
      setEditingClause((prev) => ({
        ...prev,
        threshold_field: value,
        threshold_sourcetable: option.table || null,
      }));
    }
  
    if (field === "threshold_sourcetable" && value === "Category_Userstatus") {
      setEditingClause((prev) => ({
        ...prev,
        threshold_field_target: "depending on userid/category",
        threshold_field_target_value: "depending on userid/category",
      }));
    }

    if (field === "threshold_sourcetable" && value === "Sector_Benchmarks") {
      setEditingClause((prev) => ({
        ...prev,
        threshold_field_target: "sector",
        threshold_field_target_value: "sector",
      }));
    }

    if (field === "threshold_sourcetable" && value === "Portfolio_View") {
      setEditingClause((prev) => ({
        ...prev,
        threshold_field_target: "ticker",
        threshold_field_target_value: "ticker",
      }));
    }

    if (field === "threshold_sourcetable" && value === "UserFavorites") {
      setEditingClause((prev) => ({
        ...prev,
        threshold_field_target: "Ticker",
        threshold_field_target_value: "Ticker",
      }));
    }

  
    if (field === "threshold" && value) {
      const { threshold_field, threshold_field_target, threshold_field_target_value } = editingClause;
      setShowWarning(!!(threshold_field || threshold_field_target || threshold_field_target_value));
    } else if (
      field === "threshold_field" ||
      field === "threshold_field_target" ||
      field === "threshold_field_target_value"
    ) {
      setShowWarning(!!editingClause.threshold);
    } else {
      setShowWarning(false);
    }
  };
  

  const [canTest, setCanTest] = useState(true);

useEffect(() => {
    setCanTest(isFormValid()); // Update testability whenever the form changes
}, [editingClause]);

  
const handleSaveClause = async () => {
  try {
      let savedClause;
      if (editingClause.ID) {
          // Update existing clause
          savedClause = await updateClause(editingClause.ID, editingClause);
          message.success("Clause updated successfully");
      } else {
          // Add new clause
          savedClause = await addClause(editingClause);
          message.success("New clause added successfully");
      }

      // Update the form with the fully returned saved clause
      setEditingClause(savedClause);

      setHasChanges(false); // Reset the hasChanges state
      refreshClauses(savedClause); // Refresh the clause list in the parent component
  } catch (error) {
      console.error('Error saving clause:', error);
      message.error("Failed to save the clause.");
  }
};

  



  const getFieldOptions = (table, excludeField = null) => {
    const options = [];
    if (dashboardStructure && table) {

      dashboardStructure[table]?.forEach(field => {
        if (field.name !== excludeField) {
          options.push({
            value: field.name,
            label: field.name,
          });
        }
      });
    }
    return options;
  };

  // Form validation: Ensure required fields are not empty
  const isFormValid = () => {
    const clauseRelatedFieldsPopulated = editingClause?.clause_field && editingClause?.clause_operator;
    
    // Scenario 1: If Threshold is chosen, skip other threshold-related fields
    const scenario1 = editingClause?.threshold && clauseRelatedFieldsPopulated;
  
    // Scenario 2: If Threshold Field and Sourcetable are filled but Threshold is empty
    const scenario2 = editingClause?.threshold_field && editingClause?.threshold_sourcetable && clauseRelatedFieldsPopulated;
  
    // Original Condition: All required fields are filled
    const allFieldsFilled = clauseRelatedFieldsPopulated &&
      editingClause?.threshold_field &&
      editingClause?.threshold_sourcetable &&
      editingClause?.threshold_field_target &&
      editingClause?.threshold_field_target_value;
  
    return scenario1 || scenario2 || allFieldsFilled;
  };
  

  return (
    <>
    <Modal
       title={editingClause?.ID ? `Edit Clause ${editingClause.ID}` : "Create New Clause"}
      //  title={`Edit Clause ${editingClause?.ID || ''}`}
      // width={1800}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,

<Button
key="test"
onClick={handleTestClause}
disabled={!canTest} // Use the new state for the Test button
>
{isTesting ? <Spin /> : 'Test'}
</Button>,

          <Button
          key="save"
          type="primary"
          onClick={handleSaveClause}
          disabled={!hasChanges || !isFormValid()}
          >
          {editingClause?.ID ? "Update" : "Save"}
          </Button>
      ]}
      width={1000}
    >

{/* Display the link with test results count if available */}
{testResultMessage && (
          <div style={{ marginBottom: '16px' }}>
            <a onClick={() => setIsTestResultsModalVisible(true)}>{testResultMessage}</a>
          </div>
        )}


      <Form layout="vertical">
        {/* // Dropdown option for Clause Field */}

        <Form.Item label="Clause Field" required>
            <Select
              showSearch
              value={editingClause.clause_field && editingClause.clause_field_sourcetable
                ? `${editingClause.clause_field} (${editingClause.clause_field_sourcetable})`
                : undefined}
              onChange={(value) => {
                const [field, table] = value.split("|");
                handleFieldChange("clause_field", field);
                handleFieldChange("clause_field_sourcetable", table);
              }}
            >
              {dashboardStructure && Object.keys(dashboardStructure).map((table) =>
                dashboardStructure[table].map((field) => (
                  <Option key={`${field.name}-${table}`} value={`${field.name}|${table}`}>
                    {`${field.name} (${table})`}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>


        <Form.Item label="Clause Field Sourcetable">
          <Input value={editingClause.clause_field_sourcetable} disabled />
        </Form.Item>

        <Form.Item label="Clause Field Target">
          <Select
            showSearch
            value={editingClause.clause_field_target}
            onChange={value => handleFieldChange('clause_field_target', value)}
          >
            {dashboardStructure && getFieldOptions(editingClause.clause_field_sourcetable, editingClause.clause_field).map((option) => (
            // {getFieldOptions(editingClause.clause_field_sourcetable, editingClause.clause_field).map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Clause Field Target Value">
          <Select
            showSearch
            value={editingClause.clause_field_target_value}
            onChange={value => handleFieldChange('clause_field_target_value', value)}
          >
            {targetFieldValues.map(value => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Operator" required>
          <Select
            value={editingClause.clause_operator}
            onChange={value => handleFieldChange('clause_operator', value)}
          >
            {operatorOptions.map(op => (
              <Option key={op} value={op}>
                {op}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Threshold">
  {getThresholdOptions() ? (
    <Select
      showSearch
      allowClear // Enables the 'x' to clear selection
      value={editingClause.threshold}
      onChange={(value) => handleFieldChange('threshold', value)}
      placeholder="Select or clear threshold value"
    >
      {getThresholdOptions().map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  ) : (
    <Input
      allowClear // Enables the 'x' to clear input
      value={editingClause.threshold}
      onChange={(e) => handleFieldChange('threshold', e.target.value)}
      placeholder="Enter or clear threshold value"
    />
  )}
</Form.Item>



<Form.Item label="Threshold Field" required>
  <Select
    showSearch
    value={editingClause.threshold_field && editingClause.threshold_sourcetable 
      ? `${editingClause.threshold_field}|${editingClause.threshold_sourcetable}` 
      : undefined}
    onChange={(value) => {
      if (value) { // Check if value is not undefined
        const [field, table] = value.split("|");
        handleFieldChange("threshold_field", field);
        handleFieldChange("threshold_sourcetable", table);
      } else {
        // Clear the fields when value is undefined
        handleFieldChange("threshold_field", null);
        handleFieldChange("threshold_sourcetable", null);
      }
    }}
    placeholder="Select or clear field"
    allowClear
  >
    {dashboardStructure && Object.keys(dashboardStructure).map((table) =>
      dashboardStructure[table].map(field => (
        <Option key={`${field.name}-${table}`} value={`${field.name}|${table}`}>
          {`${field.name} (${table})`}
        </Option>
      ))
    )}
  </Select>
</Form.Item>




        <Form.Item label="Threshold Sourcetable">
          <Input value={editingClause.threshold_sourcetable} disabled />
        </Form.Item>

        <Form.Item label="Threshold Field Target">
          <Select
            showSearch
            value={editingClause.threshold_field_target}
            onChange={value => handleFieldChange('threshold_field_target', value)}
          >
            {getFieldOptions(editingClause.threshold_sourcetable, editingClause.threshold_field).map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Threshold Field Target Value">
          <Select
            showSearch
            value={editingClause.threshold_field_target_value}
            onChange={value => handleFieldChange('threshold_field_target_value', value)}
          >
            {thresholdTargetFieldValues.map(value => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>  

        <Form.Item label="Clause Text">
            <div style={{ border: '1px solid #d9d9d9', padding: '8px', borderRadius: '4px', maxHeight: '100px', overflowY: 'auto', wordWrap: 'break-word' }}>
              <Button
                style={{ whiteSpace: 'normal', textAlign: 'left', padding: '4px' }} // Adjust button styling to wrap text within the box
                onClick={() => {
                  console.log("Opening TextCrafter modal...");
                  setTextCrafterVisible(true);
                }}
              >
                Edit Clause Text: {editingClause?.clause_txt}
              </Button>
            </div>
          </Form.Item>

      </Form>

      {testResultMessage && (
          <div style={{ margin: '16px 0', padding: '8px', border: '1px solid #d9d9d9', borderRadius: '4px', backgroundColor: '#f6f6f6' }}>
            {testResultMessage}
          </div>
        )}
      {showWarning && (
          <Alert message="If Threshold has a value, the clause will use that as the threshold value!" type="warning" showIcon />
        )}

    </Modal>
    
    {/* Put the TextCrafter modal here */}
    {isTextCrafterVisible && (
        <TextCrafter
          dashboardStructure={dashboardStructure}
          initialText={editingClause?.clause_txt} // Pass clause text as initialText
          onSave={(updatedText) => {
            console.log("Updated Text:", updatedText);
            // Update the editingClause with the new text
            setEditingClause(prev => ({ ...prev, clause_txt: updatedText }));
            setHasChanges(true); // Mark that changes have been made
            setTextCrafterVisible(false); // Close modal
          }}
          onClose={() => {
            // console.log("Closing TextCrafter modal...");
            setTextCrafterVisible(false);
          }}
        />
      )}

      {/* AlertTestingResultsTable modal */}
      <AlertTestingResultsTable
        visible={isTestResultsModalVisible}
        onClose={() => setIsTestResultsModalVisible(false)}
        testResults={testResults}
        columnNames={['Clause text', 'Ticker', 'User']}
      />

      </>
        
      );
  
};

export default ClauseEditor;
