import React, { useState, useEffect } from 'react';
import { Table, Tooltip, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import api from '../functions/api';
import getUserId from '../functions/getUserId';

const Market_Alerts = () => {
  const dispatch = useDispatch();
  // const [forceRender, setForceRender] = useState(false); // State to force re-render

  const marketAlerts = useSelector(state => {
    // console.log("🔄 Redux Market Alerts Updated:", state?.portfolio?.data?.market_alerts);
    return state?.portfolio?.data?.market_alerts || [];
  });
  

  const userId = getUserId();

  // useEffect(() => {
  //   // console.log("🟢 Market Alerts Updated:", marketAlerts);
  //   setForceRender(prev => !prev); // Force re-render when marketAlerts changes
  // }, [marketAlerts]);

  const formatAlertText = (text) => {
    if (!text) return '';
    const becauseIndex = text.indexOf('because:');
    if (becauseIndex === -1) return text;
    const beforeBecause = text.substring(0, becauseIndex + 8);
    const afterBecause = text.substring(becauseIndex + 8).replace(/ - /g, '\n- ');
    return `${beforeBecause}${afterBecause}`;
  };

  const isCheckedByUser = (seenby) => {
    if (!seenby) return false;
    const seenByUsers = seenby.split(',').map(id => id.trim());
    return seenByUsers.includes(userId.toString());
  };

  const handleCheckboxChange = async (id, currentSeenby) => {
    try {
      // console.log("🔵 Checkbox clicked for ID:", id, "Current seenby:", currentSeenby);
  
      const seenByUsers = currentSeenby ? currentSeenby.split(',').map(id => id.trim()) : [];
      const isAlreadyChecked = seenByUsers.includes(userId.toString());
  
      // Toggle the user in the seenby list
      const updatedSeenByUsers = isAlreadyChecked 
        ? seenByUsers.filter(user => user !== userId.toString()) // Remove user
        : [...seenByUsers, userId.toString()]; // Add user
  
      const newSeenbyValue = updatedSeenByUsers.join(', '); // Join as a comma-separated string
  
      // 🔥 Send update to the backend
      const response = await api.post('/pf_alert_check_update', { id, seenby: newSeenbyValue });
  
      // console.log("✅ API Response:", response.data); // Ensure the API returns correct data
  
      // 🔥 Dispatch Redux action **after** successful API response
      // console.log("🟢 Dispatching UPDATE_SEENBY_STATUS for ID:", id);
      dispatch({
        type: 'UPDATE_SEENBY_STATUS',
        payload: { id, seenby: newSeenbyValue },
      });
  
    } catch (error) {
      console.error('❌ Failed to update seenby status', error);
    }
  };
  

  const columns = [
    {
      title: 'Alert Type',
      dataIndex: 'alert_type',
      key: 'alert_type',
    },
    {
      title: 'Alert Subtype',
      dataIndex: 'alert_subtype',
      key: 'alert_subtype',
    },
    {
      title: 'Checked?',
      dataIndex: 'seenby',
      key: 'seenby',
      render: (text, record) => (
        <Checkbox
          checked={isCheckedByUser(record.seenby)}
          onChange={() => handleCheckboxChange(record.ID, record.seenby)}
        />
      ),
    },
    {
      title: 'Alert Text',
      dataIndex: 'pf_alert_text',
      key: 'pf_alert_text',
      render: (text) => (
        <Tooltip title={text}>
          <span style={{ whiteSpace: 'pre-wrap' }}>{formatAlertText(text)}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={marketAlerts}
        columns={columns}
        rowKey="ID"
        pagination={{ pageSize: 5 }}
      />
    </>
  );
};

export default Market_Alerts;