
//ETF_screener.jsx

import React, { useState, useEffect } from 'react';
import { Modal, Button, AutoComplete, Select, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEtfData, clearEtfData } from '../redux/actions/etfActions';
import ETF_searchresult from './ETF_searchresult';
import { ScanSearch as Icon } from "lucide-react";
import NavButton from "./ui/NavButton";
import Box from "@mui/material/Box";

const { Option } = Select;

const ETF_screener = ({ disabled }) => {
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [etfQuery, setEtfQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedEtfSymbol, setSelectedEtfSymbol] = useState('');
  const [selectedEtfName, setSelectedEtfName] = useState('');
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [selectedIssuer, setSelectedIssuer] = useState('');
  const [showTable, setShowTable] = useState(false);

  const dispatch = useDispatch();
  const etfData = useSelector((state) => state.etf.etfData) || [];
  const etfError = useSelector((state) => state.etf.error);

  const [categoryCount, setCategoryCount] = useState(etfData.length);
  const [currencyCount, setCurrencyCount] = useState(etfData.length);
  const [issuerCount, setIssuerCount] = useState(etfData.length);
  const [filteredEtfCount, setFilteredEtfCount] = useState(0);


  const rawDistinctCategories = useSelector((state) => state.etf.distinctCategories);
  const rawDistinctCurrencies = useSelector((state) => state.etf.distinctCurrencies);
  const rawDistinctIssuers = useSelector((state) => state.etf.distinctIssuers);

  // console.log('rawDistinctCategories:', rawDistinctCategories);
  // console.log('rawDistinctCurrencies:', rawDistinctCurrencies);
  // console.log('rawDistinctIssuers:', rawDistinctIssuers);

  const distinctCategories = Array.isArray(rawDistinctCategories) ? [...rawDistinctCategories].sort() : [];
  const distinctCurrencies = Array.isArray(rawDistinctCurrencies) ? [...rawDistinctCurrencies].sort() : [];
  const distinctIssuers = Array.isArray(rawDistinctIssuers) ? [...rawDistinctIssuers].sort() : [];

  const getShowAllSuggestionsButtonTitle = () => {
    return `Show All ${suggestions.length} Suggestions in Table`;
  };

  useEffect(() => {
    if (etfData.length > 0) {
      setCategoryCount(etfData.length);
      setCurrencyCount(etfData.length);
      setIssuerCount(etfData.length);
    }
  }, [etfData]);

  useEffect(() => {
    const count = etfData.filter(etf => 
      (!selectedCategory || etf.category === selectedCategory) &&
      (!selectedCurrency || etf.currency === selectedCurrency) &&
      (!selectedIssuer || etf.issuer === selectedIssuer)
    ).length;
    setFilteredEtfCount(count);
    // console.log(`Filtered ETF Count: ${count}`);
  }, [etfData, selectedCategory, selectedCurrency, selectedIssuer]);

  const filteredEtfs = etfData.filter(etf => 
    (!selectedCategory || etf.category === selectedCategory) &&
    (!selectedCurrency || etf.currency === selectedCurrency) &&
    (!selectedIssuer || etf.issuer === selectedIssuer)
  );

  const [tableDataSource, setTableDataSource] = useState(filteredEtfs);

  const columns = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Return YTD',
      dataIndex: 'return_ytd',
      key: 'return_ytd',
      render: (value) => {
        if (value !== null && !isNaN(value)) {
          return `${parseFloat(value).toFixed(2)}%`;
        }
        return 'N/A';
      },
      sorter: (a, b) => a.return_ytd - b.return_ytd,
    },
    {
      title: 'Avg Return 3yr',
      dataIndex: 'avg_return_3yr',
      key: 'avg_return_3yr',
      render: (value) => {
        if (value !== null && !isNaN(value)) {
          return `${parseFloat(value).toFixed(2)}%`;
        }
        return 'N/A';
      },
      sorter: (a, b) => a.avg_return_3yr - b.avg_return_3yr,
    },
    {
      title: 'Avg Return 5yr',
      dataIndex: 'avg_return_5yr',
      key: 'avg_return_5yr',
      render: (value) => {
        if (value !== null && !isNaN(value)) {
          return `${parseFloat(value).toFixed(2)}%`;
        }
        return 'N/A';
      },
      sorter: (a, b) => a.avg_return_5yr - b.avg_return_5yr,
    },
  ];

  const handleRowClick = (record) => {
    handleSelect(`${record.symbol} - ${record.name}`);
  };

  const showSearchModal = () => {
    setSearchModalVisible(true);
    dispatch(fetchEtfData());
  };

  const handleSearchModalOk = () => {
    setSearchModalVisible(false);
    setEtfQuery('');
    setSuggestions([]);
    setSelectedCategory('');
    setSelectedCurrency('');
    setSelectedIssuer('');
    dispatch(clearEtfData());
  };

  const handleSearchModalCancel = () => {
    setSearchModalVisible(false);
    setEtfQuery('');
    setSuggestions([]);
    setSelectedCategory('');
    setSelectedCurrency('');
    setSelectedIssuer('');
    dispatch(clearEtfData());
  };

  const handleSearch = (value) => {
    setEtfQuery(value);
    if (etfData && etfData.length > 0) {
      let filteredSuggestions = etfData.filter(etf =>
        etf.name.toLowerCase().includes(value.toLowerCase()) || etf.symbol.toLowerCase().includes(value.toLowerCase())
      );
  
      if (selectedCategory) {
        filteredSuggestions = filteredSuggestions.filter(etf => etf.category === selectedCategory);
      }
  
      if (selectedCurrency) {
        filteredSuggestions = filteredSuggestions.filter(etf => etf.currency === selectedCurrency);
      }
  
      if (selectedIssuer) {
        filteredSuggestions = filteredSuggestions.filter(etf => etf.issuer === selectedIssuer);
      }
  
      setSuggestions(filteredSuggestions);
    }
  };

  const handleSelect = (value) => {
    const [etfSymbol, etfName] = value.split(' - ');
    setSelectedEtfSymbol(etfSymbol);
    setSelectedEtfName(etfName);
    setResultModalVisible(true);
  };

  const handleResultModalClose = () => {
    setResultModalVisible(false);
    setSearchModalVisible(true);
  };

  const getFilteredCount = (filterType, filterValue) => {
    console.log(`Filter Type: ${filterType}, Filter Value: ${filterValue}`);
    if (!filterValue) return etfData.length;
    const count = etfData.filter(etf => etf[filterType] === filterValue).length;
    console.log(`Count for ${filterType} = ${filterValue}: ${count}`);
    return count;
  };

  const handleShowFilteredEtfs = () => {
    const updatedFilteredEtfs = etfData.filter(etf => 
      (!selectedCategory || etf.category === selectedCategory) &&
      (!selectedCurrency || etf.currency === selectedCurrency) &&
      (!selectedIssuer || etf.issuer === selectedIssuer)
    );
    setTableDataSource(updatedFilteredEtfs);
    setShowTable(true);
  };

  const handleShowAllSuggestions = () => {
    if (suggestions.length > 0) {
      console.log("Suggestions?", suggestions)
      setTableDataSource(suggestions);
      setShowTable(true);
    }
  };

  const handleHideTable = () => {
    setShowTable(false);
  };

  return (
    <>
      <NavButton
      icon={<Icon size={14} />}
      label={
        <Box lineHeight={0.9} textAlign="center">
      <div>ETF</div>
      <div>Screener</div>
    </Box>
  }
      
      onClick={showSearchModal}
      disabled={disabled}
      tooltip="Search ETFs on holdings, performance and overlap with your current exposure"
    />

      {/* <Button type="primary" onClick={showSearchModal} disabled={disabled}>
        ETF Screener
      </Button> */}
      <Modal
        title="ETF Screener"
        visible={searchModalVisible}
        onOk={handleSearchModalOk}
        onCancel={handleSearchModalCancel}
        width={1000} // Broaden the modal
      >

        <Button
          type="primary"
          onClick={showTable ? handleHideTable : handleShowAllSuggestions}
          style={{ marginLeft: '0px', marginBottom: '10px' }}
          disabled={suggestions.length === 0 || suggestions.length > 100} // Updated condition
        >
          {showTable ? 'Hide Table with Suggestions' : `Show All ${suggestions.length} Suggestions in Table`}
        </Button>

        <AutoComplete
          style={{ width: '100%' }}
          options={suggestions.map(suggestion => ({ value: `${suggestion.symbol} - ${suggestion.name}` }))}
          onSearch={handleSearch}
          onSelect={handleSelect}
          placeholder="ETF symbol or name"
          value={etfQuery}
          onChange={(value) => setEtfQuery(value)}
          allowClear
        />
        {etfError && <p style={{ color: 'red' }}>{etfError}</p>}

        {/* Filters */}
        <div style={{ marginTop: '20px' }}>
          <h3>Apply Filters ({filteredEtfCount} ETFs comply with your current filters)</h3>
          <div style={{ marginBottom: '10px' }}>
            <label>Category ({categoryCount})</label>
            <Select
                  style={{ width: '100%' }}
                  placeholder="Select Category"
                  value={selectedCategory}
                  onChange={(value) => {
                    console.log(`Selected Category: ${value}`);
                    setSelectedCategory(value);
                    const count = getFilteredCount('category', value);
                    console.log("Count of category ETF: ", count);
                    setCategoryCount(count);

                    // Clear suggestions, search input, and hide table
                    setEtfQuery('');
                    setSuggestions([]);
                    setShowTable(false);
                  }}
                  allowClear
                >
                  {distinctCategories.map(category => (
                    <Option key={category} value={category}>{category}</Option>
                  ))}
                </Select>


          </div>
          <div style={{ marginBottom: '10px' }}>
            <label>Currency ({currencyCount})</label>
            <Select
                  style={{ width: '100%' }}
                  placeholder="Select Currency"
                  value={selectedCurrency}
                  onChange={(value) => {
                    console.log(`Selected Currency: ${value}`);
                    setSelectedCurrency(value);
                    const count = getFilteredCount('currency', value);
                    console.log("Count of currency ETF: ", count);
                    setCurrencyCount(count);

                    // Clear suggestions, search input, and hide table
                    setEtfQuery('');
                    setSuggestions([]);
                    setShowTable(false);
                  }}
                  allowClear
                >
                  {distinctCurrencies.map(currency => (
                    <Option key={currency} value={currency}>{currency}</Option>
                  ))}
                </Select>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label>Issuer ({issuerCount})</label>
            <Select
                  style={{ width: '100%' }}
                  placeholder="Select Issuer"
                  value={selectedIssuer}
                  onChange={(value) => {
                    console.log(`Selected Issuer: ${value}`);
                    setSelectedIssuer(value);
                    const count = getFilteredCount('issuer', value);
                    console.log("Count of issuer ETF: ", count);
                    setIssuerCount(count);

                    // Clear suggestions, search input, and hide table
                    setEtfQuery('');
                    setSuggestions([]);
                    setShowTable(false);
                  }}
                  allowClear
                >
                  {distinctIssuers.map(issuer => (
                    <Option key={issuer} value={issuer}>{issuer}</Option>
                  ))}
                </Select>
          </div>
        </div>

        <Button
  type="primary"
  onClick={handleShowFilteredEtfs}
  style={{ marginTop: '20px', marginBottom: '10px', marginRight: '10px' }}
  disabled={filteredEtfCount >= 500}
>
  {showTable ? 'Hide' : 'Show Filter result in Table'}
</Button>

{showTable && (
  <Button
    type="default"
    onClick={() => {
      setTableDataSource([]);
      setShowTable(false);
    }}
    style={{ marginTop: '20px', marginBottom: '10px' }}
  >
    Clear
  </Button>
)}

{showTable && (
  <Table
    dataSource={tableDataSource}
    columns={columns}
    pagination={{ pageSize: 10 }}
    rowKey="symbol"
    style={{ marginTop: '20px' }}
    onRow={(record) => {
      return {
        onClick: () => {
          handleSelect(`${record.symbol} - ${record.name}`);
        },
      };
    }}
  />
)}


      </Modal>

      {selectedEtfSymbol && (
        <ETF_searchresult
          visible={resultModalVisible}
          onClose={handleResultModalClose}
          etfSymbol={selectedEtfSymbol}
          etfName={selectedEtfName}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      )}
    </>
  );
};

export default ETF_screener;

