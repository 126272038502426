import React, { useState, useEffect } from 'react';
import { Table, Collapse, AutoComplete, Tag } from 'antd';
import { useSelector } from 'react-redux';
import ThreeTablesModal from "./SearchResultModal";
import getUserId from '../functions/getUserId';

const { Panel } = Collapse;

const Portfolio_Performance = () => {
  const portfolioData = useSelector(state => state.portfolio.data.portfolio);
  // console.log("portfolioData", portfolioData)
  const portfolioRelativePerformanceData = useSelector(state => state.portfolio.data.portfolio_relative_performance_data);
  // console.log("portfolioRelativePerformanceData", portfolioRelativePerformanceData)
  const userId = getUserId();
  const [sortedData, setSortedData] = useState(portfolioData);
  const [sortDirection, setSortDirection] = useState('desc'); // Initial sort direction

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState(portfolioData);

  const getColorStyle = (value) => {
    if (value === 'n/a' || value === null || value === undefined) return {};
    return { color: parseFloat(value) >= 0 ? 'green' : 'red' };
  };

  const handleSort = (key) => {
    const sorted = [...filteredData].sort((a, b) => {
      const valA = parseFloat(a[key]) || 0;
      const valB = parseFloat(b[key]) || 0;

      if (sortDirection === 'asc') {
        return valA - valB;
      } else {
        return valB - valA;
      }
    });

    // Toggle sort direction
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setFilteredData(sorted);
  };

  const onRowClick = (record) => {
    setSelectedTicker(record.Ticker);
    setIsModalVisible(true);
  };

  const getPortfolioDetails = (ticker) => {
    const tickerData = portfolioRelativePerformanceData.filter(data => data.Ticker === ticker);
    if (tickerData.length === 0) return { date: 'n/a', days: 'n/a' };

    const oldestRecord = tickerData.reduce((oldest, current) => {
      return current.Block_Age > oldest.Block_Age ? current : oldest;
    });

    return { date: oldestRecord.Block_Date, days: oldestRecord.Block_Age };
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const filteredRecords = portfolioData.filter(record =>
      (record.Ticker || '').toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredRecords);
  };

  const handleSelect = (value) => {
    const filteredRecords = portfolioData.filter(record =>
      (record.Ticker || '').toLowerCase() === value.toLowerCase()
    );
    setFilteredData(filteredRecords);
  };

  const columns = [
    {
      title: 'Ticker',
      dataIndex: 'Ticker',
      key: 'Ticker',
      render: (text, record) => (
        <div style={{ marginTop: '2px' }}>
          <Tag color="blue" onClick={() => onRowClick(record)} style={{ cursor: 'pointer' }}>
            {text}
          </Tag>
        </div>
      ),
    },
    {
      title: 'Company',
      dataIndex: 'Company',
      key: 'Company',
      render: (text, record) => {
        const { date, days } = getPortfolioDetails(record.Ticker);
        return (
          <div>
            <a onClick={() => onRowClick(record)}>
              {text}
            </a>
            <div style={{ fontSize: 'small', color: 'gray' }}>
              In portfolio since {date}, for {days} days
            </div>
          </div>
        );
      },
    },
    {
      title: 'Performance',
      key: 'Performance',
      render: (text, record) => (
        <div style={{ lineHeight: 1.2 }}>
          <p>
            <a onClick={() => handleSort('Overall_Relative_Performance')} style={getColorStyle(record.Overall_Relative_Performance)}>
              Overall Relative Performance: {record.Overall_Relative_Performance !== null && record.Overall_Relative_Performance !== undefined ? `${record.Overall_Relative_Performance}%` : 'n/a'}
            </a>
          </p>
          <p>
            <a onClick={() => handleSort('performance_since_last_tx')} style={getColorStyle(record.performance_since_last_tx)}>
              Performance Since Last Tx: {record.performance_since_last_tx !== null && record.performance_since_last_tx !== undefined ? `${record.performance_since_last_tx}%` : 'n/a'}
            </a>
          </p>
          <p>
            <a onClick={() => handleSort('performance_ytd')} style={getColorStyle(record.performance_ytd)}>
              Performance YTD: {record.performance_ytd !== null && record.performance_ytd !== undefined ? `${record.performance_ytd}%` : 'n/a'}
            </a>
          </p>
          <p>
            <a onClick={() => handleSort('realized_results_current_positions_performance')} style={getColorStyle(record.realized_results_current_positions_performance)}>
              Realized Results Current Positions Performance: {record.realized_results_current_positions_performance !== null && record.realized_results_current_positions_performance !== undefined ? `${record.realized_results_current_positions_performance}%` : 'n/a'}
            </a>
          </p>
          <p>
            <a onClick={() => handleSort('unrealized_prof_loss_Eur_perc')} style={getColorStyle(record.unrealized_prof_loss_Eur_perc)}>
              Unrealized Profit/Loss Percentage: {record.unrealized_prof_loss_Eur_perc !== null && record.unrealized_prof_loss_Eur_perc !== undefined ? `${record.unrealized_prof_loss_Eur_perc}%` : 'n/a'}
            </a>
          </p>
          <p>
            <a onClick={() => handleSort('Overall_Relative_Sharpe_Ratio_Performance')} style={getColorStyle(record.Overall_Relative_Sharpe_Ratio_Performance
)}>
              Risk-Adjusted Performance Score: {record.Overall_Relative_Sharpe_Ratio_Performance !== null && record.Overall_Relative_Sharpe_Ratio_Performance !== undefined ? `${record.Overall_Relative_Sharpe_Ratio_Performance}` : 'n/a'}
            </a>
          </p>
        </div>
      ),
    },
    // Add more columns as needed
  ];

  useEffect(() => {
    setSortedData(portfolioData);
    setFilteredData(portfolioData);
  }, [portfolioData]);

  return (
    <>
      <Collapse defaultActiveKey={[]}>
        <Panel header="Performance Details" key="1">
          <AutoComplete
            style={{ width: 200, marginBottom: '20px' }}
            options={portfolioData
              .filter(record => (record.Ticker || '').toLowerCase().includes(searchValue.toLowerCase()))
              .map(record => ({ value: record.Ticker }))}
            onSearch={handleSearch}
            onSelect={handleSelect}
            placeholder="Search Ticker"
          />
          <Table 
            dataSource={filteredData} 
            columns={columns} 
            rowKey="id" 
            pagination={false}
          />
        </Panel>
      </Collapse>
      {isModalVisible && (
        <ThreeTablesModal 
          tickerValue={selectedTicker} 
          open={isModalVisible} 
          onClose={() => setIsModalVisible(false)} 
          userId={userId} 
        />
      )}
    </>
  );
};

export default Portfolio_Performance;


