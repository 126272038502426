import React, { useState, useEffect } from 'react';
import { Table, Input, Button, AutoComplete } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import api from '../functions/api';
import { getDataParameters } from '../redux/reducers/portfolioReducer';

const MaintenanceVDP = () => {
  const dispatch = useDispatch();
  const VDP_dataset = useSelector(state => state.portfolio?.parametersData?.VDP_dataset_json);
  const [vdpDataset, setVdpDataset] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [changedData, setChangedData] = useState(null);
  const [shouldCallVectorDataParametersChange, setShouldCallVectorDataParametersChange] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [editingColumn, setEditingColumn] = useState('');
  const [editingValue, setEditingValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filteredDataset, setFilteredDataset] = useState([]);

  useEffect(() => {
    if (!VDP_dataset || VDP_dataset.length === 0) {
      dispatch(getDataParameters());
    } else {
      setVdpDataset(VDP_dataset);
      setFilteredDataset(VDP_dataset);
    }
  }, [dispatch, VDP_dataset]);

  const generateVDPColumns = (dataset) => {
    if (!dataset || dataset.length === 0) {
      console.warn("VDP dataset is empty or undefined.");
      return [];
    }

    return Object.keys(dataset[0]).map(key => ({
      title: key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),  // Format titles
      dataIndex: key,
      key: key,
      sorter: (a, b) => {
        if (typeof a[key] === 'string' && typeof b[key] === 'string') {
          return a[key].localeCompare(b[key]);
        }
        if (typeof a[key] === 'number' && typeof b[key] === 'number') {
          return a[key] - b[key];
        }
        return 0;
      },
      render: (text, record) => (
        editingKey === record.ID && editingColumn === key ? (
          <Input
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
            onPressEnter={handleSave}
          />
        ) : (
          <div onClick={() => {
            setEditingKey(record.ID);
            setEditingColumn(key);
            setEditingValue(text);
          }}>
            {text !== null ? text : '-'}
          </div>
        )
      )
    }));
  };

  const handleSave = () => {
    if (editingKey && editingColumn) {
        console.log('Save data:', editingKey, editingColumn, editingValue);

        let cleanedValue = editingValue.trim() === "" ? null : editingValue.trim();

        let changedData = {
            id: editingKey,
            key: editingColumn,
            value: cleanedValue,
        };

        setChangedData(changedData);
        setShouldCallVectorDataParametersChange(true);

        // Update local dataset while preserving filters
        const updatedDataset = vdpDataset.map(item =>
            item.ID === editingKey ? { ...item, [editingColumn]: cleanedValue } : item
        );
        setVdpDataset(updatedDataset);

        // Preserve the filtered view by reapplying the filter
        setFilteredDataset(updatedDataset.filter(item =>
            item.Vector_datafield && item.Vector_datafield.toLowerCase().includes(searchValue.toLowerCase())
        ));

        setEditingKey('');
        setEditingColumn('');
        setEditingValue('');
    }
};




useEffect(() => {
  if (!isFetchingData && shouldCallVectorDataParametersChange) {
      api.post('/Vector_Data_Parameters_change', { data: [changedData] })
          .then(response => {
              console.log('API response:', response.data);
              dispatch(getDataParameters()); // Fetch updated data
          })
          .catch(error => {
              console.error('API error:', error);
          })
          .finally(() => {
              setShouldCallVectorDataParametersChange(false);
              setChangedData(null);

              // Reapply the search filter AFTER API refresh
              setTimeout(() => {
                  setFilteredDataset(vdpDataset.filter(item =>
                      item.Vector_datafield && item.Vector_datafield.toLowerCase().includes(searchValue.toLowerCase())
                  ));
              }, 500); // Small delay to allow API state to update
          });
  }
}, [changedData, shouldCallVectorDataParametersChange, isFetchingData, dispatch, searchValue, vdpDataset]);

  const handleSearch = (value) => {
    setSearchValue(value);
    const filtered = vdpDataset.filter(item =>
      item.Vector_datafield && item.Vector_datafield.toString().toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDataset(filtered);
  };

  const handleSelect = (value) => {
    setSearchValue(value);
    const filtered = vdpDataset.filter(item =>
      item.Vector_datafield && item.Vector_datafield.toString().toLowerCase() === value.toLowerCase()
    );
    setFilteredDataset(filtered);
  };

  return (
    <>
      <AutoComplete
        style={{ width: 250, marginBottom: '10px' }}
        options={vdpDataset.map(item => item.Vector_datafield ? ({ value: item.Vector_datafield.toString() }) : null).filter(Boolean)}
        value={searchValue}
        onChange={handleSearch}
        onSelect={handleSelect}
        placeholder="Search by Vector Datafield"
      />
      <Button type="primary" onClick={handleSave} style={{ marginBottom: '10px' }}>
        Save
      </Button>
      <Table
        className="custom-table"
        size="small"
        dataSource={filteredDataset}
        columns={generateVDPColumns(filteredDataset)}
        rowKey="ID"
        pagination={{ pageSize: 10 }}
      />
      {editingKey && editingColumn && (
        <Button type="primary" onClick={handleSave} style={{ marginTop: '10px' }}>
          Save
        </Button>
      )}
    </>
  );
};

export default MaintenanceVDP;
