import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts";

const Piecharts_MUI = ({
  data,
  title = "Piechart",
  showLegend = true,
  labelPrefix = "Label",
  chartHeight = 300,
  maxWidth = 700,
  titleFontSize = 24,
  legendFontSize = 12,
  legendInformation = {},
}) => {
  if (!data) return null;

  const userId = Object.keys(data)[0];
  const rawEntries = data[userId];

  const filteredEntries = Object.entries(rawEntries).filter(
    ([key]) => key !== "unknown"
  );

  const pieData = filteredEntries.map(([key, info], index) => ({
    id: index,
    label: `${labelPrefix} ${key}${
      legendInformation[key] ? ` : ${legendInformation[key]}` : ""
    }`,
    value: Number(info.percent),
  }));

  return (
    <Card sx={{ maxWidth, margin: "auto", mt: 4 }}>
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontSize: titleFontSize }}
        >
          {title}
        </Typography>

        <PieChart
          series={[
            {
              data: pieData,
              highlightScope: { fade: "global", highlight: "item" },
              faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
              valueFormatter: ({ value }) => `${value}%`,
            },
          ]}
          height={chartHeight}
          slotProps={{
            legend: {
              labelStyle: { fontSize: legendFontSize },
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default Piecharts_MUI;
