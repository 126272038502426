// /// fetch_storeWidgetprefs.js
import api from '../functions/api';
import getUserId from '../functions/getUserId';

export default async function fetchWidgetprefs(userid, token) {
    try {
        // First API call to fetch widget preferences
        const widgetResponse = await api.get('/widget_content_management', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: { userid }
        });

        // Second API call to fetch widget preload data
        const preloadResponse = await api.get('/widget_preload', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: { userid }
        });

        // console.log("response backend Widgetuserprefs ", widgetResponse.data);
        // console.log("response backend Widgetpreload ", preloadResponse.data);

        return {
            widgetPreferences: widgetResponse.data,
            widgetPreload: preloadResponse.data
        };

    } catch (error) {
        console.error("Error fetching widget preferences or preload data: ", error);
        return null;
    }
}


// /// fetch_storeWidgetprefs.js
// import api from '../functions/api';
// import getUserId from '../functions/getUserId';

// export default async function fetchWidgetprefs(userid, token) {
//     try {
//         // console.log("fetch process attempted for userid", userid);
//         const response = await api.get('/widget_content_management', {
//             headers: {
//                 'Authorization': `Bearer ${token}` // Include if token authentication is required
//             },
//             params: {
//                 userid: userid // Passing userId as a query parameter
//             }
//         });
//         // console.log("response backend Widgetuserprefs ", response.data);
//         return response.data;
//     } catch (error) {
//         console.error("Error fetching widget preferences: ", error);
//         // Handle or throw the error based on your application's needs
//     }
// }
