import React, { useEffect, useState } from 'react';
import { Modal, Spin, message, Typography } from 'antd';
import SMAGraph from './SMAGraph';
import ThreeTablesModal from "./SearchResultModal";
import getUserId from '../functions/getUserId';
import api from '../functions/api';

const { Title } = Typography;

const WidgetGraph = ({ title, widgetcontentid }) => {
  const [graphData, setGraphData] = useState(null);
  const [currency, setCurrency] = useState("USD");
  const [ticker, setTicker] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [isGraphModalVisible, setIsGraphModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [threeTablesModalVisible, setThreeTablesModalVisible] = useState(false);

  const toggleGraphModal = () => {
    setIsGraphModalVisible(!isGraphModalVisible);
  };

  const openThreeTablesModal = () => {
    setThreeTablesModalVisible(true);
  };

  const fetchTransactionData = async () => {
    try {
      const response = await api.post("/fetch_Transactions", {
        ticker: ticker,
        userid: getUserId(),
      });
      const formattedData = processTransactionData(response.data);
      setTransactionData(formattedData);
      // console.log("Fetched Transaction Data:", response.data);
    } catch (error) {
      console.error("Error fetching transaction data:", error);
    }
  };

  const processTransactionData = (transactions) => {
    return transactions.map(transaction => ({
      date: new Date(transaction.date).toISOString().split('T')[0],
      price: transaction.price,
      type: transaction.buysell,
      quantity: transaction.quantity
    }));
  };

  const transformGraphData = (data) => {
    const { dates, prices, sma_short: smaShort, sma_long: smaLong } = data;
    return dates.map((date, index) => ({
      date,
      price: prices[index],
      smaShort: smaShort[index],
      smaLong: smaLong[index],
    }));
  };

  useEffect(() => {
    if (isGraphModalVisible && widgetcontentid) {
      setLoading(true);
      api.get(`/widget_content_delivery?widgetcontentid=${widgetcontentid}`)
        .then(response => {
          const { graph_data, currency, ticker } = response.data;
          console.log("Graph Data:", graph_data);
          const transformedData = transformGraphData(graph_data); // Transform data here
          setGraphData(transformedData);
          setCurrency(currency || "USD");
          setTicker(ticker);
          setLoading(false);
        })
        .catch(error => {
          message.error('Failed to fetch graph data.');
          setLoading(false);
          console.error(error);
        });

      if (ticker) fetchTransactionData();
    }
  }, [isGraphModalVisible, widgetcontentid, ticker]);

  return (
    <>
      <div onClick={toggleGraphModal} style={{ cursor: 'pointer', padding: '10px', backgroundColor: '#f0f2f5', borderRadius: '4px' }}>
        <Title level={5} style={{ margin: 0 }}>{title}</Title>
      </div>

      <Modal
        title={
          <div onClick={openThreeTablesModal} style={{ cursor: 'pointer' }}>
            {`${title} (${currency})`}
          </div>
        }
        visible={isGraphModalVisible}
        onCancel={() => setIsGraphModalVisible(false)}
        footer={null}
        width={700}
        destroyOnClose
      >
        {loading ? (
          <Spin tip="Loading graph data..." />
        ) : (
          graphData ? (
            <SMAGraph
              data={graphData}
              currency={currency}
              ticker={ticker}
              transactions={transactionData}
            />
          ) : (
            <div>No data available for this graph.</div>
          )
        )}
      </Modal>

      {threeTablesModalVisible && (
        <ThreeTablesModal
          userId={getUserId()}
          tickerValue={ticker}
          open={threeTablesModalVisible}
          onClose={() => setThreeTablesModalVisible(false)}
        />
      )}
    </>
  );
};

export default WidgetGraph;
