import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import '../css/TickerFundamentals.css';
import SimpleExternalLinkNewWindow from '../functions/LinkFlex';

const TickerFundamentals = ({ data, DP_dataset_json, DD_dataset_json, tickerValue, sectorValue }) => {
    // console.log('TickerFundamentals data:', data);
    // console.log("DD_dataset_json", DD_dataset_json);
    // console.log("sector ", sectorValue)
    // console.log("DP_dataset_json", DP_dataset_json)
    
    const filteredData = data.fields.filter(
        (item) => !['ID', 'ticker', 'epoch', 'sector'].includes(item.fieldName) && item.value
    );

    // If no valid data remains, display a fallback message
    if (!filteredData.length) {
        return <div>No Ticker Fundamentals Data Available</div>;
    }

    const dpMap = DP_dataset_json.reduce((acc, entry) => {
        acc[entry.DataField] = entry;
        return acc;
    }, {});

    const trends = DD_dataset_json.reduce((acc, entry) => {
        if (entry.datafield && entry.trend) {
            acc[entry.datafield] = entry.trend;
        }
        return acc;
    }, {});

    return (
        <div className="ticker-fundamentals">
            <Row gutter={[10, 10]} justify="start">
                {filteredData.map((item, index) => {
                    if (!item.value) return null;
                    // console.log(`Field: ${item.fieldName}, To Average: ${item.toAverage}`);

                    const dpEntry = dpMap[item.fieldName] || {};
                    const label = dpEntry.Label || item.fieldName;
                    const showBehind = dpEntry.show_behind || "";
                    const showInfront = dpEntry.show_infront || "";

                    const displayValue = `${showInfront}${item.value}${showBehind}`;
                    const trend = trends[item.fieldName];

                    const sanitizedExternalLink = item.externalLink?.replace(/^f"/, "").replace(/"$/, "");
                    const formattedUrl = sanitizedExternalLink?.replace("{value}", tickerValue);

                    const valueStyle = trend === 'positive' ? { color: 'green' } : trend === 'negative' ? { color: 'red' } : {};

                    // Debugging: Ensure we know what the actual values are
                    // console.log(`Item: ${item.fieldName}, Quarter Trend: ${item.quarterTrend}, To Average: ${item.toAverage}`);

                    // Determine border color
                    let borderColor = 'transparent';
                    const quarterTrendIsPositive = item.quarterTrend?.toLowerCase() === 'positive';
                    const quarterTrendIsNegative = item.quarterTrend?.toLowerCase() === 'negative';
                    const toAverageIsPositive = item.toAverage > 0;
                    const toAverageIsNegative = item.toAverage < 0;

                    if (quarterTrendIsPositive && toAverageIsPositive) {
                        borderColor = 'green';
                    } else if (quarterTrendIsNegative && toAverageIsNegative) {
                        borderColor = 'red';
                    }

                    const previousValue = DD_dataset_json.find(dd => dd.datafield === item.fieldName)?.oldvalue;

                    return (
                        <Col xs={24} sm={12} md={8} lg={6} key={index}>
                            <div
                                className="grid-item"
                                style={{
                                    border: `4px solid ${borderColor}`,
                                    borderRadius: '5px',
                                    padding: '10px',
                                }}
                            >
                                <SimpleExternalLinkNewWindow
                                    clickedValue={
                                        <Tooltip
                                            title={
                                                `${dpEntry.Tooltip || label}. ` +
                                                `${previousValue ? `Previous value: ${previousValue}. ` : ''}` +
                                                `${item.quarterTrend ? `The trend compared to the previous quarter is ${item.quarterTrend}. ` : ''}` +
                                                `${item.sectorRanking !== null && item.sectorRanking !== undefined 
                                                    ? `In its sector (${sectorValue[0]}), the ranking is ${item.sectorRanking} on a total of ${item[`N_${item.fieldName}`]}. ` 
                                                    : ''}` +
                                                `${item.toAverage !== null && item.sectorAverage
                                                    ? `It scores ${item.toAverage > 0 ? 'better' : 'worse'} than average (${item.sectorAverage}) for the sector.` 
                                                    : ''}`
                                            }
                                        >
                                            <strong>
                                                {label}:{' '}
                                                <span style={valueStyle}>
                                                    {displayValue} {trend === 'positive' && <span style={{ color: 'green' }}>↑</span>}
                                                    {trend === 'negative' && <span style={{ color: 'red' }}>↓</span>}
                                                </span>
                                            </strong>
                                        </Tooltip>
                                    }
                                    url={formattedUrl}
                                />
                                <div>
                                    {item.quarterTrend && (
                                        <div>
                                            Quarter Trend:{' '}
                                            <span
                                                style={{
                                                    color: quarterTrendIsPositive
                                                        ? 'green'
                                                        : quarterTrendIsNegative
                                                        ? 'red'
                                                        : 'inherit',
                                                }}
                                            >
                                                {quarterTrendIsPositive && '↑'}
                                                {quarterTrendIsNegative && '↓'}
                                                {item.quarterTrend}
                                            </span>
                                        </div>
                                    )}
                                    {item.sectorRanking !== null && item.sectorRanking !== undefined && (
                                        <div>
                                            Sector Ranking: {item.sectorRanking}
                                            {item[`N_${item.fieldName}`] !== null && item[`N_${item.fieldName}`] !== undefined && (
                                                <span> ({item[`N_${item.fieldName}`]})</span>
                                            )}
                                        </div>
                                    )}
                                    {item.toAverage !== null && item.toAverage !== undefined && (
                                        <div>To Average: {item.toAverage}%</div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

export default TickerFundamentals;
