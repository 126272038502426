import React, { useState, useEffect } from "react";
// import React, { useState } from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Checkbox, Grid, Button } from "@mui/material";
import { addDays, format } from "date-fns";
import { nl } from "date-fns/locale";
import api from '../functions/api';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useMediaQuery, useTheme } from "@mui/material";
import StockChart from "../components/Stockchart_MUI";

const Schema_Marcel = () => {
  const generateDates = () => {
    const today = new Date();
    return Array.from({ length: 7 }, (_, i) => ({
      date: format(addDays(today, i), "yyyy-MM-dd"),
      day: format(addDays(today, i), "EEEE", { locale: nl }),
    }));
  };

  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleCheckboxes = () => {
    setShowCheckboxes(!showCheckboxes);
  };

  const timeSlots = ["Ochtend", "Middag", "Avond"];
  const [dates, setDates] = useState([]);
  const [checkedStates, setCheckedStates] = useState([]);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const generated = generateDates();
    setDates(generated);

    api.get("/marcel")
      .then((response) => {
        console.log("Fetched marcel data", response.data);
        const fetched = response.data;

        const checked = [];
        const rows = [];

        generated.forEach(({ date, day }) => {
          const slots = fetched.filter(item =>
            format(new Date(item.date), "yyyy-MM-dd") === date
          );

          checked.push(timeSlots.map(slot =>
            slots.find(s => s.time_slot === slot)?.checked === 1
          ));

          rows.push(timeSlots.map(slot => {
            const match = slots.find(s => s.time_slot === slot);
            return {
              name1: match?.name1 || "",
              name2: match?.name2 || ""
            };
          }));
        });

        setCheckedStates(checked);
        setRowData(rows);
      })
      .catch((err) => {
        console.error("Failed to fetch marcel data", err);
      });
  }, []);

  const saveCellChange = async (dateIndex, slotIndex, field, value) => {
    const payload = [{
      date: dates[dateIndex].date,
      day: dates[dateIndex].day,
      time_slot: timeSlots[slotIndex],
      checked: checkedStates[dateIndex][slotIndex],
      name1: field === "name1" ? value : rowData[dateIndex][slotIndex].name1,
      name2: field === "name2" ? value : rowData[dateIndex][slotIndex].name2,
    }];

    try {
      await api.post("/marcel", payload);
      setSnackbar({ open: true, message: "Opgeslagen", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Fout bij opslaan", severity: "error" });
    }
  };

  const handleCellKeyDown = (e, dateIndex, slotIndex, field) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault();
      const newValue = e.target.innerText.trim();
      const updated = [...rowData];
      updated[dateIndex][slotIndex][field] = newValue;
      setRowData(updated);
      saveCellChange(dateIndex, slotIndex, field, newValue);
      if (e.key === "Tab") e.target.blur();
    }
  };

  const handleCheckboxChange = (dateIndex, slotIndex) => {
    const updatedStates = [...checkedStates];
    updatedStates[dateIndex][slotIndex] = !updatedStates[dateIndex][slotIndex];
    setCheckedStates(updatedStates);

    const payload = [{
      date: dates[dateIndex].date,
      day: dates[dateIndex].day,
      time_slot: timeSlots[slotIndex],
      checked: updatedStates[dateIndex][slotIndex],
      name1: rowData[dateIndex][slotIndex].name1,
      name2: rowData[dateIndex][slotIndex].name2,
    }];

    api.post("/marcel", payload)
      .then(() => {
        setSnackbar({ open: true, message: "Opgeslagen", severity: "success" });
      })
      .catch(() => {
        setSnackbar({ open: true, message: "Fout bij opslaan", severity: "error" });
      });
  };

  const tableStyles = {
    fontSize: "0.8rem",
    lineHeight: "1.2",
    padding: "4px",
    spacing: "4px",
    widths: {
      date: "40px",
      slot: "100px",
      name1: "100px",
      name2: "100px",
    },
  };

  const buttonStyles = {
    fontSize: "0.8rem",
    padding: "4px 8px",
    marginBottom: "20px",
  };

  if (!dates.length || !checkedStates.length || !rowData.length) {
    return <div style={{ padding: 20 }}>Laden...</div>;
  }

  return (

  


    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "40px" }}>

   

      <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <h3 style={{ marginRight: "20px" }}>Schema Marcel</h3>
        <img
          src="/PHOTO-2024-11-19-22-15-19.jpg"
          alt="Hiking in the mountains"
          style={{ width: "150px", height: "auto", borderRadius: "8px" }}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={toggleCheckboxes}
        style={buttonStyles}
      >
        {showCheckboxes ? "Verberg Checkboxes" : "Toon Checkboxes"}
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <TableContainer component={Paper} style={{ width: isMobile ? "100%" : "650px", overflowX: "auto" }}>
              <Table size="small" style={{ borderSpacing: tableStyles.spacing }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: tableStyles.widths.date, fontSize: tableStyles.fontSize, padding: tableStyles.padding }}>Datum</TableCell>
                    <TableCell style={{ width: tableStyles.widths.slot, fontSize: tableStyles.fontSize, padding: tableStyles.padding }}>Tijdvak</TableCell>
                    <TableCell style={{ width: tableStyles.widths.name1, fontSize: tableStyles.fontSize, padding: tableStyles.padding }}>Naam 1</TableCell>
                    <TableCell style={{ width: tableStyles.widths.name2, fontSize: tableStyles.fontSize, padding: tableStyles.padding }}>Naam 2</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dates.map((entry, dateIndex) => (
                    <React.Fragment key={dateIndex}>
                      {timeSlots.map((slot, slotIndex) => (
                        <TableRow key={slotIndex}>
                          {slotIndex === 0 && (
                            <TableCell
                              rowSpan={3}
                              style={{
                                width: tableStyles.widths.date,
                                fontSize: tableStyles.fontSize,
                                padding: tableStyles.padding,
                                lineHeight: tableStyles.lineHeight,
                              }}
                            >
                              {entry.date}
                              <br />
                              <span style={{ fontStyle: "italic", fontSize: "0.7rem" }}>{entry.day}</span>
                            </TableCell>
                          )}
                          <TableCell
                            style={{
                              width: tableStyles.widths.slot,
                              fontSize: tableStyles.fontSize,
                              padding: tableStyles.padding,
                            }}
                          >
                            {showCheckboxes && (
                              <Checkbox
                                sx={{ transform: "scale(0.8)", opacity: 0.7 }}
                                checked={checkedStates[dateIndex]?.[slotIndex] ?? false}
                                onChange={() => handleCheckboxChange(dateIndex, slotIndex)}
                              />
                            )}
                            {slot}
                          </TableCell>
                          <TableCell
                            contentEditable={!checkedStates[dateIndex][slotIndex]}
                            suppressContentEditableWarning
                            onFocus={(e) => {
                              if (e.target.innerText === "enter naam") e.target.innerText = "";
                            }}
                            onKeyDown={(e) => handleCellKeyDown(e, dateIndex, slotIndex, "name1")}
                            style={{
                              width: tableStyles.widths.name1,
                              fontStyle: checkedStates[dateIndex][slotIndex] ? "normal" : "italic",
                              backgroundColor: checkedStates[dateIndex][slotIndex] ? "#e0e0e0" : "inherit",
                              pointerEvents: checkedStates[dateIndex][slotIndex] ? "none" : "auto",
                              boxShadow: !checkedStates[dateIndex][slotIndex] &&
                                (!rowData[dateIndex]?.[slotIndex]?.name1 || rowData[dateIndex]?.[slotIndex]?.name1 === "enter naam")
                                  ? "0 0 5px 2px #fff176"
                                  : "none",
                              fontSize: tableStyles.fontSize,
                              padding: tableStyles.padding,
                            }}
                          >
                            {checkedStates[dateIndex][slotIndex] ? "" : (rowData[dateIndex]?.[slotIndex]?.name1 || "enter naam")}
                          </TableCell>
                          <TableCell
                            contentEditable={!checkedStates[dateIndex][slotIndex]}
                            suppressContentEditableWarning
                            onFocus={(e) => {
                              if (e.target.innerText === "enter naam") e.target.innerText = "";
                            }}
                            onKeyDown={(e) => handleCellKeyDown(e, dateIndex, slotIndex, "name2")}
                            style={{
                              width: tableStyles.widths.name2,
                              fontStyle: checkedStates[dateIndex][slotIndex] ? "normal" : "italic",
                              backgroundColor: checkedStates[dateIndex][slotIndex] ? "#e0e0e0" : "inherit",
                              pointerEvents: checkedStates[dateIndex][slotIndex] ? "none" : "auto",
                              boxShadow: !checkedStates[dateIndex][slotIndex] &&
                                (!rowData[dateIndex]?.[slotIndex]?.name2 || rowData[dateIndex]?.[slotIndex]?.name2 === "enter naam")
                                  ? "0 0 5px 2px #fff176"
                                  : "none",
                              fontSize: tableStyles.fontSize,
                              padding: tableStyles.padding,
                            }}
                          >
                            {checkedStates[dateIndex][slotIndex] ? "" : (rowData[dateIndex]?.[slotIndex]?.name2 || "enter naam")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Schema_Marcel;
