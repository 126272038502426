import { useSelector } from "react-redux";
import React from "react";
import { Paper, Typography, Box } from "@mui/material";

const Portfolio_Highlights = () => {
  const portfolioTotalValue = useSelector(
    (state) => state.portfolio?.data?.portfolio_topline?.[0]?.portfolio_total_value || 0
  );
  const demoMode = useSelector((state) => state.demoMode?.isDemoMode);

  const portfolioAlertsCount = useSelector(
    (state) => state.portfolio?.data?.pf_data_alert?.length ?? 0
  );

  const favoriteAlertsCount = useSelector(
    (state) => state.favorites?.data?.favorites_alert_data?.length ?? 0
  );

  const opportunitiesAlertsCount = useSelector(
    (state) => state.portfolio?.data?.opportunities_alerts?.length ?? 0
  );

  return (
    <Paper
  elevation={0} // remove shadow
  sx={{
    border: "none",                     // remove border
    borderRadius: "16px",              // keep your rounded corners
    padding: "16px 20px",
    width: "80%",
    minWidth: 220,
    backgroundColor: "transparent",    // remove background if needed
    boxShadow: "none",                 // fully kill shadows
  }}
>

      <Typography variant="h6" mb={2} fontWeight="medium">
        Quick Stats
      </Typography>

      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" fontWeight="regular">
            Total Value:
          </Typography>
          <Typography
  variant="body2"
  fontWeight="bold"
  sx={demoMode ? { filter: 'blur(4px)' } : {}}
>
  ${parseFloat(portfolioTotalValue).toLocaleString()}
</Typography>

        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" fontWeight="regular">
            Portfolio Alerts:
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {portfolioAlertsCount}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" fontWeight="regular">
            Favorite Alerts:
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {favoriteAlertsCount}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" fontWeight="regular">
            Opportunities Alerts:
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {opportunitiesAlertsCount}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default Portfolio_Highlights;
