// //////////Account.jsx

import React, { useState, useEffect, useRef } from 'react';
import api from '../functions/api';
import { Dropdown, Button, Tooltip, Modal } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PreferencesModal from './PreferencesModal.jsx';
import User from './Users.jsx';
import useLocalStorage from '../functions/useLocalStorage';
import getUserId from '../functions/getUserId';
import CategoryPreferencesModal from './CategoryPreferencesModal.jsx';
import MaintenanceModal from './MaintenanceModal.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { logIn, logOut, clearUserData, clearPortfolioData,setWidgetPreferences } from '../redux/actions';
import store from '../redux/store';
import { getPortfolioData, getFavoritesData } from '../redux/reducers/portfolioReducer';
import { getDataParameters, setFilteredTickers,setFilteredTickersF, getPortfolioESGData, getFavoritesESGData } from '../redux/reducers/portfolioReducer';
import BrokerAccessModal from './BrokerAccessModal.jsx';
import { getStoreSize } from '../functions/storeSize';
import fetchWidgetprefs from '../functions/fetch_storeWidgetprefs';
import ESGChecker from './ESGChecker.jsx';
import MarketAlerts from './MarketAlerts.jsx';
import ClauseManagement from './ClauseManagement.jsx';
import AlertEffectiveness from './AlertEffectiveness.jsx';
import ScriptReporting from './ScriptReporting.jsx';
import TickerMaintenance from './TickerMaintenance.jsx';
import Portfolio_Datamanagement from './Portfolio_Datamanagement.jsx';
import { ChevronDown as Icon } from "lucide-react";
import NavButton from "./ui/NavButton";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";


const Account = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [token, setToken] = useLocalStorage('token', null);
  const [isMaintenanceModalVisible, setIsMaintenanceModalVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const dataParameters = useSelector((state) => state.portfolio.dataParameters);
  const [isDatabaseModalVisible, setIsDatabaseModalVisible] = useState(false); // New state for the modal visibility
  const [isTickerMaintenanceModalVisible, setIsTickerMaintenanceModalVisible] = useState(false); // New state for the modal visibility
  const dispatch = useDispatch();
  const { userName } = useSelector(state => state.auth);
  const isDebugMode = useSelector(state => state.debugMode?.isDebugMode);
  const [isPortfolioDataManagementVisible, setIsPortfolioDataManagementVisible] = useState(false);


  useEffect(() => {
    if (isDebugMode) {
      // Use store.getState() to access the full state when debug mode is on
      const allState = store.getState();  // Assuming 'store' is imported or accessible in this file
      console.log("All State: ", allState);
      // console.log("isDebugMode: ", isDebugMode);
    }
  }, [isDebugMode]);  // Effect runs only when isDebugMode changes


  const [isAlertEffectivenessModalVisible, setIsAlertEffectivenessModalVisible] = useState(false);
  const [isScriptReportingModalVisible, setIsScriptReportingModalVisible] = useState(false);

    const handleAEClick = () => {
      setIsAlertEffectivenessModalVisible(true);
    };

    const handleSRClick = () => {
      setIsScriptReportingModalVisible(true);
    };

    const handleTickerMaintenancelick = () => {
      setIsTickerMaintenanceModalVisible(true);
    };

    const handleAlertEffectivenessModalClose = () => {
      setIsAlertEffectivenessModalVisible(false);
    };

    const handleScriptReportingModalClose = () => {
      setIsScriptReportingModalVisible(false);
    };


  const prevFilteredTickersRef = useRef();
  const prevFilteredTickersFRef = useRef();

  const stored_portfolioData = useSelector((state) => state.portfolio.data);
  // console.log("stored_portfolioData: ", stored_portfolioData);
  const stored_favoritesData = useSelector((state) => state.favorites?.data);

  const allTickers = stored_portfolioData?.portfolio?.map(item => item.Ticker) || [];

  const allTickersF = stored_favoritesData?.data?.map(item => item.Ticker) || [];

  const filteredTickers = allTickers.filter(ticker => ticker !== null && ticker !== '');
  const filteredTickersF = allTickersF.filter(ticker => ticker !== null && ticker !== '');

  const [isESGCheckerVisible, setIsESGCheckerVisible] = useState(false);
const [isClauseModalVisible, setIsClauseModalVisible] = useState(false);

const handleClausesClick = () => {
  setIsClauseModalVisible(true);
};

const handleClauseModalClose = () => {
  setIsClauseModalVisible(false);
};

  
  const handleESGCheckerClick = () => {
    window.open('/esgcheck', '_blank');
  };
  
  

  useEffect(() => {
    const storeSize = getStoreSize();
    // console.log("Redux Store Size in bytes:", storeSize);
  }, []);

  useEffect(() => {
    if (JSON.stringify(prevFilteredTickersRef.current) !== JSON.stringify(filteredTickers)) {
      // console.log("ACCOUNT: Dispatching filteredTickers: ", filteredTickers);
      dispatch(setFilteredTickers(filteredTickers));
    }
    prevFilteredTickersRef.current = filteredTickers;
  }, [filteredTickers, dispatch]);
  
  useEffect(() => {
    if (JSON.stringify(prevFilteredTickersFRef.current) !== JSON.stringify(filteredTickersF)) {
      // console.log("ACCOUNT: Dispatching filteredTickersF: ", filteredTickersF);
      dispatch(setFilteredTickersF(filteredTickersF));
    }
    prevFilteredTickersFRef.current = filteredTickersF;
  }, [filteredTickersF, dispatch]);


  useEffect(() => {
    // console.log("check on dataparameters")
    if (!dataParameters) {
      dispatch(getDataParameters());
      // console.log("ACCOUNT.JSX: get DP dispatch")
    }
  }, [dataParameters, dispatch, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
    // console.log("isLoggedIn: ", isLoggedIn);
    dispatch(getPortfolioData(token));
    dispatch(getFavoritesData(token));
    // dispatch(getDataParameters());

    }
  }, [isLoggedIn, token, dispatch]);
  // }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && stored_portfolioData) {
    // console.log("isLoggedIn: ", isLoggedIn);
    dispatch(getPortfolioESGData(filteredTickers));
    }
  }, [isLoggedIn, stored_portfolioData]);

  useEffect(() => {
    if (isLoggedIn && stored_favoritesData) {
      // console.log("filteredTickersF: ", filteredTickersF)
    // console.log("isLoggedIn: ", isLoggedIn);
    dispatch(getFavoritesESGData(filteredTickersF));
    }
  }, [isLoggedIn, stored_favoritesData]);

  useEffect(() => {
    if (isLoggedIn && userId && token) {
      // Fetch and set widget preferences
      // console.log("fetching widget prefs");
      const fetchAndUpdateWidgetPrefs = async () => {
        try {
          const widgetPrefs = await fetchWidgetprefs(userId, token);

// Ensure data is properly extracted
if (widgetPrefs) {
    dispatch(setWidgetPreferences(widgetPrefs.widgetPreferences, widgetPrefs.widgetPreload));
}

        } catch (error) {
          console.error('Error fetching widget preferences:', error);
        }
      };
      fetchAndUpdateWidgetPrefs();
    }
  }, [isLoggedIn, userId, token, dispatch]); 


  const [isPreferencesModalVisible, setIsPreferencesModalVisible] = useState(false);
  const [modalState, setModalState] = useState({
    isLoginRegisterModalVisible: false,
    defaultTab: "2"
  });

  const [isCategoryPreferencesModalVisible, setIsCategoryPreferencesModalVisible] = useState(false);
  const [categoryPreferences, setCategoryPreferences] = useState([]);
  const [formattedName, setFormattedName] = useState(null);
  const [isBrokeraccessmodalVisible, setIsBrokeraccessmodalVisible] = useState(false);
  const handleBrokeraccessModalClose = () => {
    setIsBrokeraccessmodalVisible(false);};
 
  useEffect(() => {
    const fetchUserDetails = async () => {
      const storedToken = localStorage.getItem('token');
      setToken(storedToken);
    
      if (!storedToken) {
        dispatch(logOut());
        return;
      }
    
      try {
        const response = await api.get('/user_check');
        const { ID, first_name, last_name } = response.data;
        const formattedName = `${first_name} ${last_name}`;
        setUserId(ID);
        setFormattedName(formattedName);
        dispatch(logIn({ ID, userName: formattedName }));
      } catch (error) {
        console.error('Error fetching user details:', error);
        localStorage.removeItem('token');
        dispatch(logOut());
      }
    };
    
  
    fetchUserDetails();
  }, [token]);

  const handleLogout = () => {
    dispatch(clearUserData()); // Dispatch logOut action to Redux
    dispatch(clearPortfolioData());
    dispatch({ type: 'CLEAR_USER_DATA' })
    dispatch({ type: 'CLEAR_PORTFOLIO_DATA' })
    dispatch({ type: 'CLEAR_FAVORITES_DATA' })
    localStorage.removeItem('token');
    setToken(null); // clear the token state
    // setUserLoggedIn(prev => !prev);  // Toggle to trigger re-render
  
    dispatch(logOut());

    /// console.log("clearUserData dispatched");
    // console.log('Redux State:', store.getState());


    setModalState({ ...modalState, isLoginRegisterModalVisible: false });
  };

  const handleCategoryPreferencesClick = () => {
    setIsCategoryPreferencesModalVisible(true);
};


const handleBrokeraccessClick = () => {
  setIsBrokeraccessmodalVisible(true);
};

  const handlePreferencesClick = () => {
    setIsPreferencesModalVisible(true);
  };

  const handlePreferencesModalClose = () => {
    setIsPreferencesModalVisible(false);
  };

  const handleLoginClick = () => {
    setModalState({
      isLoginRegisterModalVisible: true,
      defaultTab: "2"
    });
  };

  const handleRegisterClick = () => {
    setModalState({
      isLoginRegisterModalVisible: true,
      defaultTab: "1"
    });
  };

  const handleMaintenanceClick = () => {
    setIsMaintenanceModalVisible(true); // Set the modal visibility to true
  };
  
  // Add handler to open the Database modal
  const handleDatabaseClick = () => {
    setIsDatabaseModalVisible(true);  // This will open the Database modal
  };

  const handleDatabaseModalClose = () => {
    setIsDatabaseModalVisible(false);  // This will close the Database modal
  };

  const [anchorEl, setAnchorEl] = useState(null);
const menuOpen = Boolean(anchorEl);

const handleMenuClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleMenuClose = () => {
  setAnchorEl(null);
};



  return (
    <div>

<NavButton
  icon={<Icon size={14} />}
  // label={formattedName || userName || "Guest"}

  label={userName || "Guest"}
  onClick={handleMenuClick}
  tooltip={
    isLoggedIn
      ? "Manage your data preferences"
      : "Login, or Register to Login. Then, this place also enables you to manage your datapreferences"
  }
/>

<Menu
  anchorEl={anchorEl}
  open={menuOpen}
  onClose={handleMenuClose}
  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
  transformOrigin={{ vertical: "top", horizontal: "left" }}
>
  {!isLoggedIn && (
    <>
      <MenuItem onClick={() => { handleLoginClick(); handleMenuClose(); }}>Login</MenuItem>
      <MenuItem onClick={() => { handleRegisterClick(); handleMenuClose(); }}>Register</MenuItem>
    </>
  )}

  {isLoggedIn && (
    <>
      <MenuItem onClick={() => { handleCategoryPreferencesClick(); handleMenuClose(); }}>Category Preferences</MenuItem>
      <MenuItem onClick={() => { handlePreferencesClick(); handleMenuClose(); }}>Data Preferences</MenuItem>
      <MenuItem onClick={() => { handleBrokeraccessClick(); handleMenuClose(); }}>Broker Access</MenuItem>
      <MenuItem onClick={() => { handleLogout(); handleMenuClose(); }}>Logout</MenuItem>

      {(userId === 1 || userId === 2) && (
        <MenuItem onClick={() => { handleMaintenanceClick(); handleMenuClose(); }}>
          Maintenance
        </MenuItem>
      )}

      {userId === 2 && (
        <>
          <MenuItem onClick={() => { handleDatabaseClick(); handleMenuClose(); }}>Market Alerts</MenuItem>
          <MenuItem onClick={() => { handleClausesClick(); handleMenuClose(); }}>Clauses</MenuItem>
          <MenuItem onClick={() => { handleAEClick(); handleMenuClose(); }}>Alert Effectiveness</MenuItem>
          <MenuItem onClick={() => { setIsPortfolioDataManagementVisible(true); handleMenuClose(); }}>Portfolio Data Management</MenuItem>
          <MenuItem onClick={() => { handleSRClick(); handleMenuClose(); }}>Script Reporting</MenuItem>
          <MenuItem onClick={() => { handleTickerMaintenancelick(); handleMenuClose(); }}>Ticker Maintenance</MenuItem>
        </>
      )}

      {(userId === 1 || userId === 2) && (
        <MenuItem onClick={() => { handleESGCheckerClick(); handleMenuClose(); }}>
          ESG Checker
        </MenuItem>
      )}
    </>
  )}
</Menu>


 
      <MarketAlerts isVisible={isDatabaseModalVisible} onClose={handleDatabaseModalClose} />

      {isPreferencesModalVisible && (
        <PreferencesModal
          userId={getUserId()}
          onClose={handlePreferencesModalClose}
        />
      )}

      {isClauseModalVisible && (
        <ClauseManagement
          isVisible={isClauseModalVisible}
          onClose={handleClauseModalClose}
        />
      )}

      <AlertEffectiveness 
        isVisible={isAlertEffectivenessModalVisible} 
         onClose={handleAlertEffectivenessModalClose} 
       />

      <ScriptReporting 
        isVisible={isScriptReportingModalVisible} 
         onClose={handleScriptReportingModalClose} 
       /> 


      <User
        defaultTab={modalState.defaultTab}
        setToken={setToken}
        visible={modalState.isLoginRegisterModalVisible}
        setVisible={(isVisible) => setModalState({...modalState, isLoginRegisterModalVisible: isVisible})}
      />

      <CategoryPreferencesModal 
      isVisible={isCategoryPreferencesModalVisible} 
      setIsVisible={setIsCategoryPreferencesModalVisible}
      categoryPreferences={categoryPreferences}
      />

      <MaintenanceModal 
          isVisible={isMaintenanceModalVisible} 
          setIsVisible={setIsMaintenanceModalVisible} 
        />

      <BrokerAccessModal 
          isVisible={isBrokeraccessmodalVisible} 
          setIsVisible={setIsBrokeraccessmodalVisible} 
          onClose={handleBrokeraccessModalClose}
        />      

      {isTickerMaintenanceModalVisible && (
        <TickerMaintenance
          isVisible={isTickerMaintenanceModalVisible}
          setIsVisible={setIsTickerMaintenanceModalVisible}
        />
      )}

    {isPortfolioDataManagementVisible && (
      <Modal
      open={isPortfolioDataManagementVisible}
      onCancel={() => setIsPortfolioDataManagementVisible(false)}
      footer={null}
      width="90%"
      title="Portfolio Data Management"
    >
      <Portfolio_Datamanagement />
    </Modal>
    )}



    </div>
  );
};

export default Account;

