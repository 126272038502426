import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs, Badge } from 'antd';
import api from '../functions/api';
import getUserId from '../functions/getUserId';
import PF_Alerts from './PF_Alerts';
import FavoritesAlerts from './FavoritesAlerts';
import Market_Alerts from './Market_Alerts';
import Opportunities from './Opportunities'; // Import the new component
import { useSelector } from 'react-redux';
import { Bell as Icon } from "lucide-react";
import NavButton from "./ui/NavButton";
import Box from "@mui/material/Box";

const { TabPane } = Tabs;

const Alerts = ({ disabled }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [opportunitiesUnseen, setOpportunitiesUnseen] = useState(false); // New state for Opportunities alerts

  const showModal = () => {
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  // Check for unseen alerts in Redux
  const portfolioUnseen = useSelector(state => state.portfolio?.data?.pf_alert?.some(alert => alert.seen === 0));
  const favoritesUnseen = useSelector(state => state.favorites?.data?.favorites_alert_data?.some(alert => alert.seen === 0));
  const userId = getUserId();
  const marketUnseen = useSelector(state =>
    state?.portfolio?.data?.market_alerts?.some(alert =>
      !alert.seenby ||
      !alert.seenby
        .split(',')
        .map(id => id.trim())
        .includes(userId ? userId.toString() : "")
    )
  );

  // Function to update unseen alerts from Opportunities (passed as prop)
  const updateOpportunitiesUnseen = (unseen) => {
    setOpportunitiesUnseen(unseen);
  };

  // Final check for any unseen alerts in all sources
  const hasUnseenAlerts = portfolioUnseen || favoritesUnseen || marketUnseen || opportunitiesUnseen;

  return (
    <>
      <Badge dot={hasUnseenAlerts} color="red">

      <NavButton
      icon={<Icon size={14} />}
      label="Alerts"
      onClick={showModal}
      disabled={disabled}
      tooltip="Alerts on Portfolio, Favorites, Market and Opportunities"
    />

        {/* <Button type="primary" onClick={showModal} disabled={disabled}>
          Alerts
        </Button> */}
      </Badge>
      <Modal
        title="Alerts"
        visible={isVisible}
        onCancel={handleClose}
        width={1400}
        footer={[
          <Button key="ok" type="primary" onClick={handleClose}>
            OK
          </Button>,
        ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane 
            tab={<Badge dot={portfolioUnseen} color="red">Portfolio Alerts</Badge>} 
            key="1"
          >
            <PF_Alerts />
          </TabPane>
          <TabPane 
            tab={<Badge dot={favoritesUnseen} color="red">Favorites Alerts</Badge>} 
            key="2"
          >
            <FavoritesAlerts />
          </TabPane>
          <TabPane 
            tab={<Badge dot={opportunitiesUnseen} color="red">Opportunities Alerts</Badge>} 
            key="3"
          >
            <Opportunities updateUnseen={updateOpportunitiesUnseen} />
          </TabPane>
          <TabPane 
            tab={<Badge dot={marketUnseen} color="red">Market Alerts</Badge>} 
            key="4"
          >
            <Market_Alerts/>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default Alerts;
