/// index.js or MainApp.jsx

import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import store, { persistor } from './redux/store';

import { startTokenExpiryCheck } from './redux/actions';
import useLocalStorage from './functions/useLocalStorage';
import VersionChecker from './functions/VersionChecker';

import Homepage from './components/Homepage';
import User from './components/Users';
import PrivacyPolicy from './components/PrivacyPolicy';
import Support from './components/Support';
import ESGChecker from './components/ESGChecker.jsx';
import Schema_Marcel from './components/Schema_Marcel.jsx';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const MainApp = () => {
  const dispatch = useDispatch();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showExpiredMessage, setShowExpiredMessage] = useState(false);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useLocalStorage('token', null);
  const tokenRef = useRef(localStorage.getItem('token'));

  useEffect(() => {
    const intervalId = startTokenExpiryCheck(dispatch, setShowExpiredMessage);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <React.StrictMode>
      <VersionChecker />
      <Router>
        <Routes>
          <Route path="/" element={
            <Homepage
              userId={userId}
              showLoginModal={showLoginModal}
              setShowLoginModal={setShowLoginModal}
              setToken={setToken}
              showExpiredMessage={showExpiredMessage}
            />
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/support" element={<Support />} />
          <Route path="/esgcheck" element={<ESGChecker />} />
          <Route path="/marcel" element={<Schema_Marcel />} />
        </Routes>
      </Router>
    </React.StrictMode>
  );
};

const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MainApp />
    </PersistGate>
  </Provider>
);


