// PF_Risk.jsx

import React, { useEffect, useState } from "react";
import api from '../functions/api';
import getUserId from '../functions/getUserId';
import { Spin, Table, Alert } from "antd";
import RiskCategory from "./Risk_Category";
import Piecharts_MUI from "./Piecharts_MUI";

const PF_Risk = () => {
  const [riskData, setRiskData] = useState(null);
  const [exposureData, setExposureData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const userId = getUserId();

    const fetchData = async () => {
      try {
        const [riskRes, exposureRes] = await Promise.all([
          api.get(`/pf_risk`, { params: { userid: userId } }),
          api.get(`/pf_other_exposures`, { params: { userid: userId } }),
        ]);

        // console.log("Risk Data", riskRes.data.portfolio_risk);
        // console.log("Exposure Data", exposureRes.data.portfolio_various_exposures);

        setRiskData(riskRes.data.portfolio_risk);
        setExposureData(exposureRes.data.portfolio_various_exposures);

      } catch (err) {
        setError("Failed to fetch risk or exposure data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const extractLegendInformation = (riskData) => {
    const userId = Object.keys(riskData)[0];
    const entries = riskData[userId];
    return Object.entries(entries)
      .filter(([key]) => key !== "unknown")
      .reduce((acc, [key, val]) => {
        acc[key] = val.assettypes;
        return acc;
      }, {});
  };

  if (loading) {
    return <Spin tip="Loading risk data..." />;
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  const legendInfo = riskData ? extractLegendInformation(riskData) : {};

  return (
    <div>
      <h3>Risk Analysis</h3>

      {riskData ? (
        <Piecharts_MUI 
        data={riskData} 
        title="Risk Distribution" 
        labelPrefix="Risk" 
        legendInformation={legendInfo}
        legendFontSize={8}
      />
      
      ) : (
        <p>No risk data available.</p>
      )}

      {riskData ? (
        <RiskCategory data={riskData} />
      ) : (
        <p>No risk data available.</p>
      )}
    </div>
  );
};

export default PF_Risk;
