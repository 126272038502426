//  Description: This component fetches and displays script reporting data in a modal. It allows users to start/stop scripts, edit intervals, and view running scripts and resource usage.
//
// It also includes a search feature and handles missing scripts.
//ScriptReporting.jsx

import React, { useState, useEffect } from 'react';
import { Table, Modal, message, InputNumber, Button, Switch,  Tooltip, Popconfirm, AutoComplete } from 'antd';
import api from '../functions/api'; // Assuming this is a pre-configured Axios instance
import { CopyOutlined } from '@ant-design/icons'; // Import Ant Design's copy icon
import { DeleteOutlined } from '@ant-design/icons';



const ScriptReporting = ({ isVisible, onClose }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [showRedAlertsOnly, setShowRedAlertsOnly] = useState(false);
  const countExceededInterval = () => data.filter(isRedAlert).length;
  const [scriptData, setScriptData] = useState({}); // Add state for script_data
  const isEditing = (record) => record.ID === editingKey;
  const [logFile, setLogFile] = useState(''); // State for log file
  const [missingScripts, setMissingScripts] = useState([]); // State for missing_in_db
  const [showMissingScripts, setShowMissingScripts] = useState(false); // Toggle visibility
  const [runningScripts, setRunningScripts] = useState([]);
const [resourceUsage, setResourceUsage] = useState({});
const [isRunningScriptsVisible, setIsRunningScriptsVisible] = useState(false);
const [loadingRunningScripts, setLoadingRunningScripts] = useState(false);
const [filteredData, setFilteredData] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchText, setSearchText] = useState(''); // State to store the search text



const fetchRunningScripts = async () => {
  setLoadingRunningScripts(true);
  try {
    const response = await api.post('/get_runningscripts'); // Fetch data
    console.log("response running scripts", response)
    const { running_scripts, resource_usage } = response.data;
    setRunningScripts(running_scripts);
    setResourceUsage(resource_usage);
  } catch (error) {
    message.error('Failed to fetch running scripts and resource usage.');
    console.error('API error:', error);
  } finally {
    setLoadingRunningScripts(false);
  }
};

useEffect(() => {
  if (isVisible) {
    fetchData(); // Fetch data for the table
    fetchRunningScripts(); // Fetch running scripts upon rendering
  }
}, [isVisible]);

const isScriptRunning = (scriptName) => {
  return runningScripts.some((script) => {
    // Normalize paths and extract script name
    const runningScriptName = script.Script.split('/').pop(); // Extract the file name
    return runningScriptName === scriptName; // Compare file names
  });
};

const stopScript = async (pid) => {
  try {
    await api.post('/scripts', { action: 'stop', PID: pid }); // Include the action parameter
    message.success(`Script with PID ${pid} stopped successfully!`);
    fetchRunningScripts(); // Refresh the running scripts list
  } catch (error) {
    message.error(`Failed to stop script with PID ${pid}.`);
    console.error('API error:', error);
  }
};

const [scriptStartLoading, setScriptStartLoading] = useState(false); // Spinner state for starting scripts

const startScript = async (scriptName) => {
  setScriptStartLoading(true); // Start the spinner for the "Start Script" button
  try {
    console.log(`Sending request to start script: ${scriptName}`);
    const response = await api.post('/scripts', { action: 'execute', script_name: scriptName });

    // Check response structure
    if (response.status === 200 && response.data) {
      const { message: backendMessage, pid } = response.data || {}; // Safely access properties
      if (backendMessage) {
        message.success(backendMessage); // Use the backend message
      } else {
        throw new Error('Backend did not return a success message.');
      }
      fetchRunningScripts(); // Refresh running scripts
    } else {
      throw new Error(response.data?.error || 'Unknown error occurred'); // Handle unexpected responses
    }
  } catch (error) {
    console.error('Error while starting script:', error);
    message.error(`Failed to start script "${scriptName}": ${error.message}`);
  } finally {
    setScriptStartLoading(false); // Stop the spinner
  }
};








const runningScriptsColumns = [
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Popconfirm
        title={`Are you sure you want to stop the script with PID ${record.PID}?`}
        onConfirm={() => stopScript(record.PID)} // Execute the stop action on confirmation
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="danger"
          size="small"
          style={{
            fontSize: '10px',
          }}
        >
          Stop Script
        </Button>
      </Popconfirm>
    ),
  },
  
  {
    title: 'PID',
    dataIndex: 'PID',
    key: 'PID',
  },
  {
    title: 'User',
    dataIndex: 'User',
    key: 'User',
  },
  {
    title: 'Script',
    dataIndex: 'Script',
    key: 'Script',
  },
  {
    title: 'Command',
    dataIndex: 'Command',
    key: 'Command',
    render: (text) => (
      <Tooltip title={text}>
        <span style={{ fontSize: '10px', textDecoration: 'underline', color: '#1890ff' }}>
          {text.length > 30 ? `${text.slice(0, 30)}...` : text}
        </span>
      </Tooltip>
    ),
  },
  {
    title: 'CPU Usage (%)',
    dataIndex: 'CPU Usage (%)',
    key: 'CPU Usage (%)',
  },
  {
    title: 'Memory Usage (MB)',
    dataIndex: 'Memory Usage (MB)',
    key: 'Memory Usage (MB)',
  },
];


  const handleDelete = async (record) => {
    try {
      await api.delete('/script_reporting', { data: { ID: record.ID } }); // DELETE request
      message.success(`Script "${record.script_name}" deleted successfully!`);
      fetchData(); // Refreshes table data
    } catch (error) {
      message.error('Failed to delete script.');
      console.error('API error:', error);
    }
  };
  
  

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success('Copied to clipboard!');
      })
      .catch(() => {
        message.error('Failed to copy!');
      });
  };

  const parseCronInterval = (cron) => {
    if (!cron) return null;
    const parts = cron.split(' ');
    if (parts.length < 5) return null;
  
    const [minute, hour, day, month, dayOfWeek] = parts;
  
    // Daily interval
    if (minute !== '*' && hour !== '*' && day === '*' && month === '*' && dayOfWeek === '*') {
      return 24; // 24 hours
    }
  
    // Weekly interval (runs on a specific day of the week)
    if (dayOfWeek !== '*' && day === '*' && month === '*') {
      return 7 * 24; // 7 days * 24 hours = 168 hours
    }
  
    // Default: return null if not recognized
    return null;
  };
  

  const handleSearch = (value) => {
    setSearchText(value); // Track search input
  };
  


  const handleSelect = (value) => {
    const selected = data.filter((item) => item.script_name === value);
    setFilteredData(selected); // Update filtered data with selected item
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get('/script_reporting');
      console.log("Response from script reporting", response);
  
      const { database_results, script_data } = response.data;
  
      setData(database_results); // Set table data
      setFilteredData(database_results); // Initialize filtered data
      setSearchOptions(
        database_results.map((item) => ({
          value: item.script_name, // Use script name for suggestions
        }))
      );
      setScriptData(script_data || {}); // Populate scriptData with script_data
    } catch (error) {
      message.error('Failed to fetch script reporting data.');
      console.error('API error:', error);
    } finally {
      setLoading(false);
    }
  };
  
  

  

  useEffect(() => {
    if (isVisible) {
      fetchData(); // Fetch data whenever the modal becomes visible
    }
  }, [isVisible]);

  const edit = (record) => {
    setEditingKey(record.ID);
  };

  const save = async (id, newInterval) => {
    try {
      await api.put('/script_reporting', { ID: id, interval: newInterval });
      message.success('Interval updated successfully!');
      fetchData(); // Refresh data after update
    } catch (error) {
      message.error('Failed to update interval.');
      console.error('API error:', error);
    } finally {
      setEditingKey('');
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const getRowStyle = (record) => {
    const currentTime = Date.now();
    const recordTime = record.epoch * 1000; // Convert epoch to milliseconds
    const intervalInMillis = record.interval * 60 * 60 * 1000; // Convert interval to milliseconds

    if (currentTime - recordTime > intervalInMillis) {
      return {
        border: '1px solid red',
        padding: '4px',
        borderRadius: '4px',
      };
    }

    return {};
  };

  const isRedAlert = (record) => {
    const currentTime = Date.now();
    const recordTime = record.epoch * 1000; // Convert epoch to milliseconds
    const intervalInMillis = record.interval * 60 * 60 * 1000; // Convert interval to milliseconds
    return currentTime - recordTime > intervalInMillis;
  };

  const removePrefixAndSuffix = (scriptName) => {
    return scriptName.replace(/^(DR_|DC_|DS_)/, '').replace(/\.py$/, '');
  };

  const finalFilteredData = (showRedAlertsOnly ? data.filter(isRedAlert) : data).filter((item) =>
  item.script_name.toLowerCase().includes(searchText.toLowerCase())
);

  
  // const filteredData = showRedAlertsOnly ? data.filter(isRedAlert) : data;

  const columns = [
    
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
      sorter: (a, b) => a.ID - b.ID,
      render: (text) => (
        <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px' }}>{text}</span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        const isRunning = isScriptRunning(record.script_name); // Check if the script is running
        return (
          <Popconfirm
            title={`Are you sure you want to start "${record.script_name}"?`}
            onConfirm={() => startScript(record.script_name)}
            okText="Yes"
            cancelText="No"
            disabled={isRunning} // Disable Popconfirm if running
          >
            <Button
              type="primary"
              size="small"
              disabled={isRunning || scriptStartLoading} // Disable button if running or loading
              style={{ fontSize: '10px' }}
            >
              {isRunning ? 'Running' : 'Start Script'}
            </Button>
          </Popconfirm>
        );
      },
    },
    

    {
      title: 'Script Name',
      dataIndex: 'script_name',
      key: 'script_name',
      sorter: (a, b) => a.script_name.localeCompare(b.script_name),
      render: (_, record) => {
        const copyToClipboard = (text) => {
          navigator.clipboard
            .writeText(text)
            .then(() => {
              message.success('Copied to clipboard!');
            })
            .catch(() => {
              message.error('Failed to copy!');
            });
        };
    
        const isRed = isRedAlert(record);
        const isRunning = isScriptRunning(record.script_name);
    
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isRunning && (
              <span
                style={{
                  height: '10px',
                  width: '10px',
                  backgroundColor: 'red',
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '8px',
                }}
              ></span>
            )}
            <span
              style={{
                fontFamily: 'Arial, sans-serif',
                fontSize: '10px',
                fontWeight: isRed ? 'bold' : 'normal',
                color: isRed ? 'red' : 'inherit',
              }}
            >
              {record.script_name}
            </span>
            <CopyOutlined
              onClick={() => copyToClipboard(record.script_name)}
              style={{
                marginLeft: 8,
                cursor: 'pointer',
                color: '#1890ff', // Optional: color to make it stand out
              }}
              title="Copy to clipboard"
            />
          </div>
        );
      },
    },
    
    
    
    {
      title: 'Name',
      key: 'name',
      sorter: (a, b) => {
        const nameA = removePrefixAndSuffix(a.script_name).toLowerCase();
        const nameB = removePrefixAndSuffix(b.script_name).toLowerCase();
        return nameA.localeCompare(nameB);
      },
      render: (_, record) => (
        <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px', display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '8px' }}>{removePrefixAndSuffix(record.script_name)}</span>
          <Popconfirm
  title="Are you sure you want to delete this script from the script reporting table?"
  onConfirm={() => handleDelete(record)} // Executes delete logic
  okText="Yes"
  cancelText="No"
>
  <Button
    type="text"
    icon={<DeleteOutlined style={{ color: 'red' }} />}
    size="small"
  />
</Popconfirm>

        </div>
      ),
    },
    {
      title: 'Cron Schedule',
      key: 'cron_schedule',
      sorter: (a, b) => {
        const cronA = scriptData[a.script_name] || '';
        const cronB = scriptData[b.script_name] || '';
        return cronA.localeCompare(cronB);
      },
      render: (_, record) => {
        const cronSchedule = scriptData[record.script_name] || '-';
        return (
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px' }}>
            {cronSchedule}
          </div>
        );
      },
    },
    
    
    {
      title: 'Interval',
      dataIndex: 'interval',
      key: 'interval',
      sorter: (a, b) => a.interval - b.interval,
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        const cronSchedule = scriptData[record.script_name] || '';
        const expectedInterval = parseCronInterval(cronSchedule);
        const isMismatch = expectedInterval && record.interval !== expectedInterval;
    
        return editable ? (
          <InputNumber
            min={0}
            defaultValue={record.interval}
            onBlur={(e) => save(record.ID, e.target.value)}
            style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px' }}
          />
        ) : (
          <span
            onClick={() => edit(record)}
            style={{
              cursor: 'pointer',
              color: isMismatch ? 'red' : 'blue',
              fontFamily: 'Arial, sans-serif',
              fontSize: '10px',
            }}
          >
            {record.interval || '-'}
            {isMismatch && <span style={{ color: 'red', marginLeft: '4px' }}>?</span>}
          </span>
        );
      },
    },
    
    {
      title: 'Duration (minutes)',
      dataIndex: 'duration',
      key: 'duration',
      sorter: (a, b) => a.duration - b.duration,
      render: (text) => (
        <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px' }}>{text}</span>
      ),
    },
    {
      title: 'Row Report',
      dataIndex: 'row_report',
      key: 'row_report',
      sorter: (a, b) => a.row_report - b.row_report,
      render: (text) => (
        <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px' }}>{text}</span>
      ),
    },
    {
      title: 'Essential Results',
      dataIndex: 'essential_results',
      key: 'essential_results',
      sorter: (a, b) => (a.essential_results || '').localeCompare(b.essential_results || ''),
      render: (text) => {
        if (text) {
          return (
            <Tooltip title={text}>
              <span
                style={{
                  color: '#1890ff',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontFamily: 'Arial, sans-serif',
                  fontSize: '10px',
                }}
              >
                Tooltip
              </span>
            </Tooltip>
          );
        }
        return (
          <span
            style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: '10px',
            }}
          >
            -
          </span>
        );
      },
    },
    {
      title: 'Epoch',
      dataIndex: 'epoch',
      key: 'epoch',
      sorter: (a, b) => a.epoch - b.epoch,
      render: (text) => (
        <span
          style={{
            fontFamily: 'Arial, sans-serif',
            fontSize: '10px',
            whiteSpace: 'nowrap', // Ensures data stays in one line
          }}
        >
          {new Date(text * 1000).toLocaleString()}
        </span>
      ),
    },
  ];

  return (
    <Modal
  title={
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Script Reporting ({countExceededInterval()} of scripts exceeded interval)</span>
        <div>
          <Switch
            checked={showRedAlertsOnly}
            onChange={setShowRedAlertsOnly}
            checkedChildren="Red Alerts On"
            unCheckedChildren="Red Alerts Off"
          />
        </div>
      </div>

      <div style={{ marginTop: '12px' }}>
  <Button
    type="link"
    onClick={() => {
      setIsRunningScriptsVisible(!isRunningScriptsVisible);
      if (!isRunningScriptsVisible) fetchRunningScripts();
    }}
  >
    {isRunningScriptsVisible ? 'Hide Running Scripts and Resource Usage' : 'Show Running Scripts and Resource Usage'}
  </Button>
  {isRunningScriptsVisible && (
    <div style={{ marginTop: '8px' }}>
      {loadingRunningScripts ? (
        <p>Loading...</p>
      ) : (
        <>
          <h4>Running Scripts</h4>
          <Table
  dataSource={runningScripts}
  columns={runningScriptsColumns}
  rowKey="PID"
  pagination={false}
  size="small"
/>


          <h4>Resource Usage</h4>
          <p style={{ fontSize: '10px' }}>
            CPU Usage: {resourceUsage['CPU Usage'] || '-'}%, Memory Usage: {resourceUsage['Memory Usage'] || '-'}%
          </p>
        </>
      )}
    </div>
  )}
</div>


      {/* Log File Section */}
      {logFile && (
        <div style={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
          <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px' }}>
            Logfile: {logFile}
          </span>
          <CopyOutlined
            onClick={() => copyToClipboard(logFile)}
            style={{
              marginLeft: '8px',
              cursor: 'pointer',
              color: '#1890ff',
            }}
            title="Copy log file path"
          />
        </div>
      )}
      {/* Missing Scripts Section */}
      {missingScripts.length > 0 && (
        <div style={{ marginTop: '8px', fontFamily: 'Arial, sans-serif', fontSize: '12px' }}>
          <span
            onClick={() => setShowMissingScripts(!showMissingScripts)}
            style={{
              color: '#1890ff',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Scripts not in reporting database
          </span>
          {showMissingScripts && (
            <ul style={{ marginTop: '4px', paddingLeft: '20px' }}>
              {missingScripts.map((script, index) => (
                <li key={index} style={{ fontSize: '10px' }}>{script}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  }
  visible={isVisible}
  onCancel={onClose}
  footer={[
    <Button key="refresh" onClick={() => {
      fetchData(); // Refresh table data
      fetchRunningScripts(); // Refresh running scripts and resource usage
    }} loading={loading}>
      Refresh
    </Button>,
    
    <Button key="close" onClick={onClose}>
      Close
    </Button>,
  ]}
  width={1500}
>
<div style={{ marginBottom: '16px' }}>
        <AutoComplete
          style={{ width: 300 }}
          options={searchOptions}
          placeholder="Search script by name"
          onSearch={handleSearch} // Update suggestions while typing
          onSelect={handleSelect} // Filter table when a suggestion is selected
          allowClear
        />
      </div>
  <Table
  dataSource={finalFilteredData}

    // dataSource={filteredData}
    columns={columns}
    rowKey="ID"
    loading={loading}
    pagination={{ pageSize: 20 }}
    rowClassName={() => 'table-low-row'}
  />
</Modal>

  );
};

export default ScriptReporting;
