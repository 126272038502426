//alertClauseService.js

import { message } from 'antd';
import api from './api';  // Reuse your existing Axios instance

// Export an alert (POST)
export const copyAlert = async (alertData) => {
  try {
    const payload = { ...alertData, copy_request: true }; // Add copy_request flag
    console.log("Exporting alert data with copy request:", payload);
    const response = await api.post("/market_alert", payload);
    message.success("Alert copied successfully!");
    return response.data;
  } catch (error) {
    message.error("Failed to copy alert.");
    console.error("Error copying alert:", error);
    throw error;
  }
};



// Fetch all alerts or one on id (GET)
export const fetchAlerts = async (id = null) => {
  try {
    const response = await api.get("/market_alert", { params: id ? { id } : {} });
    if (id) {
      message.success(`Alert with ID ${id} fetched successfully!`);
      return response.data; // Return a single alert record
    } else {
      message.success("All alerts fetched successfully!");
      return response.data; // Return an array of all alerts
    }
  } catch (error) {
    if (id) {
      message.error(`Error fetching alert with ID ${id}.`);
      console.error(`Error fetching alert with ID ${id}:`, error);
    } else {
      message.error("Error fetching alerts.");
      console.error("Error fetching alerts:", error);
    }
    throw error;
  }
};


// Delete an alert (DELETE)
export const deleteAlert = async (alertId) => {
  try {
    await api.delete("/market_alert", { data: { id: alertId } });
    message.success("Alert deleted successfully!");
  } catch (error) {
    message.error("Failed to delete alert.");
    console.error("Error deleting alert:", error);
    throw error;
  }
};

// Update an alert (PUT)
export const updateAlert = async (updatedAlertData) => {
  const formattedData = { ...updatedAlertData, id: updatedAlertData.ID }; // Use `id` key as expected by backend

  // console.log("AlertData in updateAlert ", formattedData)

  try {
    await api.put("/market_alert", formattedData);
    message.success("Alert updated successfully!");
  } catch (error) {
    message.error("Failed to update alert.");
    console.error("Error updating alert:", error);
    throw error;
  }
};

// Function to fetch all clauses (GET)
export const fetchClauses = async () => {
  try {
    const response = await api.get('/alert_clause_definition');
    message.success('Clauses fetched successfully!');
    return response.data;
  } catch (error) {
    message.error('Error fetching clauses.');
    console.error('Error fetching clauses:', error);
    throw error;
  }
};

// Function to add a new clause (POST)
export const addClause = async (clauseData) => {
  try {
    const response = await api.post('/alert_clause_definition', clauseData);
    message.success('Clause added successfully!');
    return response.data;
  } catch (error) {
    message.error('Error adding clause.');
    console.error('Error adding clause:', error);
    throw error;
  }
};

// Function to update an existing clause (PUT)
export const updateClause = async (id, clauseData) => {
  try {
    const response = await api.put(`/alert_clause_definition/${id}`, clauseData);
    message.success('Clause updated successfully!');
    return response.data;
  } catch (error) {
    message.error('Error updating clause.');
    console.error(`Error updating clause with ID ${id}:`, error);
    throw error;
  }
};

// Function to delete a clause (DELETE)
export const deleteClause = async (id) => {
  try {
    const response = await api.delete(`/alert_clause_definition/${id}`);
    message.success('Clause deleted successfully!');
    return response.data;
  } catch (error) {
    message.error('Error deleting clause.');
    console.error(`Error deleting clause with ID ${id}:`, error);
    throw error;
  }
};

// Function to fetch distinct values for a specific field from a specific table
export const fetchTargetFieldValues = async (table, field) => {
  try {
    const response = await api.get(`/field_values`, {
      params: {
        table: table,
        field: field
      }
    });
    message.success('Field values fetched successfully!');
    // console.log("Field values ", response)
    return response.data;
  } catch (error) {
    message.error('Error fetching target field values.');
    console.error('Error fetching target field values:', error);
    throw error;
  }
};

// Function to test the clause (POST)
export const testClause = async (clauseData) => {
  try {
    const response = await api.post('/test_clause', clauseData);
    console.log("ClauseData in alertClauseService ", clauseData)
    console.log("response on clause test in alertClauseservice ", response)
    message.success('Clause tested successfully!');
    return response.data;
  } catch (error) {
    message.error('Error testing clause.');
    console.error('Error testing clause:', error);
    throw error;
  }
};

// Function to test the Alert (POST)
export const testAlert = async (alert_ID) => {
  try {
    // Ensure `alert_ID` is passed in the expected structure
    const response = await api.post('/test_alert', { Alert_ID: alert_ID }, {  // Send as `{ Alert_ID: <value> }`
      headers: {
        'Content-Type': 'application/json' // Ensure content type is set to JSON
      }
    });
    message.success('Alert tested successfully!');
    return response.data;
  } catch (error) {
    message.error('Error testing alert.');
    console.error('Error testing alert:', error);
    throw error;
  }
};


export const createAlert = async (newAlertData) => {
  try {
    console.log("newAlertData in CreateAlert", newAlertData);
    const response = await api.post("/market_alert", newAlertData);
    console.log("response from CreateAlert", response);

    // Merge returned ID with original data
    const savedAlert = { ...newAlertData, ID: response.data.ID };

    message.success("Alert created successfully!");
    return savedAlert; // Return the complete alert object
  } catch (error) {
    message.error("Failed to create alert.");
    console.error("Error creating alert:", error);
    throw error;
  }
};


// Fetch detailed alert records by user and alert parameters (GET)
export const fetchDetailedAlertRecords = async (
  userid,
  alertSubtypeIdentifier,
  alertType,
  alertSubtype,
  targetColumn
) => {
  try {
    const response = await api.get('/get_alerts_from_pf_alert_archive_normalized_for_effectiveness_calculations', {
      params: {
        userid,
        alert_subtype_identifier: alertSubtypeIdentifier,
        alert_type: alertType,
        alert_subtype: alertSubtype,
        target_column: targetColumn, // Pass the target column
      },
    });
    message.success('Detailed alert records fetched successfully!');
    return response.data;
  } catch (error) {
    message.error('Error fetching detailed alert records.');
    console.error('Error fetching detailed alert records:', error);
    throw error;
  }
};

// Fetch alert effectiveness data (GET)
export const fetchAlertEffectiveness = async () => {
  try {
    const response = await api.get("/get_alert_effectiveness");
    message.success("Alert effectiveness data fetched successfully!");
    return response.data; // Return the effectiveness data
  } catch (error) {
    message.error("Failed to fetch alert effectiveness data.");
    console.error("Error fetching alert effectiveness data:", error);
    throw error;
  }
};

export const getAlertEffectivenessData = (effectivenessData) => {
  const aeFields = Object.keys(effectivenessData)
    .filter((key) => key.startsWith('AE_') && effectivenessData[key] !== null)
    .reduce(
      (acc, key) => {
        const nKey = `n_${key}`;
        const value = parseFloat(effectivenessData[key]).toFixed(2);
        const count = effectivenessData[nKey] ?? 'null';
        const formatted = `${key}: ${value}% (${count})`;

        if (key.includes('abs')) {
          acc.abs.push({ key, value: parseFloat(value), formatted });
        } else if (key.includes('P')) {
          acc.P.push({ key, value: parseFloat(value), formatted });
        }

        return acc;
      },
      { abs: [], P: [] }
    );

  const order = ['1w', '1m', '6m', '1y'];
  const sortedAbs = aeFields.abs.sort(
    (a, b) => order.indexOf(a.key.split('_').pop()) - order.indexOf(b.key.split('_').pop())
  );
  const sortedP = aeFields.P.sort(
    (a, b) => order.indexOf(a.key.split('_').pop()) - order.indexOf(b.key.split('_').pop())
  );

  const renderFields = (fields, positiveColor, negativeColor) =>
    fields.map((field) => {
      const match = field.formatted.match(/([\w_]+): ([\d.-]+)% \(([\w\d-]+)\)/);
      if (!match) {
        return <div key={field.key}>{field.formatted}</div>;
      }
      const [_, key, value, count] = match;
      const valueColor = parseFloat(value) > 0 ? positiveColor : parseFloat(value) < 0 ? negativeColor : 'inherit';
      const countDisplay = count === 'null' ? (
        <span style={{ color: 'gray', fontStyle: 'italic' }}>null</span>
      ) : (
        count
      );
      return (
        <div
          key={field.key}
          style={{
            fontSize: '12px',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          {key}:{' '}
          <span style={{ color: valueColor, fontWeight: 'bold' }}>{value}%</span> ({countDisplay})
        </div>
      );
    });

  const hasSharepriceData = sortedAbs.length > 0;
  const hasPortfolioData = sortedP.length > 0;

  return {
    hasSharepriceData,
    hasPortfolioData,
    sortedAbs: renderFields(sortedAbs, 'green', 'red'),
    sortedP: renderFields(sortedP, 'red', 'green'),
  };
};

export const renderAlertEffectiveness = (alertEffectiveness, alertID) => {
  
  if (!Array.isArray(alertEffectiveness) || alertEffectiveness.length === 0) {
    return <span>No data available</span>;
  }
  const effectivenessData = alertEffectiveness.find(
    (eff) => eff.alert_subtype_identifier === alertID
  );

  if (!effectivenessData) {
    return <span>No data available</span>;
  }

  const { hasSharepriceData, hasPortfolioData, sortedAbs, sortedP } = getAlertEffectivenessData(effectivenessData);

  if (!hasSharepriceData && !hasPortfolioData) {
    return <span>No effectiveness data yet available</span>;
  }

  return (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {hasSharepriceData && (
        <>
          <div>
            <strong>Shareprice Eff</strong>
          </div>
          {sortedAbs}
        </>
      )}
      {hasPortfolioData && (
        <>
          <div style={{ marginTop: '8px' }}>
            <strong>Portfolio Eff</strong>
          </div>
          {sortedP}
        </>
      )}
    </div>
  );
};
