import React from "react";
import api from '../functions/api';
import { useSelector, useDispatch } from "react-redux";
import { Button, MenuItem, Select, CircularProgress, Grid } from "@mui/material";
import getUserId from '../functions/getUserId';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableSortLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getPortfolioData } from '../redux/reducers/portfolioReducer';

import AddPortfolioModal from "./AddPortfolioModal";


const Portfolio_Datamanagement = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  const portfolioData = useSelector((state) => state.portfolio?.data?.portfolio || []);
  const categoryOptions = useSelector((state) => state.portfolio?.data?.category_options || []);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [editData, setEditData] = React.useState({});
  const [refreshEnabled, setRefreshEnabled] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const assetTypeOptions = useSelector((state) => state.portfolio?.data?.assettypes || []);


  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [newItem, setNewItem] = React.useState({
    Ticker: "",
    Company: "",
    AssetType: "",
    base_currency: "",
    Value_eur: "",
  });
  

const handleDeleteRow = async (rowId) => {
    const confirmed = window.confirm("Are you sure you want to delete this item?");
    if (!confirmed) return;
  
    setLoading(true);
    try {
      const userId = getUserId();
      const response = await api.post("/update_portfolio_data", {
        userId,
        delete_ids: [rowId],
      });
      if (response.status === 200) {
        alert("Item deleted.");
        setRefreshEnabled(true);
      } else {
        alert("Error deleting item.");
      }
    } catch (err) {
      console.error("Delete failed", err);
      alert("Error during delete.");
    } finally {
      setLoading(false);
    }
  };
  

const handleAddFieldChange = (field, value) => {
  setNewItem((prev) => ({ ...prev, [field]: value }));
};

const handleAddNewItem = async () => {
  const requiredFields = ["Ticker", "Company", "AssetType", "base_currency", "Value_eur"];

//   const requiredFields = ["Ticker", "Company", "AssetType", "base_currency"];
  const isValid = requiredFields.every((field) => newItem[field]?.trim());

  if (!isValid) {
    alert("Please fill in all required fields.");
    return;
  }

  setLoading(true);
  try {
    const userId = getUserId();
    const response = await api.post("/update_portfolio_data", {
      userId,
      new_items: [newItem],
    });
    if (response.status === 200) {
      alert("New item added.");
      setAddModalOpen(false);
      setNewItem({ Ticker: "", Company: "", AssetType: "", base_currency: "" });
      setRefreshEnabled(true);
    } else {
      alert("Error adding new item.");
    }
  } catch (err) {
    console.error("Add failed", err);
    alert("Error during addition.");
  } finally {
    setLoading(false);
  }
};

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(columnId);
  };

  const handleEditChange = (id, field, value) => {
    setEditData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleSaveChanges = async (rowId) => {
    const updates = editData[rowId] ? [{ id: parseInt(rowId), fields: editData[rowId] }] : [];
    if (updates.length === 0) return;

    setLoading(true);
    try {
      const userId = getUserId();
      const response = await api.post("/update_portfolio_data", { userId, updates });
      if (response.status === 200) {
        alert("Changes saved successfully.");
        setEditData((prev) => {
          const newEditData = { ...prev };
          delete newEditData[rowId];
          return newEditData;
        });
        setRefreshEnabled(true);
      } else {
        alert("Error saving changes.");
      }
    } catch (err) {
      console.error("Save failed", err);
      alert("Error during save.");
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    if (!token) return;

    setLoading(true);
    try {
      await dispatch(getPortfolioData(token));
      setRefreshEnabled(false);
    } catch (err) {
      console.error("Refresh failed", err);
    } finally {
      setLoading(false);
    }
  };

  const sortedData = React.useMemo(() => {
    if (!orderBy) return portfolioData;
    return [...portfolioData].sort((a, b) => {
      const aValue = a[orderBy] || "";
      const bValue = b[orderBy] || "";
      return typeof aValue === "string"
        ? order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
        : order === "asc" ? aValue - bValue : bValue - aValue;
    });
  }, [portfolioData, order, orderBy]);

  const columns = [
    { id: "Ticker", label: "Ticker" },
    { id: "Company", label: "Company" },
    { id: "AssetType", label: "Asset Type" },
    { id: "Category", label: "Category" },
    { id: "account", label: "Account" },
    { id: "base_currency", label: "Base Currency" },
    { id: "currency", label: "Currency" },
    { id: "exchange", label: "Exchange" },
    { id: "industry", label: "Industry" },
    { id: "sector", label: "Sector" },
    { id: "country", label: "Country" }
  ];

  const tableStyles = {
    fontSize: "0.8rem",
    lineHeight: "1.2",
    padding: "4px",
    spacing: "4px",
  };

  const isEditable = (row, columnId) => {
    if (row.adaptable === 1) return true;
    const val = row[columnId];
    return !val || ["N/A", "Undefined", "undefined", "UNDEFINED"].includes(val);
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <h3>Portfolio Data Management</h3>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}> {/* Take full width to allow scrolling */}

            <Button
              variant="contained"
              size="small"
              onClick={handleRefresh}
              disabled={!refreshEnabled || loading}
              style={{ marginBottom: '10px' }}
            >
              {loading ? <CircularProgress size={20} /> : "Refresh Portfolio Data"}
            </Button>

            <Button
              variant="contained"
              size="small"
              onClick={() => setAddModalOpen(true)}
              style={{ marginBottom: '10px', marginLeft: '10px' }}
            >
              Add Portfolio Item
            </Button>

            <AddPortfolioModal
              open={addModalOpen}
              onClose={() => setAddModalOpen(false)}
              loading={loading}
              onAdd={async (formData, resetForm) => {
                const requiredFields = ["Ticker", "Company", "AssetType", "base_currency", "Value_eur"];
                const isValid = requiredFields.every((field) => formData[field]?.trim());
                if (!isValid) {
                  alert("Please fill in all required fields.");
                  return;
                }

                setLoading(true);
                try {
                  const userId = getUserId();
                  const response = await api.post("/update_portfolio_data", {
                    userId,
                    new_items: [formData],
                  });
                  if (response.status === 200) {
                    alert("New item added.");
                    setAddModalOpen(false);
                    setRefreshEnabled(true);
                    resetForm();
                  } else {
                    alert("Error adding new item.");
                  }
                } catch (err) {
                  console.error("Add failed", err);
                  alert("Error during addition.");
                } finally {
                  setLoading(false);
                }
              }}
              assetTypeOptions={assetTypeOptions}
            />

          <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>

            {/* <TableContainer component={Paper}> */}
            <Table
  size="small"
  style={{ borderSpacing: tableStyles.spacing }}
  sx={{ minWidth: 1200 }} // adjust this value as needed
>

              {/* <Table size="small" style={{ borderSpacing: tableStyles.spacing }}> */}
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{
                          fontSize: tableStyles.fontSize,
                          padding: tableStyles.padding,
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell /> {/* Action column */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row) => (
                    <TableRow key={row.id} sx={{ backgroundColor: row.adaptable ? "#e0f7fa" : "inherit" }}>
                      {columns.map((column) => {
                        const value = editData[row.id]?.[column.id] ?? row[column.id];
                        const editable = isEditable(row, column.id);
                        const highlight = !row[column.id] || ["N/A", "Undefined", "undefined", "UNDEFINED"].includes(row[column.id]);

                        return (
                          <TableCell
                            key={column.id}
                            style={{
                              fontSize: tableStyles.fontSize,
                              padding: tableStyles.padding,
                              lineHeight: tableStyles.lineHeight,
                              backgroundColor: highlight ? "#ffebee" : "inherit",
                            }}
                          >
                            {editable ? (
                              column.id === "Category" ? (
                                <Select
                                  variant="standard"
                                  fullWidth
                                  value={value || ""}
                                  onChange={(e) => handleEditChange(row.id, column.id, e.target.value)}
                                  style={{ fontSize: tableStyles.fontSize, padding: 0 }}
                                >
                                  {categoryOptions.map(([label, id]) => (
                                    <MenuItem key={id} value={label}>{label}</MenuItem>
                                  ))}
                                </Select>
                              ) : column.id === "AssetType" ? (
                                <Select
                                  variant="standard"
                                  fullWidth
                                  value={value || ""}
                                  onChange={(e) => handleEditChange(row.id, column.id, e.target.value)}
                                  style={{ fontSize: tableStyles.fontSize, padding: 0 }}
                                >
                                  {assetTypeOptions.map(({ assettype }) => (
                                    <MenuItem key={assettype} value={assettype}>{assettype}</MenuItem>
                                  ))}
                                </Select>
                              ) : (
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  value={value || ""}
                                  onChange={(e) => handleEditChange(row.id, column.id, e.target.value)}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      fontSize: tableStyles.fontSize,
                                      padding: 0,
                                    },
                                  }}
                                />
                              )
                            ) : (
                              value || "N/A"
                            )}
                          </TableCell>
                        );
                      })}
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {editData[row.id] && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleSaveChanges(row.id)}
                            disabled={loading}
                            style={{ marginRight: '5px' }}
                          >
                            {loading ? <CircularProgress size={20} /> : "Save"}
                          </Button>
                        )}
                        {row.adaptable === 1 && (
                          <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={() => handleDeleteRow(row.id)}
                            disabled={loading}
                          >
                            🗑
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
  
};

export default Portfolio_Datamanagement;
