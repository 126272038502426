import React from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';

const PieChartComponent = ({
  data,
  title,
  width = "100%",
  height = 230,
  outerRadius = 80,
  titleFontSize = '16px', // Default title font size
  labelFontSize = '12px', // Default label font size
  colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'], // Default colors
}) => {
  // Function to get the color based on index
  const getColor = (index) => colors[index % colors.length];

  console.log("data", data);

  // Simplified label rendering
  const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {
    const RADIAN = Math.PI / 180;
    const radius = 10 + innerRadius + (outerRadius - innerRadius) / 2; // Adjusted radius
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text
        x={x}
        y={y}
        fill="black"
        fontSize={labelFontSize}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${value.toFixed(2)}%`}
      </text>
    );
  };
  
  <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
  
  // const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  //   const RADIAN = Math.PI / 180;
  //   // Adjust this radius to move labels closer or further away from the pie chart
  //   const radius = 10 + innerRadius + (outerRadius - innerRadius) / 2; // Adjusted radius
  //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //   const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
  //   return (
  //     <text
  //       x={x}
  //       y={y}
  //       fill="black"
  //       fontSize={labelFontSize}
  //       textAnchor={x > cx ? 'start' : 'end'}
  //       dominantBaseline="central"
  //     >
  //       {`${(percent * 100).toFixed(0)}%`}
  //     </text>
  //   );
  // };
  

  return (
    <div>
      <h2 style={{ textAlign: 'center', fontSize: titleFontSize }}>{title}</h2>
      <ResponsiveContainer width={width} height={height}>
        <PieChart>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={outerRadius}
            labelLine={false} // Disable the label lines
            label={renderCustomLabel} // Use custom label rendering
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getColor(index)} />
            ))}
          </Pie>
          <Tooltip formatter={(value) => `${value}%`} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartComponent;

