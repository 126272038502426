import React, { useState, useEffect } from 'react';
import { Modal, Radio, message, Button, Typography, Divider } from 'antd';
import api from '../functions/api';
import { useDispatch } from 'react-redux';
import { updateWidgetContent } from '../redux/actions';
import SearchBox2 from '../functions/Search_Generic.js';

const { Title, Text } = Typography;

const WidgetController = ({ userId, widgetNumber, onContentSelected, existingContentId, onUpdateWidgetContent }) => {
  const dispatch = useDispatch();
  const [contentOptions, setContentOptions] = useState([]);
  const [selectedContentId, setSelectedContentId] = useState(existingContentId);
  const [tickerValue, setTickerValue] = useState('');
  const [isTickerSelected, setIsTickerSelected] = useState(false);

  const onTickerNewsSelected = async (selectedTicker) => {
    setTickerValue(selectedTicker);
    const payload = {
      userid: userId,
      widget_number: widgetNumber,
      content_type: 'table',
      content_subtype: 'tickernews',
      ticker: selectedTicker
    };

    try {
      const response = await api.post('/widget_content_management', JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        setIsTickerSelected(true);
        setSelectedContentId(response.data.widgetcontentid);
        message.success('Tickernews content set successfully!');
      } else {
        message.error('An error occurred while setting the Tickernews content.');
      }
    } catch (error) {
      console.error('Error posting widget content:', error);
      message.error('An error occurred while posting the widget content.');
    }
  };

  const onTickerSelected = async (selectedTicker) => {
    setTickerValue(selectedTicker);
    const payload = {
      userid: userId,
      widget_number: widgetNumber,
      content_type: 'graph',
      ticker: selectedTicker
    };

    try {
      const response = await api.post('/widget_content_management', JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        setIsTickerSelected(true);
        setSelectedContentId(response.data.widgetcontentid);
        message.success('Graph content set successfully!');
      } else {
        message.error('An error occurred while setting the graph content.');
      }
    } catch (error) {
      console.error('Error posting widget content:', error);
      message.error('An error occurred while posting the widget content.');
    }
  };

  useEffect(() => {
    // console.log("selectedContentId updated to", selectedContentId);
  }, [selectedContentId]);

  const handleClear = async () => {
    const payload = { userid: userId, widget_number: widgetNumber, widgetcontentid: null };
  
    try {
      const response = await api.post('/widget_content_management', JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      if (response.status === 200) {
        dispatch(updateWidgetContent(userId, widgetNumber, null));
        onContentSelected(null);
        setContentOptions([]);
        message.success('Widget cleared successfully!', 1.5);
      } else {
        console.error('Backend returned an error:', response);
      }
    } catch (error) {
      console.error('Error clearing widget content:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post('/widget_content_management', JSON.stringify({}), {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        const nonGraphOptions = response.data.filter(option => option.type !== 'graph');
        
        const formattedData = nonGraphOptions.map(option => ({
          ...option,
          added_by: option.added_by === null ? 'null' : option.added_by.toString()
        }));
        setContentOptions(formattedData);
      } catch (error) {
        console.error('Error fetching widget content:', error);
      }
    };
  
    fetchData();
  }, [userId, widgetNumber]);

  const stringUserId = userId?.toString();
  const selfAddedOptions = contentOptions.filter(option => option.added_by === stringUserId);
  const otherOptions = contentOptions.filter(option => option.added_by !== stringUserId && option.type !== 'widget_personal');
  const relatedToYourPortfolioOptions = contentOptions.filter(option => option.type === 'widget_personal');

  const handleSelection = (e) => {
    setSelectedContentId(e.target.value);
  };

  const handleOk = async () => {
    let payload;
    if (selectedContentId === 'clear') {
      payload = { userid: userId, widget_number: widgetNumber, widgetcontentid: null };
    } else {
      payload = { userid: userId, widget_number: widgetNumber, widgetcontentid: selectedContentId };
    }

    try {
      const response = await api.post('/widget_content_management', JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        dispatch(updateWidgetContent(userId, widgetNumber, selectedContentId));
        onContentSelected(selectedContentId);
        setContentOptions([]);
        message.success('Widget updated successfully!', 1.5);
      } else {
        console.error('Backend returned an error:', response);
      }
    } catch (error) {
      console.error('Error updating widget content:', error);
    }
  };

  return (
    <Modal
      title="Select Content for Widget"
      visible={true}
      onOk={handleOk}
      onCancel={() => onContentSelected(existingContentId)}
      width="80%"
      footer={[
        <Button key="back" onClick={() => onContentSelected(existingContentId)}>
          Cancel
        </Button>,
        <Button key="clear" type="default" onClick={handleClear}>
          Clear Widget
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Save Changes
        </Button>,
      ]}
    >
      <div>
        {relatedToYourPortfolioOptions.length > 0 && (
          <>
            <Title level={5}>Related to your own portfolio:</Title>
            <Radio.Group onChange={handleSelection} value={selectedContentId}>
              {relatedToYourPortfolioOptions.map((option) => (
                <Radio key={option.ID} value={option.ID}>{option.description}</Radio>
              ))}
            </Radio.Group>
            <Divider />
          </>
        )}
      </div>

      <div>
        <Title level={5}>Created by yourself:</Title>
        <Radio.Group onChange={handleSelection} value={selectedContentId}>
          {selfAddedOptions.length > 0 && selfAddedOptions.map((option) => (
            <Radio key={option.ID} value={option.ID}>
              {option.description}
            </Radio>
          ))}
        </Radio.Group>
        <Divider />
      </div>

      {otherOptions.length > 0 && (
        <div>
          <Title level={5}>Other Options:</Title>
          <Radio.Group onChange={handleSelection} value={selectedContentId}>
            {otherOptions.map((option) => (
              <Radio key={option.ID} value={option.ID}>
                {option.description}
              </Radio>
            ))}
          </Radio.Group>
          <Divider />
        </div>
      )}

      <div>
        <Title level={5}>Choose graph:</Title>
        <SearchBox2 onSelectTicker={onTickerSelected} />
        <Divider />
      </div>

      <div>
        <Title level={5}>Choose latest company news:</Title>
        <SearchBox2 onSelectTicker={onTickerNewsSelected} />
      </div>
    </Modal>
  );
};

export default WidgetController;








