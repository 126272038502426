import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { List, ListItem, ListItemText, Divider, Box, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import api from '../functions/api';

import ReactMarkdown from 'react-markdown';

const VectorsTable = () => {
  const vectors = useSelector(state => state.portfolio?.data?.vectors);
  // console.log("vectors", vectors);
  const [selectedVector, setSelectedVector] = useState(null);
  const [analysisData, setAnalysisData] = useState(null);
  const [open, setOpen] = useState(false);

  if (!vectors || vectors.length === 0) {
    return <div>No data available</div>;
  }

  const getRowColor = (value) => {
    if (typeof value === 'string') {
      if (value.includes('No Panic') || value.includes('Stable')|| value.includes('Bullish')
        || value.includes('Strong') || value.includes('Strengthening') || value.includes('Market Has Strong Support')
      
      ) return '#DFF2BF'; // Green

    

      if (value.includes('Weakening') || value.includes('Moderate Drop Risk')|| value.includes('Caution')
        || value.includes('No CLear') || value.includes('Inconclusive') || value.includes('Equilibrium')
      ) return '#FEEFB3'; // Orange


      if (value.includes('Bearish') || value.includes('Weak') || value.includes('Panic behaviour')
        || value.includes('Stress')|| value.includes('Deep Drop Risk')
      ) return '#FF4C4C'; // Red
;
    }
    return 'transparent';
  };

  const handleRowClick = async (vectorName) => {
    setSelectedVector(vectorName);
    setOpen(true);

    try {
      const response = await api.post("/Market_analysis", { analysis_type: vectorName });
      setAnalysisData(response.data);
      // console.log("analysisData", response.data);
    } catch (error) {
      setAnalysisData({ error: "Failed to retrieve analysis" });
    }
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ width: 'fit-content', overflow: 'hidden',padding: '2px' }}>
        <Table size="small" aria-label="dense vectors table">
          <TableHead>
            <TableRow>
              <TableCell 
                sx={{ fontSize: '10px', fontWeight: 'bold', padding: '0.1px', cursor: 'pointer' }}
                onClick={() => handleRowClick('Market')}
              >
                Marketstatus
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vectors.map((vector) => (
              <TableRow 
                key={vector[0]} 
                sx={{ 
                  backgroundColor: getRowColor(vector[2]), 
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f5f5f5', // Change background color on hover
                  }
                }}
                onClick={() => handleRowClick(vector[1])}
              >
                <TableCell 
  sx={{ 
    fontSize: '10px', 
    padding: '0.2px', 
    whiteSpace: 'normal', 
    width: '100px', // Adjust width as needed
    minWidth: '100px', // Ensures a minimum width
    maxWidth: '200px' // Prevents excessive expansion
  }} 
  component="th" 
  scope="row"
>
  {vector[1]}
</TableCell>

                <TableCell sx={{ fontSize: '10px', padding: '0.2px', whiteSpace: 'normal' }} align="left">
                  {vector[2]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog 
  open={open} 
  onClose={() => setOpen(false)} 
  fullWidth 
  maxWidth="lg"
  sx={{ 
    "& .MuiDialog-paper": { 
      width: "90vw",  
      maxWidth: "1200px", 
      minWidth: "300px",
      overflowX: "hidden" // Ensure no horizontal scrolling
    }
  }}
>
  <DialogTitle>{selectedVector} Analysis</DialogTitle>

  <DialogContent 
    sx={{ 
      display: "flex", 
      flexDirection: "column", 
      overflowX: "hidden", 
      wordBreak: "break-word", 
      whiteSpace: "pre-wrap", 
      width: "100%",
      padding: "16px"
    }}
  >
    {analysisData ? (
      <Box sx={{ width: "100%" }}>
        {analysisData.purpose && (
          <>
            <Typography variant="h6" gutterBottom><strong>Purpose:</strong></Typography>
            <Box sx={{ 
              display: "block", 
              width: "100%", 
              maxWidth: "100%", 
              overflowWrap: "anywhere", 
              wordBreak: "break-word", // This ensures long words break
              whiteSpace: "pre-wrap",
              lineBreak: "anywhere" // Added for better support
            }}>
              <ReactMarkdown>{analysisData.purpose}</ReactMarkdown>
            </Box>
            <Divider sx={{ my: 2 }} />
          </>
        )}

        <Typography variant="h6" gutterBottom><strong>Result:</strong></Typography>
        <Box sx={{ 
          display: "block", 
          width: "100%", 
          maxWidth: "100%", 
          overflowWrap: "anywhere", 
          wordBreak: "break-word", 
          whiteSpace: "pre-wrap",
          lineBreak: "anywhere" 
        }}>
          <ReactMarkdown>{analysisData.result || "N/A"}</ReactMarkdown>
        </Box>

        <Divider sx={{ my: 2 }} />

        
      </Box>
    ) : (
      <Typography>Loading...</Typography>
    )}
  </DialogContent>
</Dialog>

    </>
  );
};

export default VectorsTable;
