import { useState, useEffect } from 'react';

function useLocalStorage(key, defaultValue) {
  // State to store our value
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        try {
          return JSON.parse(item); // ✅ Try parsing as JSON
        } catch {
          return item; // ✅ If parsing fails, return the string
        }
      }
      return defaultValue;
    } catch (error) {
      console.error(`Error loading key "${key}" from localStorage:`, error);
      return defaultValue;
    }
  });
  
  // const [storedValue, setStoredValue] = useState(() => {
  //   try {
  //     const item = window.localStorage.getItem(key);
  //     return item ? JSON.parse(item) : defaultValue;
  //   } catch (error) {
  //     console.log(error);
  //     return defaultValue;
  //   }
  // });

  useEffect(() => {
    setStoredValue(localStorage.getItem(key));
    const handleStorageChange = () => {
      setStoredValue(localStorage.getItem(key));
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  return [storedValue, setStoredValue];
}

export default useLocalStorage;
