/// Name: portfolioReducer.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import fetchPortfolioData from '../src/functions/fetch_storePortfolioData';
import fetchPortfolioData from '../../functions/fetch_storePortfolioData';

import fetchFavoritesData from '../../functions/fetch_storeFavoritesData';
import getUserId from '../../functions/getUserId';
import { fetchDataParameters } from '../../functions/getDataParameters';
import { fetchPortfolioESGData } from '../../functions/fetchPortfolio_ESG_data';
import { fetchFavoritesESGData } from '../../functions/fetchFavorites_ESG_data';

export const getPortfolioData = createAsyncThunk(
  'portfolio/getPortfolioData',
  async (token) => {
    const userId = getUserId();
    const data = await fetchPortfolioData(userId, token);
    return data;
  }
);

export const getFavoritesData = createAsyncThunk(
  'favorites/getFavoritesData',
  async (token) => {
    const userId = getUserId();
    const data = await fetchFavoritesData(userId, token);
    return data;
  }
);

export const getPortfolioESGData = createAsyncThunk(
  'portfolio/getPortfolioESGData',
  async (tickers) => {
    const data = await fetchPortfolioESGData(tickers);
    return data;
  }
);

export const getFavoritesESGData = createAsyncThunk(
  'favorites/getFavoritesESGData',
  async (tickers) => {
    const data = await fetchFavoritesESGData(tickers);
    return data;
  }
);

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState: {
    data: null,
    status: 'idle',
    error: null,
    parametersData: null,
    parametersStatus: 'idle',
    parametersError: null,
    esgData: null,
    esgStatus: 'idle',
    esgError: null,
    filteredTickers: null,
  },
  reducers: {
    setFilteredTickers: (state, action) => {
      state.filteredTickers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

    .addCase('UPDATE_SEENBY_STATUS', (state, action) => {
      // console.log("🟢 State at reducer start:", state);
      // console.log("🔵 Action payload:", action.payload);
      
      if (state.data && state.data.market_alerts) {
        // console.log("🟠 Updating seenby for alert ID:", action.payload.id, "with seenby:", action.payload.seenby);
        
        state.data = {
          ...state.data,
          market_alerts: state.data.market_alerts.map(alert =>
            alert.ID === action.payload.id
              ? { ...alert, seenby: action.payload.seenby } // Update the seenby field
              : alert
          ),
        };
      } else {
        // console.log("❌ State data or market_alerts is missing:", state.data);
      }
    })

      .addCase(getPortfolioData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPortfolioData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getPortfolioData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase('CLEAR_PORTFOLIO_DATA', (state) => {
        return {
          data: null,
          esgData: null,
          status: 'idle',
          error: null,
        };
      })
      .addCase(getDataParameters.pending, (state) => {
        state.parametersStatus = 'loading';
      })
      .addCase(getDataParameters.fulfilled, (state, action) => {
        state.parametersStatus = 'succeeded';
        state.parametersData = action.payload;
      })
      .addCase(getDataParameters.rejected, (state, action) => {
        state.parametersStatus = 'failed';
        state.parametersError = action.error.message;
      })
      .addCase(getPortfolioESGData.pending, (state) => {
        state.esgStatus = 'loading';
      })
      .addCase(getPortfolioESGData.fulfilled, (state, action) => {
        state.esgStatus = 'succeeded';
        state.esgData = action.payload;
      })
      .addCase(getPortfolioESGData.rejected, (state, action) => {
        state.esgStatus = 'failed';
        state.esgError = action.error.message;
      })
      .addCase('UPDATE_SEEN_STATUS', (state, action) => {
        if (state.data && state.data.pf_alert) {
          state.data.pf_alert = state.data.pf_alert.map(alert =>
            alert.ID === action.payload.id ? { ...alert, seen: action.payload.seen } : alert
          );
        }
      });
  },
});

export const { setFilteredTickers } = portfolioSlice.actions;

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: {
    data: null,
    status: 'idle',
    error: null,
    parametersData: null,
    parametersStatus: 'idle',
    parametersError: null,
    esgData: null,
    esgStatus: 'idle',
    esgError: null,
    filteredTickersF: null,
  },
  reducers: {
    setFilteredTickersF: (state, action) => {
      state.filteredTickersF = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase('UPDATE_SEEN_STATUS', (state, action) => {
      if (state.data && state.data.favorites_alert_data) {
        state.data.favorites_alert_data = state.data.favorites_alert_data.map(alert =>
          alert.ID === action.payload.id ? { ...alert, seen: action.payload.seen } : alert
        );
      }
    })

    .addCase('UPDATE_SEENBY_STATUS', (state, action) => {
      // console.log("🟢 State at reducer start:", state);
      // console.log("🔵 Action payload:", action.payload);
      
      if (state.data && state.data.market_alerts) {
        // console.log("🟠 Updating seenby for alert ID:", action.payload.id, "with seenby:", action.payload.seenby);
        
        state.data = {
          ...state.data,
          market_alerts: state.data.market_alerts.map(alert =>
            alert.ID === action.payload.id
              ? { ...alert, seenby: action.payload.seenby } // Update the seenby field
              : alert
          ),
        };
      } else {
        // console.log("❌ State data or market_alerts is missing:", state.data);
      }
    })
    
    

    
    
    
    
      .addCase('UPDATE_FAVORITES_AND_BENCHMARK', (state, action) => {
        const payload = action.payload;
        const { updatedFavorites, updatedBenchmarkPerformance } = payload;

        // Update favorites data
        if (Array.isArray(updatedFavorites)) {
          state.data.data = updatedFavorites;
        }

        // Update benchmark_performance
        if (Array.isArray(payload.benchmark_performance)) {
          state.benchmark_performance = payload.benchmark_performance;
        }

        // Update benchmark_performance
        if (Array.isArray(updatedBenchmarkPerformance)) {
          state.benchmark_performance = updatedBenchmarkPerformance;
        }
      })
      .addCase('UPDATE_FAVORITES', (state, action) => {
        const payload = action.payload;

        // Update state.data.data
        if (Array.isArray(payload.data)) {
          state.data.data = state.data.data.map(fav => {
            const newFav = payload.data.find(item => item.Ticker === fav.Ticker);
            return newFav ? { ...fav, ...newFav } : fav;
          });
        }

        // Update benchmark_performance
        if (Array.isArray(payload.benchmark_performance)) {
          state.benchmark_performance = payload.benchmark_performance;
        }

        // Update metadata
        if (payload.metadata) {
          state.metadata = payload.metadata;
        }

        // Update performance_data
        if (Array.isArray(payload.performance_data)) {
          state.performance_data = payload.performance_data;
        }
      })
      .addCase(getFavoritesData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFavoritesData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getFavoritesData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase('CLEAR_FAVORITES_DATA', (state) => {
        return {
          data: null,
          esgData: null,
          status: 'idle',
          error: null,
        };
      })
      .addCase(getDataParameters.pending, (state) => {
        state.parametersStatus = 'loading';
      })
      .addCase(getDataParameters.fulfilled, (state, action) => {
        state.parametersStatus = 'succeeded';
        state.parametersData = action.payload;
      })
      .addCase(getDataParameters.rejected, (state, action) => {
        state.parametersStatus = 'failed';
        state.parametersError = action.error.message;
      })
      .addCase(getFavoritesESGData.pending, (state) => {
        state.esgStatus = 'loading';
      })
      .addCase(getFavoritesESGData.fulfilled, (state, action) => {
        state.esgStatus = 'succeeded';
        state.esgData = action.payload;
      })
      .addCase(getFavoritesESGData.rejected, (state, action) => {
        state.esgStatus = 'failed';
        state.esgError = action.error.message;
      });
  },
});

export const { setFilteredTickersF } = favoritesSlice.actions;

export const getDataParameters = createAsyncThunk(
  'portfolio/getDataParameters',
  async () => {
    const data = await fetchDataParameters();
    return data;
  }
);

export const favoritesReducer = favoritesSlice.reducer;

export default portfolioSlice.reducer;
