// ///TickerSearch.jsx

import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import api from "../functions/api";
import AddRequest from "./AddRequest";

const TickerSearch = ({ onTickerSelect }) => {
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isAddRequestVisible, setIsAddRequestVisible] = useState(false);

  // console.log("TickerSearch component rendered");

  const handleSearch = async (value) => {
    setSearchValue(value);
    if (!value) return;

    const response = await api.get(`/search_suggestions?query=${value}`);
    const autoCompleteOptions = response.data.map((suggestion) => ({
      label: suggestion,
    }));
    setOptions(autoCompleteOptions);
  };

  const handleSelect = (event, value) => {
    if (value) {
      const ticker = value.label.split(" - ")[0];
      onTickerSelect(ticker);
      setSearchValue("");
    }
  };

  const handleBlur = () => {
    const found = options.some((option) => option.label === searchValue);
    if (!found && searchValue.trim() !== "") {
      setIsAddRequestVisible(true);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleBlur();
    }
  };

  const handleClearMessage = () => {
    setIsAddRequestVisible(false);
    setSearchValue("");
  };

  return (
    <>
      <Autocomplete
        freeSolo
        disableClearable
        options={options}
        inputValue={searchValue}
        onInputChange={(event, newInputValue) => handleSearch(newInputValue)}
        onChange={handleSelect}
        filterOptions={(x) => x} // disable default filtering
        sx={{
          minWidth: 180,
          "& .MuiOutlinedInput-root": {
            padding: "4px 8px",
            fontSize: "13px",
            height: 32,
            borderRadius: 2,
            boxShadow: 8,
            backgroundColor: "background.paper",
            border: "1px solid",
            borderColor: "divider",
            "&:hover": {
              backgroundColor: "action.hover",
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="ticker or stockname"
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            size="small"
          />
        )}
      />

      {isAddRequestVisible && (
        <AddRequest
          searchValue={searchValue}
          handleClearMessage={handleClearMessage}
          visible={isAddRequestVisible}
        />
      )}
    </>
  );
};

export default TickerSearch;


// import React, { useState } from 'react';
// import { AutoComplete, Input } from 'antd';
// import api from '../functions/api';
// import AddRequest from './AddRequest';

// const TickerSearch = ({ onTickerSelect }) => {
//   const [searchValue, setSearchValue] = useState('');
//   const [options, setOptions] = useState([]);
//   const [isAddRequestVisible, setIsAddRequestVisible] = useState(false);

//   const handleSearch = async (value) => {
//     setSearchValue(value);
//     if (!value) return;

//     // Now using api instead of axios
//     const response = await api.get(`/search_suggestions?query=${value}`);
//     const autoCompleteOptions = response.data.map((suggestion) => ({
//       value: suggestion,
//       style: { width: 'auto', minWidth: '200px' }
//     }));
//     setOptions(autoCompleteOptions);
//   };

//   const handleSelect = (value) => {
//     const isSelectedValueInOptions = options.some(option => option.value === value);
//     if (isSelectedValueInOptions) {
//       const ticker = value.split(' - ')[0];
//       onTickerSelect(ticker);
//       setSearchValue('');  // Reset the search field after selection
//     }
//   };

//   const handleBlur = () => {
//     const isSelectedValueInOptions = options.some(option => option.value === searchValue);
//     if (!isSelectedValueInOptions) {
//       setIsAddRequestVisible(true); // Open the AddRequest modal
//     }
//   };

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter') {
//       handleBlur();
//     }
//   };

//   const handleClearMessage = () => {
//     setIsAddRequestVisible(false); // Close the AddRequest modal
//     setSearchValue('');  // Reset the search field
//   };

//   return (
//     <>
//       <AutoComplete
//         options={options}
//         onSelect={handleSelect}
//         onSearch={handleSearch}
//         onBlur={handleBlur}
//         onKeyDown={handleKeyDown} // add this line
//         value={searchValue}
//         dropdownStyle={{ minWidth: '200px' }}
//       >
//         <Input placeholder="ticker or stockname" />
//       </AutoComplete>
//       {isAddRequestVisible && (
//         <AddRequest 
//           searchValue={searchValue} 
//           handleClearMessage={handleClearMessage}
//           visible={isAddRequestVisible}
//         />
//       )}
//     </>
//   );
// };

// export default TickerSearch;
