import React, { useState, useEffect } from 'react';
import api from '../functions/api';
import { Modal, Table, Button, notification, Input, AutoComplete } from 'antd';
import AddTicker from './AddTicker'; // Import your AddTicker component
import epochToDate from '../functions/epochdate'; // Adjust the path as necessary

const TickerMaintenance = ({ isVisible, setIsVisible }) => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAddTickerModalVisible, setIsAddTickerModalVisible] = useState(false); // State to control AddTicker modal visibility
  const [isTableVisible, setIsTableVisible] = useState(true);
  const [tnuRecords, setTnuRecords] = useState([]);
  const [isTnuTableVisible, setIsTnuTableVisible] = useState(true);
  const [isFinanceModalVisible, setIsFinanceModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [isAddTickerFromFinanceVisible, setIsAddTickerFromFinanceVisible] = useState(false);
  const [prefilledTicker, setPrefilledTicker] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filteredTnuRecords, setFilteredTnuRecords] = useState([]);

  const onIgnoreClick = async (record) => {
    try {
      const response = await api.post('/process_Addrequests', { requestid: record.requestid });
      notification.success({
        message: 'Success',
        description: response.data.message || 'The record has been successfully updated.',
      });
      setRecords(prevRecords => prevRecords.filter(r => r.requestid !== record.requestid));
    } catch (error) {
      console.error('Failed to process the request:', error);
      notification.error({
        message: 'Error',
        description: 'There was an error processing the request.',
      });
    }
  };

  useEffect(() => {
    if (isVisible) { // Only fetch records when modal is opened
      const fetchData = async () => {
        setLoading(true);
        try {
          // Fetch AddRequest records
          const addRequestResponse = await api.post('/get_Addrequests', {}, {
            headers: { "Content-Type": "application/json" }
          });
          setRecords(addRequestResponse.data);

          // Fetch ticker_not_updated records
          const tnuResponse = await api.post('/tnu', {}, {
            headers: { "Content-Type": "application/json" }
          });
          setTnuRecords(tnuResponse.data);
          setFilteredTnuRecords(tnuResponse.data);

        } catch (error) {
          console.error('Failed to fetch records:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [isVisible]);

  const flipSkipStatus = async (record) => {
    try {
      const updatedStatus = record.skip === 1 ? 0 : 1;
      await api.post('/tnu', { ticker: record.ticker, skip: updatedStatus });

      // Update local state
      setTnuRecords((prevRecords) =>
        prevRecords.map((r) =>
          r.ticker === record.ticker ? { ...r, skip: updatedStatus } : r
        )
      );

      notification.success({
        message: 'Success',
        description: `Skip status updated to ${updatedStatus === 1 ? 'True' : 'False'}.`,
      });
    } catch (error) {
      console.error('Failed to update skip status:', error);
      notification.error({
        message: 'Error',
        description: 'There was an error updating the skip status.',
      });
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const filteredRecords = tnuRecords.filter(record =>
      record.ticker.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTnuRecords(filteredRecords);
  };

  const columns = [
    {
      title: 'User ID',
      dataIndex: 'userid',
      key: 'userid',
      sorter: (a, b) => (a.userid || '').localeCompare(b.userid || ''),
    },
    {
      title: 'Company Name',
      dataIndex: 'companyname',
      key: 'companyname',
      sorter: (a, b) => (a.companyname || '').localeCompare(b.companyname || ''),
    },
    {
      title: 'Information',
      dataIndex: 'info',
      key: 'info',
      sorter: (a, b) => (a.info || '').localeCompare(b.info || ''),
    },
    {
      title: 'New Company',
      dataIndex: 'Newcompany',
      key: 'Newcompany',
      sorter: (a, b) => (a.Newcompany || '').localeCompare(b.Newcompany || ''),
    },
    {
      title: 'Requested Ticker To Add',
      dataIndex: 'requested_ticker_to_add',
      key: 'requested_ticker_to_add',
      sorter: (a, b) => (a.requested_ticker_to_add || '').localeCompare(b.requested_ticker_to_add || ''),
    },
    {
      title: 'Process',
      key: 'process',
      render: (text, record) => (
        <div>
          <Button 
            onClick={() => onIgnoreClick(record)}
            type="default"
            style={{ marginBottom: "8px" }}
          >
            Ignore
          </Button>
          <br />
          <Button 
            onClick={() => setIsAddTickerModalVisible(true)}
            type="primary"
          >
            Add
          </Button>
        </div>
      ),
    },
  ];

  const tnuColumns = [
    { 
      title: 'Ticker', 
      dataIndex: 'ticker', 
      key: 'ticker',
      sorter: (a, b) => (a.ticker || '').localeCompare(b.ticker || ''),
    },
    { 
      title: 'Company Name', 
      dataIndex: 'company_name', 
      key: 'company_name',
      sorter: (a, b) => (a.company_name || '').localeCompare(b.company_name || ''),
    },
    { 
      title: 'Problem', 
      dataIndex: 'error', 
      key: 'error',
      sorter: (a, b) => (a.error || '').localeCompare(b.error || ''),
    },
    { 
      title: 'Comment', 
      dataIndex: 'comment', 
      key: 'comment',
      sorter: (a, b) => (a.comment || '').localeCompare(b.comment || ''),
    },
    {
      title: 'Initiation',
      dataIndex: 'epoch_initial',
      key: 'epoch_initial',
      render: (epoch) => {
        const date = new Date(epoch * 1000); // Convert epoch to milliseconds
        const day = String(date.getDate()).padStart(2, '0'); // Day (dd)
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month (mm)
        const year = String(date.getFullYear()).slice(-2); // Year (yy)
        return `${day}-${month}-${year}`; // Format: dd-mm-yy
      },
      sorter: (a, b) => a.epoch_initial - b.epoch_initial,
    },
    {
      title: 'Confirmation',
      dataIndex: 'epoch_reconfirmed',
      key: 'epoch_reconfirmed',
      render: (epoch) => {
        const date = new Date(epoch * 1000); // Convert epoch to milliseconds
        const day = String(date.getDate()).padStart(2, '0'); // Day (dd)
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month (mm)
        const year = String(date.getFullYear()).slice(-2); // Year (yy)
        return `${day}-${month}-${year}`; // Format: dd-mm-yy
      },
      sorter: (a, b) => a.epoch_reconfirmed - b.epoch_reconfirmed,
    },
    {
      title: 'Alternative Tickers',
      dataIndex: 'alternative_ticker',
      key: 'alternative_ticker',
      render: (tickers) =>
        tickers
          ? tickers.split(',').map((ticker, index) => (
              <a
                key={index}
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default anchor behavior
                  setSelectedTicker(ticker.trim());
                  setIsFinanceModalVisible(true);
                }}
                style={{ marginRight: '8px', cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
              >
                {ticker.trim()}
              </a>
            ))
          : 'N/A',
    },
    {
      title: 'Skip Status',
      dataIndex: 'skip',
      key: 'skip',
      render: (skip) => (skip === 1 ? 'True' : 'False'),
      sorter: (a, b) => a.skip - b.skip,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => flipSkipStatus(record)} type="primary">
          Flip Skip Status
        </Button>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Ticker Maintenance"
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={null}
        width={1000}
      >
        <Button type="link" onClick={() => setIsTableVisible(!isTableVisible)}>
          {isTableVisible ? "Hide Add Requests" : "Show Add Requests"}
        </Button>

        {isTableVisible && (
          <Table columns={columns} dataSource={records} loading={loading} rowKey="requestid" />
        )}

        <Button type="link" onClick={() => setIsTnuTableVisible(!isTnuTableVisible)}>
          {isTnuTableVisible ? "Hide Suspicious Tickers" : "Show Suspicious Tickers"}
        </Button>

        {isTnuTableVisible && (
          <>
            <AutoComplete
              style={{ width: 200, marginBottom: '10px' }}
              options={filteredTnuRecords.map(record => ({ value: record.ticker }))}
              onSearch={handleSearch}
              placeholder="Search Ticker"
            />
            <Table columns={tnuColumns} dataSource={filteredTnuRecords} loading={loading} rowKey="ticker" />
          </>
        )}
      </Modal>

      <Modal
        title="Add Ticker"
        visible={isAddTickerModalVisible}
        onCancel={() => setIsAddTickerModalVisible(false)}
        footer={null}
      >
        <AddTicker closeModal={() => setIsAddTickerModalVisible(false)} />
      </Modal>

      <Modal
        title={`Yahoo Finance - ${selectedTicker}`}
        visible={isFinanceModalVisible}
        onCancel={() => setIsFinanceModalVisible(false)}
        footer={[
          <Button
            key="addTicker"
            type="primary"
            onClick={() => {
              setPrefilledTicker(selectedTicker);
              setIsAddTickerFromFinanceVisible(true);
            }}
          >
            Add Ticker
          </Button>
        ]}
        width={900}
      >
        {selectedTicker && (
          <iframe
            src={`https://finance.yahoo.com/quote/${selectedTicker}`}
            width="100%"
            height="600px"
            style={{ border: "none" }}
          ></iframe>
        )}
      </Modal>
      <Modal
        title="Add Ticker"
        visible={isAddTickerFromFinanceVisible}
        onCancel={() => setIsAddTickerFromFinanceVisible(false)}
        footer={null}
      >
        <AddTicker
          closeModal={() => setIsAddTickerFromFinanceVisible(false)}
          prefilledTicker={prefilledTicker}
        />
      </Modal>
    </>
  );
};

export default TickerMaintenance;
