import React, { useMemo, useState } from "react"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from "recharts"
import { Box, Typography, Button, ButtonGroup } from "@mui/material"

const defaultPeriods = [
    { label: "1D", days: 1, trend: "mixed" },
    { label: "1W", days: 7, trend: "up" },
    { label: "1M", days: 30, trend: "up" },
    { label: "3M", days: 90, trend: "mixed" },
    { label: "1Y", days: 365, trend: "up" },
    { label: "5Y", days: 365 * 5, trend: "up" }
  ]
  

const generateMockData = (days, trend = 'up') => {
  const data = []
  const startValue = 100000
  let currentValue = startValue

  for (let i = 0; i < days; i++) {
    const date = new Date()
    date.setDate(date.getDate() - (days - i - 1))

    let change = 0
    if (trend === 'up') change = Math.random() * 1000 - 300
    else if (trend === 'down') change = Math.random() * 1000 - 700
    else change = Math.random() * 2000 - 1000

    currentValue += change

    data.push({
      date: date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
      value: currentValue,
      change
    })
  }

  return data
}

const StockChart = ({
    externalData,
    title = "",
    currency = "$",
    colorUp = "#4caf50",
    colorDown = "#f44336",
    showLegend = true,
    showToolbar = true,
    periods = defaultPeriods,
    height = 300,
    valueKey = "value",
    tooltipLabel = "Portfolio Value",
    onPeriodChange,
    yAxisFormat = "currency"  // NEW
  }) => {
  
    // console.log("externalData", externalData)

  const [activePeriod, setActivePeriod] = useState(1)

  const data = useMemo(() => {
    if (!externalData?.length) {
      return generateMockData(
        periods[activePeriod].days,
        periods[activePeriod].trend ?? "up"
      )
    }
  
    const targetDate = new Date()
    targetDate.setDate(targetDate.getDate() - periods[activePeriod].days)
  
    return externalData.filter(d => {
      const dataDate = new Date(d.date)
      return dataDate >= targetDate
    })
  }, [activePeriod, externalData, periods])
  
  

  const start = data[0]?.[valueKey] ?? 0
  const end = data[data.length - 1]?.[valueKey] ?? 0
  const valueChange = (typeof end === "number" && typeof start === "number") ? end - start : 0
  const percentChange = (typeof start === "number" && start !== 0) ? (valueChange / start) * 100 : 0
  const isPositive = valueChange >= 0

  const currencyMap = {
    "$": "USD",
    "€": "EUR",
    "£": "GBP",
    "¥": "JPY"
  };
  
  const formatCurrency = (val) => {
    if (yAxisFormat === "percentage") {
      return `${val.toFixed(2)}%`;
    }
  
    const currencyMap = {
      "$": "USD",
      "€": "EUR",
      "£": "GBP",
      "¥": "JPY"
    };
    const code = currencyMap[currency] || currency;
  
    return val.toLocaleString(undefined, {
      style: "currency",
      currency: code,
      minimumFractionDigits: 2
    });
  };
  
  

  return (
    <Box width="100%">
      {title && (
        <Typography variant="h6" mb={2} fontWeight="medium">
          {title}
        </Typography>
      )}

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box>
        <Typography variant="h5" fontWeight="bold">
  {formatCurrency(end)}
</Typography>
<Typography variant="body2" color={isPositive ? "success.main" : "error.main"}>
  {isPositive ? "+" : "-"}{formatCurrency(Math.abs(valueChange))} (
  {isPositive ? "+" : "-"}{Math.abs(percentChange).toFixed(2)}%)
</Typography>


        </Box>

        {showToolbar && (
          <ButtonGroup size="small" variant="outlined">
            {periods.map((period, index) => (
              <Button
                key={period.label}
                variant={activePeriod === index ? "contained" : "outlined"}
                onClick={() => {
                  setActivePeriod(index)
                  onPeriodChange?.(index)
                }}
              >
                {period.label}
              </Button>
            ))}
          </ButtonGroup>
        )}
      </Box>

      <Box height={height} width="100%">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={isPositive ? colorUp : colorDown} stopOpacity={0.8} />
                <stop offset="95%" stopColor={isPositive ? colorUp : colorDown} stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tick={{ fontSize: 12 }}
              tickFormatter={(value, index) => {
                if (data.length > 30 && index % Math.ceil(data.length / 6) !== 0) return ""
                return value
              }}
            />
            <YAxis
              tickFormatter={(val) =>
                yAxisFormat === "percentage"
                  ? `${val.toFixed(1)}%`
                  : `${currency}${(val / 1000).toFixed(0)}K`
              }
              
              domain={
                yAxisFormat === "percentage"
                  ? ['auto', 'auto']
                  : ['dataMin - 5000', 'dataMax + 5000']
              }
              
              tickLine={false}
              axisLine={false}
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              formatter={(val) =>
                yAxisFormat === "percentage"
                  ? [`${Number(val).toFixed(2)}%`, tooltipLabel]
                  : [`${currency}${Number(val).toLocaleString()}`, tooltipLabel]
              }
              
              labelFormatter={(label) => `Date: ${label}`}
            />
            <Area
              type="monotone"
              dataKey={valueKey}
              stroke={isPositive ? colorUp : colorDown}
              fillOpacity={1}
              fill="url(#colorValue)"
            />
            {showLegend && <Legend />}
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default StockChart
