// AddPortfolioModal.jsx is a component that will be used to add a new portfolio item. It will be a modal that will open when the user clicks on the Add Portfolio button. The modal will have input fields for the Ticker, Company, Asset Type, Base Currency, and Position Value (EUR) fields. The user can enter the details in these fields and click on the Add button to add the new portfolio item. The modal will also have a Cancel button to close the modal.
import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    CircularProgress,
    Select,
    MenuItem,
  } from "@mui/material";
  

const AddPortfolioModal = React.memo(({ open, onClose, onAdd, loading, assetTypeOptions }) => {

    const [formState, setFormState] = React.useState({
      Ticker: "",
      Company: "",
      AssetType: "",
      base_currency: "",
      Value_eur: "",
    });
  
    const handleChange = (field, value) => {
      setFormState((prev) => ({ ...prev, [field]: value }));
    };
  
    const handleSubmit = () => {
      onAdd(formState, () => {
        setFormState({
          Ticker: "",
          Company: "",
          AssetType: "",
          base_currency: "",
          Value_eur: "",
        });
      });
    };
  
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Add New Portfolio Item</DialogTitle>
        <DialogContent>
  {[
    { id: "Ticker", label: "Ticker" },
    { id: "Company", label: "Company" },
    { id: "base_currency", label: "Base Currency" },
    { id: "Value_eur", label: "Position Value (EUR)", type: "number" },
  ].map(({ id, label, type }) => (
    <TextField
      key={id}
      label={label}
      type={type || "text"}
      fullWidth
      margin="dense"
      value={formState[id]}
      onChange={(e) => handleChange(id, e.target.value)}
    />
  ))}

  <Select
    fullWidth
    variant="outlined"
    margin="dense"
    value={formState.AssetType}
    onChange={(e) => handleChange("AssetType", e.target.value)}
    displayEmpty
  >
    <MenuItem value="" disabled>
      Select Asset Type
    </MenuItem>
    {assetTypeOptions.map(({ assettype }) => (
      <MenuItem key={assettype} value={assettype}>
        {assettype}
      </MenuItem>
    ))}
  </Select>
</DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
  export default AddPortfolioModal;
