import React from "react";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'primary',
  })(({ theme, primary }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    textTransform: "none",
    transition: "all 0.2s",
    boxShadow: theme.shadows[1],
    fontSize: theme.typography.pxToRem(13),
    minWidth: 100,
    height: 32,
    padding: theme.spacing(0, 1.5),
    ...(primary
      ? {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            opacity: 0.5,
            cursor: "not-allowed",
          },
        }
      : {
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.divider}`,
            opacity: 0.5,
            cursor: "not-allowed",
          },
        }),
  }));
  

const NavButton = ({
  icon,
  label,
  primary = false,
  onClick,
  disabled = false,
  tooltip = null,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const button = (
    <StyledButton primary={primary ? 1 : 0} onClick={onClick} disabled={disabled}>
      {icon}
      {!isSmallScreen && <Box component="span">{label}</Box>}
    </StyledButton>
  );

  if (tooltip && !disabled) {
    return (
      <Tooltip title={tooltip} arrow enterDelay={1500} placement="bottom">
        <span>{button}</span>
      </Tooltip>
    );
  }

  return button;
};

export default NavButton;
