import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const WidgetWidgetView = ({ title, headers, parsedData, onClick, setSelectedTicker }) => {
  // Fetch Redux state
  const favoritesAlertData = useSelector(state => state.favorites?.data?.favorites_alert_data || []);
  const opportunitiesAlerts = useSelector(state => state.portfolio?.data?.opportunities_alerts || []);
  const pfDataAlerts = useSelector(state => state.portfolio?.data?.pf_alert || []);

  // Extract tickers from Redux state
  const alertTickers = new Set([
    ...favoritesAlertData.map(alert => alert.ticker?.toUpperCase()).filter(Boolean),
    ...opportunitiesAlerts.map(alert => alert.ticker?.toUpperCase()).filter(Boolean),
    ...pfDataAlerts.map(alert => (Array.isArray(alert) && alert.length > 2 && typeof alert[2] === 'string' ? alert[2].toUpperCase() : null)).filter(Boolean),
  ]);

  const alertDetailsMap = new Map([
    ...favoritesAlertData.map(alert => [alert.ticker?.toUpperCase(), `${alert.alert_type}:${alert.alert_subtype}`]),
    ...opportunitiesAlerts.map(alert => [alert.ticker?.toUpperCase(), `${alert.alert_type}:${alert.alert_subtype}`]),
    ...pfDataAlerts.map(alert => [
      (Array.isArray(alert) && alert.length > 2 && typeof alert[2] === 'string' ? alert[2].toUpperCase() : null),
      `${alert[4]}:${alert[5]}` // Adjusted based on pf_alert array structure
    ])
  ]);

  return (
    <Box onClick={onClick} sx={{ cursor: 'pointer', p: 1, bgcolor: '#f0f2f5', borderRadius: 1 }}>
      <Typography variant="subtitle2" sx={{ mb: 0.5, fontWeight: 'bold', fontSize: '0.85rem' }}>{title}</Typography>

      <TableContainer component={Paper} sx={{ maxHeight: 150 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headers.slice(0, 3).map((header) => (
                <TableCell key={header} sx={{ fontWeight: 'normal', fontSize: '0.55rem', py: 0.5, px: 1 }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {parsedData.slice(0, 5).map((row, index) => (
              <TableRow key={index} hover sx={{ height: '30px' }}>
                {headers.slice(0, 3).map((header) => {
                  const dataKey = Object.keys(row).find(key => key.toLowerCase() === header.toLowerCase());
                  const tickerValue = row[dataKey];
                  const isAlertedTicker = typeof tickerValue === 'string' && alertTickers.has(tickerValue.toUpperCase());

                  return (
                    <TableCell key={header} sx={{ fontSize: '0.45rem', py: 0.5, px: 0.5 }}>
                      {dataKey === 'ticker' && typeof tickerValue === 'string' ? (
                        <Typography
                          sx={{ color: '#1976d2', cursor: 'pointer', fontSize: '0.55rem', display: 'flex', alignItems: 'center' }}
                          onClick={() => setSelectedTicker(tickerValue)}
                        >
                          {alertDetailsMap.has(tickerValue.toUpperCase()) && (
                            <Tooltip title={alertDetailsMap.get(tickerValue.toUpperCase())} arrow>
                              <span style={{ width: 6, height: 6, backgroundColor: 'red', borderRadius: '50%', marginRight: 4, display: 'inline-block' }}></span>
                            </Tooltip>
                          )}
                          {tickerValue}
                        </Typography>
                      ) : (
                        row[dataKey]
                      )}
                    </TableCell>

                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default WidgetWidgetView;

// import React, { useEffect } from 'react';
// import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// const WidgetWidgetView = ({ title, headers, parsedData, onClick, setSelectedTicker }) => {
  
//   // ✅ Log all data when the title matches
//   useEffect(() => {
//     if (title === "your rating updates") {
//       // console.log("Logging all parsedData for:", title);
//       // console.log(parsedData);
//     }
//   }, [title, parsedData]); // ✅ Runs when title or parsedData changes

//   return (
//     <Box onClick={onClick} sx={{ cursor: 'pointer', p: 1, bgcolor: '#f0f2f5', borderRadius: 1 }}>
//       <Typography variant="subtitle2" sx={{ mb: 0.5, fontWeight: 'bold', fontSize: '0.85rem' }}>{title}</Typography>

//       <TableContainer component={Paper} sx={{ maxHeight: 150 }}>
//         <Table size="small">
//           <TableHead>
//             <TableRow>
//             {headers.slice(0, 3).map((header) => (
//   <TableCell key={header} sx={{ fontWeight: 'normal', fontSize: '0.55rem', py: 0.5, px: 1 }}>
//     {header}
//   </TableCell>
// ))}

//               {/* {headers.slice(0, 3).map((header) => (
//                 <TableCell key={header} sx={{ fontWeight: 'normal', fontSize: '0.55rem', py: 0.5, px: 1 }}>
//                   {header}
//                 </TableCell>
//               ))} */}
//             </TableRow>
//           </TableHead>

//           <TableBody>
//   {parsedData.slice(0, 5).map((row, index) => (
//     <TableRow key={index} hover sx={{ height: '30px' }}>
//       {headers.slice(0, 3).map((header) => {
//         const dataKey = Object.keys(row).find(key => key.toLowerCase() === header.toLowerCase()); 
//         return (
//           <TableCell key={header} sx={{ fontSize: '0.45rem', py: 0.5, px: .5 }}>
//             {dataKey === 'ticker' ? (
//               <Typography sx={{ color: '#1976d2', cursor: 'pointer', fontSize: '0.55rem' }}
//                 onClick={() => setSelectedTicker(row[dataKey])}>
//                 {row[dataKey]}  {/* ✅ Ensures correct case */}
//               </Typography>
//             ) : (
//               row[dataKey]  /* ✅ Ensures data is displayed */
//             )}
//           </TableCell>
//         );
//       })}
//     </TableRow>
//   ))}
// </TableBody>

//         </Table>
//       </TableContainer>
//     </Box>
//   );
// };

// export default WidgetWidgetView;
