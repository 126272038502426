// Description: This is the main component of the application that renders the homepage with various widgets and functionalities.
//Homepage.jsx

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {getDataParameters,} from '../redux/reducers/portfolioReducer';
import SearchBox from '../functions/Search';
import WidgetAreaController from '../functions/Widget_area_controller';
import PortfolioView from '../components/PortfolioView';
import FavoritesView from '../components/FavoritesView';
import Filter from '../components/Filter';
import ETF_screener from '../components/ETF_screener';
import Account from '../components/Account';
import Alerts from '../components/Alerts';
import ESGCheck from '../components/ESGCheck';
import AboutModal from '../components/AboutModal';
import Ask from '../components/Ask';
import InvestmentGoals from '../components/Investment_Goals';
import StockChart from '../components/Stockchart_MUI';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import api from '../functions/api';
import getUserId from '../functions/getUserId';
import User from './Users';
import Portfolio_Highlights from '../components/Portfolio_Highlights';
import { Paper, Box } from '@mui/material';

const Homepage = ({ showLoginModal, setShowLoginModal, setToken }) => {

// const Homepage = () => {

    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const demoMode = useSelector((state) => state.demoMode.isDemoMode);
    const dataParameters = useSelector((state) => state.portfolio.dataParameters);
    const portfolioTopline = useSelector((state) => state.portfolio.data?.portfolio_topline);
    const benchmarkPerformance = useSelector((state) => state.portfolio?.data?.benchmark_performance);
    const benchmarkData = useSelector((state) => state.portfolio?.parametersData?.b_dataset_json);
    const [chartData, setChartData] = useState([]);
    const userId = getUserId();
  
    const isPortfolioDataAvailable =
      userLoggedIn &&
      portfolioTopline?.length > 0 &&
      benchmarkPerformance?.length > 0 &&
      benchmarkData?.length > 0;
  
    useEffect(() => {
        if (!userLoggedIn) {
          setShowLoginModal(true);
        }
      }, [userLoggedIn]);
      

    useEffect(() => {
      if (!dataParameters) {
        dispatch(getDataParameters());
      }
    }, [dataParameters, dispatch]);
  
    const renderPortfolioButton = () => {
      if (userLoggedIn && isPortfolioDataAvailable) {
        return <PortfolioView disabled={!userLoggedIn} userId={userId} />;
      }
      return null;
    };

    useEffect(() => {
        // console.log('useeffect called');
        // console.log('userLoggedIn:', userLoggedIn);
        // console.log('userId:', userId);
        if (userLoggedIn && userId) {
          api.get('/stockchart_mui', {
            params: {
              code: 'portfolio_performance',
              userid: userId
            }
          })
          .then((res) => {
            if (res.data.status === 'success') {
                // console.log('Chart data:', res.data.data);
              setChartData(res.data.data);
            }
          })
          .catch((err) => {
            console.log(err);
            console.error('Failed to load chart data', err);
          });
        }
      }, [userLoggedIn, userId]);
      
  
    return (
      <div className="main-container">
        <div className="responsive-container">
          <h1>Investments Dashboard</h1>
          <div className="top-right-button">
            <AboutModal />
            <Ask disabled={!userLoggedIn} />
            {demoMode && <span style={{ color: 'red', fontSize: '10px', marginLeft: '10px' }}>Demo Mode</span>}
          </div>
          <div className="search-box-container">
            <SearchBox disabled={!userLoggedIn} />
            <div className="portfolio-button"><Filter disabled={!userLoggedIn} /></div>
            <div className="portfolio-button"><ETF_screener disabled={!userLoggedIn} /></div>
            <div className="portfolio-button"><FavoritesView disabled={!userLoggedIn} /></div>
            <div className="portfolio-button">{renderPortfolioButton()}</div>
            <div className="portfolio-button"><InvestmentGoals disabled={!userLoggedIn} /></div>
            <div className="portfolio-button"><Alerts disabled={!userLoggedIn} userId={userId} /></div>
            <div className="portfolio-button"><ESGCheck /></div>
            <div className="user-greeting-container"><Account /></div>
          </div>
  
          {userLoggedIn && (
            <div className="table-grid-search-container">
              {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '24px', marginTop: '40px', marginBottom: '40px', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start' }}> */}
              <Box
  display="flex"
  flexWrap="wrap"
  gap="24px"
  mt={5}
  mb={5}
  justifyContent="space-between"
  alignItems="stretch"  // << force children to same height
>
  <Paper
    elevation={2}
    sx={{
      flex: '1 1 600px',
      minWidth: '300px',
      borderRadius: '16px',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <StockChart
      title="Portfolio Performance"
      externalData={chartData}
      yAxisFormat="percentage"
      tooltipLabel="Portfolio Return"
    />
  </Paper>

  <Paper
    elevation={2}
    sx={{
      flex: '1 1 300px',
      minWidth: '260px',
      borderRadius: '16px',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Portfolio_Highlights />
  </Paper>
</Box>

{/* </div> */}


              <Accordion disableGutters sx={{ marginTop: '40px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight="bold">Investment Management Widgets</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <WidgetAreaController />
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>

        <User
  defaultTab="2"
  visible={showLoginModal}
  setVisible={setShowLoginModal}
  setToken={setToken}
/>


      </div>
    );
  };
  
  export default Homepage;
  