// //WidgetContentMgr
import React, { useState, useEffect } from 'react';
import api from '../functions/api';
import { message } from 'antd';
import WidgetTable from '../components/WidgetTable';
import WidgetGraph from '../components/WidgetGraph';
import WidgetPersonal from '../components/WidgetPersonal';
import VectorsTable from '../components/Vectors'; // Import Vectors component
import Portfolio_Highlights from '../components/Portfolio_Highlights'; // Import Portfolio_Highlights component

const WidgetContentMgr = ({ widgetcontentid }) => {
  const [content, setContent] = useState(null);

  const COMPONENT_MAP = {
    VectorsTable, Portfolio_Highlights,
    
    // Add more subtype components here as needed
  };

  useEffect(() => {
    if (widgetcontentid) {
      api.get(`/widget_title_delivery?widgetcontentid=${widgetcontentid}`)
        .then(response => {
          setContent(response.data); // Store title and type information from the backend
          // console.log("content in WidgetContentmgr ", response.data)
        })
        .catch(error => {
          message.error('Failed to fetch widget title.');
          console.error(error);
        });
    }
  }, [widgetcontentid]);

  const renderContentBasedOnType = () => {
    if (!content) {
      return <div>Loading title...</div>;
    }

    switch (content.type) {
      case 'table':
        return <WidgetTable title={content.title} widgetcontentid={widgetcontentid} />;
      case 'graph':
        return <WidgetGraph title={content.title} widgetcontentid={widgetcontentid} />;
      
      case 'widget_special':
          const SpecialComponent = content.subtype ? COMPONENT_MAP[content.subtype] : null;
          return SpecialComponent ? <SpecialComponent /> : <div>Unsupported widget_special subtype</div>;

  
      case 'widget_personal':
        return content.subtype ? (
          <WidgetPersonal subtype={content.subtype} title={content.title} />
        ) : (
          <div>Unsupported or incomplete widget_personal content.</div>
        );
      default:
        return <div>Unsupported content type</div>;
    }
  };

  return renderContentBasedOnType();
};

export default WidgetContentMgr;

