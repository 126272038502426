// Date: 03/20/2021
// Name: Risk_Category.jsx
// Description: Risk category distribution component

import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import { BarChart } from '@mui/x-charts';

const RiskCategory = ({ data }) => {
  if (!data) return null;

  const userId = Object.keys(data)[0];
  const riskDetails = data[userId];

  const knownRisks = Object.entries(riskDetails).filter(([key]) => key !== "unknown");
  const unknownRisk = riskDetails["unknown"];

  const categories = knownRisks.map(
    ([riskLevel, info]) => `Risk ${riskLevel}\n(${info.assettypes})`
  );
  const values = knownRisks.map(([, info]) => info.percent);

  return (
    <Card sx={{ maxWidth: 900, margin: "auto", mt: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Risk Category Distribution
        </Typography>

        <BarChart
  xAxis={[{
    scaleType: 'band',
    data: categories,
    label: 'Risk Categories',
    tickLabelStyle: {
      fontSize: 10,
      wordBreak: 'break-word',
    },
  }]}
  series={[{
    data: values,
    showValue: true,
    valueFormatter: (val) => `${val}%`,
  }]}
  width={800} // Chart width
  height={400} // Chart height
  sx={{ mx: "auto", mt: 20 }} // MUI spacing
  margin={{ top: 30, bottom: 60, left: 40, right: 20 }} // inner chart margin
  barLabel={(item) => `${item.value}%`}
  layout="vertical" // optional, switch to horizontal layout if needed
/>



        {unknownRisk && (
          <>
            <Typography variant="h6" sx={{ mt: 3 }}>
              Unknown Risk Exposure — {unknownRisk.percent}%
            </Typography>
            <Grid container spacing={1} sx={{ mt: 1 }}>
              {unknownRisk.records.map((item, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card variant="outlined" sx={{ p: 1 }}>
                    <Typography variant="body2">
                      <strong>Ticker:</strong> {item.ticker || "—"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Company:</strong> {item.company || "—"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Exposure:</strong> {item.exposure_perc}%
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default RiskCategory;
