// ✅ Display Widget Content in MUI Table
// ✅ Full Table in Modal
import React, { useState } from 'react';
import { Table as AntTable, Modal, Tag, Typography, message, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import ThreeTablesModal from "./SearchResultModal";
import SimpleExternalLinkNewWindow from '../functions/LinkFlex';
import WidgetWidgetView from './WidgetWidgetView';
import { Row, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import api from '../functions/api';

const { Title } = Typography;

const WidgetTable = ({ title, widgetcontentid }) => {
  const [isFullTableVisible, setIsFullTableVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [threeTablesModalVisible, setThreeTablesModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  
  // ✅ Extract alert tickers from Redux state
  const favoritesAlertData = useSelector(state => state.favorites?.data?.favorites_alert_data || []);
  const opportunitiesAlerts = useSelector(state => state.portfolio?.data?.opportunities_alerts || []);
  const pfDataAlerts = useSelector(state => state.portfolio?.data?.pf_alert || []);

  // ✅ Map tickers to alert details
  const alertDetailsMap = new Map([
    ...favoritesAlertData.map(alert => [alert.ticker?.toUpperCase(), `${alert.alert_type}:${alert.alert_subtype}`]),
    ...opportunitiesAlerts.map(alert => [alert.ticker?.toUpperCase(), `${alert.alert_type}:${alert.alert_subtype}`]),
    ...pfDataAlerts.map(alert => [
      (Array.isArray(alert) && alert.length > 2 && typeof alert[2] === 'string' ? alert[2].toUpperCase() : null),
      `${alert[4]}:${alert[5]}` // Adjusted for pf_alert format
    ])
  ]);


  // ✅ Get data from Redux instead of API
  const widgetPreload = useSelector(state => state.widget?.widgetPreload) || [];


  // ✅ Filter all records matching `widgetcontentid`
  const widgetData = widgetPreload.filter(wp => wp.widgetcontent_id === widgetcontentid);

  // ✅ Parse all `data` entries into objects
  const parsedData = widgetData.map(item => {
    try {
      return JSON.parse(item.data);
    } catch (error) {
      console.error("Error parsing widget data:", error);
      return {};
    }
  });

  // ✅ Extract headers in the correct sequence
  const headersArray = widgetData.length > 0
    ? Object.entries(JSON.parse(widgetData[0].headers_sequence))
        .sort((a, b) => a[1] - b[1])
        .map(entry => entry[0])
    : [];

  const headers = headersArray; // Make it the same as WidgetPersonal


  const toggleFullTableModal = () => {
    if (!isFullTableVisible) {
      loadData();  // Load data when opening modal
    }
    setIsFullTableVisible(!isFullTableVisible);
  };
  
  // const toggleFullTableModal = () => {
  //   setIsFullTableVisible(!isFullTableVisible);
  // };

  

  const loadData = async () => {
    // console.log("Calling API to load widget data...");
    setLoading(true);
    
    try {
      const response = await api.get('/widget_content_delivery', {
        params: { widgetcontentid }
      });
      
      // console.log("DATA BEING LOADED FOR THIS WIDGET:", response); // <-- Should appear if API returns data
  
      if (!response || !response.data) {
        console.error("API returned an invalid response:", response);
        message.error("API returned an unexpected response.");
        return;
      }
  
      const standardizedData = response.data.data.map(item => {
        const newItem = {};
        Object.keys(item).forEach(key => {
          newItem[key.toLowerCase()] = item[key];
        });
        return newItem;
      });
  
      setData(standardizedData);
    } catch (error) {
      console.error("API call failed:", error);
      message.error("Failed to fetch personal widget data.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      {/* ✅ Display Widget Content in MUI Table */}
      <WidgetWidgetView 
  title={title}
  headers={headersArray}
  parsedData={parsedData}
  onClick={toggleFullTableModal}
  setSelectedTicker={setSelectedTicker}
/>
      {/* ✅ Full Table in Modal */}
      <Modal
        title={<Title level={3}>{title}</Title>}
        visible={isFullTableVisible}
        onCancel={toggleFullTableModal}
        footer={null}
        width={1200}
        destroyOnClose
      >
        <AntTable
  dataSource={data}
  columns={headersArray.map(header => {
    const normalizedHeader = Object.keys(data[0] || {}).find(
      key => key.toLowerCase() === header.toLowerCase()
    ) || header;  // ✅ Dynamically match correct key case

    return {
      title: header,
      dataIndex: normalizedHeader,  // ✅ Ensures correct data mapping
      key: normalizedHeader,
      sorter: (a, b) => {
        if (a[normalizedHeader] && b[normalizedHeader]) {
          return typeof a[normalizedHeader] === 'number'
            ? a[normalizedHeader] - b[normalizedHeader]
            : a[normalizedHeader].toString().localeCompare(b[normalizedHeader].toString());
        }
        return 0;
      },
      render: (text, record) => {
        const value = record[normalizedHeader];

        if (header.toLowerCase() === 'ticker') {
          const tickerValue = value;
          const alertInfo = typeof tickerValue === 'string' ? alertDetailsMap.get(tickerValue.toUpperCase()) : null;

          return (
            <a
              onClick={() => {
                setSelectedTicker(tickerValue);
                setThreeTablesModalVisible(true);
              }}
              style={{ cursor: 'pointer', color: '#1890ff', display: 'flex', alignItems: 'center' }}
            >
              {alertInfo && (
                <Tooltip title={alertInfo} arrow>
                  <span style={{ width: 6, height: 6, backgroundColor: 'red', borderRadius: '50%', marginRight: 4, display: 'inline-block' }}></span>
                </Tooltip>
              )}
              <Tag color="blue">{text}</Tag>
            </a>
          );
        } 

        // ✅ Ensure numerical values are displayed properly
        if (typeof value === 'number') {
          return value.toFixed(2);
        }

        return value;
      }
    };
  })}
  pagination={{ pageSize: 10 }}
  rowKey={(record, index) => index}
  bordered
  size="middle"
/>

      </Modal>

      {threeTablesModalVisible && (
        <ThreeTablesModal
          tickerValue={selectedTicker}
          open={threeTablesModalVisible}
          onClose={() => setThreeTablesModalVisible(false)}
        />
      )}
    </>
  );
};

export default WidgetTable;
