/// SMAGraph.jsx

import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceDot,
} from 'recharts';
import '../SMAGraph.css';

const SMAGraph = ({ data, currency, transactions, ticker }) => {


     // Debugging: Log the transactions and data
  // console.log("Transactions:", transactions);
  // console.log("Graph Data:", data);


    // Formatter for the Y-axis
    const twoDecimalFormatter = (value) => {
      if (typeof value === 'number' && isFinite(value)) {
        return value.toFixed(2);
      }
      return '-';
    };
    
    // Formatter for the date on the X-axis
    const formatDate = (date) => {
        const newDate = new Date(date);
        const year = newDate.getFullYear().toString().slice(-2); // Get last two digits of the year
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Format as yy-mm-dd
    };
  
    // Custom style for the ticks
    const customTick = {
      fontSize: '10px',
      // Add other styling properties if necessary
    };

    // Ensure transactions is defined and is an array before mapping over it
    // Ensure transactions is defined and is an array before mapping over it
const transactionDots = Array.isArray(transactions)
? transactions.map((transaction, index) => {
    // Convert the transaction date to match the label format
    const transactionDate = new Date(transaction.date).toISOString().split('T')[0];

    // Set the color based on the transaction type
    const dotColor = transaction.type === 'S' || transaction.type === 'Sell' ? 'red' : 'blue';

    // const dotColor = transaction.type === 'S' ? 'red' : 'blue';

    return (
      <ReferenceDot 
        key={index} 
        x={transactionDate} 
        y={transaction.price} 
        r={4} 
        fill={dotColor} 
        stroke="none"
        // Here we add the payload for the tooltip
        payload={{ 
          type: transaction.type, 
          quantity: transaction.quantity,
          price: transaction.price,
          date: transactionDate
        }}
      />
    );
  })
: null;

  

    // Custom Tooltip Content

    
// Custom Tooltip Content
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    // Find the transaction for the label (date)
    const transaction = Array.isArray(transactions) 
      ? transactions.find(t => new Date(t.date).toISOString().split('T')[0] === label) 
      : null;

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
        {/* Display the label and payload value */}
        <p>Date: <span className="label">{label}</span></p>
        <p>Closing Price: <span className="label">{`${payload.find(p => p.dataKey === 'price').value.toFixed(2)} ${currency}`}</span></p>
        {/* Display the transaction details if a transaction is found */}
        {transaction && (
          <>
          {/* Display the label and payload value */}
          <p className="transaction-details">Your transaction</p>
          <p className="transaction-details">tx Price: <span className="label">{transaction.price}</span></p>
          <p className="transaction-details">tx Type: <span className="label">{transaction.type}</span></p>
          <p className="transaction-details">tx Quantity: <span className="label">{transaction.quantity}</span></p>
        </>
        )}
      </div>
    );
  }

  return null;
};



    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={formatDate} tick={customTick} />
          <YAxis 
            domain={['dataMin', 'dataMax']} 
            tickFormatter={twoDecimalFormatter} 
            tick={customTick}
            label={{ value: `Price (${currency})`, angle: -90, position: 'insideLeft', style: customTick }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ fontSize: '12px' }} />

          {/* Lines for price, smaShort, and smaLong */}
          <Line type="monotone" dataKey="price" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="smaShort" stroke="#82ca9d" />
          <Line type="monotone" dataKey="smaLong" stroke="#ffc658" />

          {/* ReferenceDots for transactions */}
          {transactionDots}

        </LineChart>
      </ResponsiveContainer>
    );
};

export default SMAGraph;
