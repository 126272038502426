import React, { useState, useEffect } from 'react';
import { Slider, Table, Button, Select, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import api from '../functions/api';
import getUserId from '../functions/getUserId';

const { Option } = Select;

const RebalanceTargetSetting = () => {
    const dispatch = useDispatch();

    // Available dataset options
    const datasetOptions = [
        { key: 'asset_type_division_cashincluded', label: 'Asset Type' },
        { key: 'currency_division', label: 'Currency' },
        { key: 'industry_division_cashincluded', label: 'Industry' },
        { key: 'category_userstatus', label: 'Category User Status' },
    ];

    // Fetch all datasets at once using useSelector
    const allData = useSelector(state => ({
        asset_type_division_cashincluded: state.portfolio.data.asset_type_division_cashincluded || [],
        currency_division: state.portfolio.data.currency_division || [],
        industry_division_cashincluded: state.portfolio.data.industry_division_cashincluded || [],
        category_userstatus: state.portfolio.data.category_userstatus || [],
        portfolio_total_value: state.portfolio.data.portfolio_topline[0]?.portfolio_total_value || '0'
    }));

    // State for dataset selection
    const [selectedDataset, setSelectedDataset] = useState('asset_type_division_cashincluded');
    const [targets, setTargets] = useState([]);

    // Ensure selectedData is always an array
    const [selectedData, setSelectedData] = useState(allData[selectedDataset] || []);

    const [sliderValues, setSliderValues] = useState(
        (allData[selectedDataset] || []).map(record => parseFloat(record.column2_name) || 0)
    );

    const [initialSliderValues, setInitialSliderValues] = useState(
        (allData[selectedDataset] || []).map(record => parseFloat(record.column2_name) || 0)
    );

    const [isAnyResetActive, setIsAnyResetActive] = useState(false);
    const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);

    const fetchTargets = async () => {
        const userId = getUserId();
        const keyTypeMap = {
            asset_type_division_cashincluded: 'assettype',
            currency_division: 'currency',
            industry_division_cashincluded: 'sector',
            category_userstatus: 'category'
        };
        const keyType = keyTypeMap[selectedDataset];

        try {
            const response = await api.get('/rebalance_targets', {
                params: { userId, keyType }
            });
            console.log('Fetch Targets Response:', response);
            if (response.status === 200) {
                setTargets(response.data.data);
            } else {
                message.error('Failed to fetch targets');
            }
        } catch (error) {
            message.error('Failed to fetch targets');
        }
    };

    useEffect(() => {
        fetchTargets();
    }, [selectedDataset]);

    const handleDatasetChange = (value) => {
        setSelectedDataset(value);
        const newData = allData[value].map(record => ({
            column1_name: value === 'category_userstatus' ? record.category : record.column1_name,
            column2_name: value === 'category_userstatus' ? record.part_of_portfolio : record.column2_name
        }));
        setSelectedData(newData);
        setSliderValues(newData.map(record => parseFloat(record.column2_name) || 0));
        setInitialSliderValues(newData.map(record => parseFloat(record.column2_name) || 0));
    };

    const totalPortfolioShare = selectedData.reduce((sum, record) => sum + parseFloat(record.column2_name || 0), 0);
    const totalAdjustShare = sliderValues.reduce((sum, value) => sum + value, 0);

    const columns = [
        {
            title: datasetOptions.find(option => option.key === selectedDataset)?.label || 'Category',
            dataIndex: 'column1_name',
            key: 'category',
            render: (text) => text || 'Undefined',
        },
        {
            title: 'Portfolio Share (%)',
            dataIndex: 'column2_name',
            key: 'portfolioShare',
            render: (value) => value ? `${parseFloat(value).toFixed(2)}%` : 'Undefined',
        },
        {
            title: (
                <>
                    Adjust
                    {isAnyResetActive && Math.abs(totalAdjustShare - totalPortfolioShare) > totalPortfolioShare * 0.01 && (
                        <span style={{ color: 'red', marginLeft: '10px' }}>
                            Make sure the total adds up to {totalPortfolioShare.toFixed(2)}%
                        </span>
                    )}
                </>
            ),
            key: 'adjust',
            render: (text, record, index) => {
                const initialValue = initialSliderValues[index];
                const currentValue = sliderValues[index];
                const change = currentValue - initialValue;
                const euroChange = (change / 100) * parseFloat(allData.portfolio_total_value);
                return (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <span>{sliderValues[index].toFixed(2)}%</span>
                            <Button 
                                size="small" 
                                onClick={() => handleReset(index)} 
                                disabled={sliderValues[index] === initialSliderValues[index]}
                                style={{ marginLeft: '10px', padding: '0 5px', height: '24px' }}
                            >
                                Reset
                            </Button>
                        </div>
                        <Slider
                            min={0}
                            max={100}
                            value={sliderValues[index]}
                            onChange={(value) => handleSliderChange(index, value)}
                            onAfterChange={(value) => handleSliderAfterChange(record.column1_name, value)}
                        />
                        {sliderValues[index] !== initialSliderValues[index] && (
                            <div style={{ marginTop: '8px', color: change > 0 ? 'green' : 'red' }}>
                                {change.toFixed(2)}% ({euroChange.toFixed(2)} €)
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            title: 'Targets',
            key: 'targets',
            render: (text, record) => {
                const keyTypeMap = {
                    asset_type_division_cashincluded: 'assettype',
                    currency_division: 'currency',
                    industry_division_cashincluded: 'sector',
                    category_userstatus: 'category'
                };
                const keyType = keyTypeMap[selectedDataset];
                const target = targets.find(target => target[keyType] === record.column1_name);
                if (target && !isNaN(target.adjustPercentage)) {
                    const euroChange = parseFloat(target.euroChange);
                    return (
                        <>
                            {`${parseFloat(target.adjustPercentage).toFixed(2)}%`}
                            {euroChange !== 0 && (
                                <div style={{ color: euroChange > 0 ? 'green' : 'red' }}>
                                    {`${euroChange.toFixed(2)} €`}
                                </div>
                            )}
                        </>
                    );
                }
                return 'No target';
            }
        }
    ];

    const handleSliderChange = (index, value) => {
        const newSliderValues = [...sliderValues];
        newSliderValues[index] = value;
        setSliderValues(newSliderValues);
        setIsAnyResetActive(newSliderValues.some((value, index) => value !== initialSliderValues[index]));
    };

    const handleSliderAfterChange = (column1_name, value) => {
        dispatch({
            type: 'UPDATE_ASSET_TYPE_DIVISION',
            payload: { column1_name, column2_name: value.toFixed(2) },
        });
    };

    const handleReset = (index) => {
        const originalValue = initialSliderValues[index];
        handleSliderChange(index, originalValue);
        handleSliderAfterChange(selectedData[index]?.column1_name, originalValue);
    };

    const handleResetAll = () => {
        const originalValues = initialSliderValues;
        setSliderValues(originalValues);
        originalValues.forEach((value, index) => {
            handleSliderAfterChange(selectedData[index]?.column1_name, value);
        });
        setShowAdditionalButtons(true);
    };

    const handleSetTargets = async () => {
        const dataset = selectedData.map((record, index) => {
            const baseData = {
                key: selectedDataset,
                adjustPercentage: sliderValues[index],
                percentageChange: sliderValues[index] - initialSliderValues[index],
                euroChange: ((sliderValues[index] - initialSliderValues[index]) / 100) * parseFloat(allData.portfolio_total_value)
            };

            switch (selectedDataset) {
                case 'asset_type_division_cashincluded':
                    return { ...baseData, assetType: record.column1_name };
                case 'currency_division':
                    return { ...baseData, currency: record.column1_name };
                case 'industry_division_cashincluded':
                    return { ...baseData, sector: record.column1_name };
                case 'category_userstatus':
                    return { ...baseData, category: record.column1_name };
                default:
                    return baseData;
            }
        });

        console.log('Set Targets Dataset:', dataset);

        const userId = getUserId();
        try {
            const response = await api.post('/rebalance_targets', { userId, dataset });
            if (response.status === 200) {
                message.success('Targets successfully set');
                fetchTargets(); // Re-fetch targets after setting new ones
            } else {
                message.error('Failed to set targets');
            }
        } catch (error) {
            message.error('Failed to set targets');
        }
    };

    return (
        <div>
            <Select
                defaultValue="asset_type_division"
                style={{ width: 250, marginBottom: '15px' }}
                onChange={handleDatasetChange}
            >
                {datasetOptions.map(option => (
                    <Option key={option.key} value={option.key}>
                        {option.label}
                    </Option>
                ))}
            </Select>

            <Button 
                onClick={handleResetAll} 
                style={{ marginLeft: '10px', marginBottom: '10px' }}
                disabled={!sliderValues.some((value, index) => value !== initialSliderValues[index])}
            >
                Reset All
            </Button>

            {sliderValues.some((value, index) => value !== initialSliderValues[index]) && Math.abs(totalAdjustShare - totalPortfolioShare) <= totalPortfolioShare * 0.01 && (
                <>
                    <Button 
                        style={{ marginLeft: '10px', marginBottom: '10px' }}
                        onClick={handleSetTargets}
                    >
                        Set Targets
                    </Button>
                </>
            )}

            <Table
                dataSource={selectedData}
                columns={columns}
                rowKey="column1_name"
                pagination={false}
                summary={() => (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}><b>Total</b></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}><b>{totalPortfolioShare.toFixed(2)}%</b></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}><b>{totalAdjustShare.toFixed(2)}%</b></Table.Summary.Cell>
                    </Table.Summary.Row>
                )}
            />
        </div>
    );
};

export default RebalanceTargetSetting;
